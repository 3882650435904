import React, { useEffect, useMemo, useState } from "react";
// import PropTypes from "prop-types";
import axios from "axios";
import { url } from "../../config";
import { CircularProgress, Paper, Stack } from "@mui/material";
import { groupByValue } from "../../utils";
import AssigneeRow from "./AssigneeRow";
import { CustomTableHeader } from "../../ComponentsLibrary/CustomTable";
import { useLocalStorage } from "../../CustomHooks/useLocalStorage";
import TableRow from "../../UIComponents/DesignSystem/TableRow";
import TableCell from "../../UIComponents/DesignSystem/TableCell";
import Table from "../../UIComponents/DesignSystem/Table";
import TableContainer from "../../UIComponents/DesignSystem/TableContainer";
import TableHead from "../../UIComponents/DesignSystem/TableHead";
import TableBody from "../../UIComponents/DesignSystem/TableBody";

const ActiveDeliverables = (props) => {
	const [delivarables, setActiveDelivarables] = useState([]);
	const [loadingState, setLoading] = useState("loading");
	const [filterValues, setFilterValues] = useState({
		assignees: [],
		managers: [],
		leads: [],
		deliverableStatuses: [],
		deliverablePlatforms: []
	});

	const [filteredAssignees, setAssigneeFilter] = useLocalStorage("assignees");
	const [filteredPlatforms, setPlatformFilter] = useLocalStorage("platforms");
	const [filteredManagers, setManagerFilter] = useLocalStorage("managers");
	const [filteredLeads, setLeadFilter] = useLocalStorage("leads");

	// get Active Delivarables
	useEffect(() => {
		axios
			.get(`${url}/activedeliverables`)
			.then((res) => {
				if (res.status) {
					setLoading("success");

					const activeDeliverables = res.data.map((deliverable) => {
						const newDeliverable = deliverable;
						if (!newDeliverable.assignees) newDeliverable.assignees = "Unassigned";
						return newDeliverable;
					});

					let uniqueValues = {
						assignees: new Set(),
						deliverableStatus: new Set(),
						deliverablePlatform: new Set(),
						manager: new Set(),
						projectlead: new Set()
					};

					activeDeliverables.forEach((item) => {
						uniqueValues.assignees.add(item.assignees || "Unassigned");
						uniqueValues.deliverableStatus.add(item.deliverableStatus);
						uniqueValues.manager.add(item.manager);
						uniqueValues.projectlead.add(item.projectlead);
						let platform = item.deliverablePlatform?.split(",");
						if (platform?.length > 1) {
							for (let i = 0; i < platform.length; i++) {
								uniqueValues.deliverablePlatform.add(platform[i].trim());
							}
						} else {
							uniqueValues.deliverablePlatform.add(item.deliverablePlatform);
						}
					});

					setFilterValues((prevState) => ({
						...prevState,
						leads: [...uniqueValues.projectlead],
						assignees: [...uniqueValues.assignees],
						deliverableStatuses: [...uniqueValues.deliverableStatus],
						managers: [...uniqueValues.manager],
						deliverablePlatforms: [...uniqueValues.deliverablePlatform]
					}));

					setActiveDelivarables(activeDeliverables);
				}
			})
			.catch((err) => {
				setLoading("failed");
				console.debug(err);
			});
	}, []);

	//   const filterData=(data,options,varName)=>{
	//     if(options){
	//       return  data.filter(
	//         (delv) => options.indexOf(delv[varName]) !== -1
	//       );
	//     }
	//     return data;
	// }
	const filteredData = useMemo(() => {
		if (delivarables.length > 0) {
			let filterDeliverables = [...delivarables];
			if (filteredAssignees) {
				filterDeliverables = filterDeliverables.filter(
					(delv) => filteredAssignees.indexOf(delv.assignees) !== -1
				);
			}
			if (filteredPlatforms) {
				filterDeliverables = filterDeliverables.filter(
					(delv) => filteredPlatforms.indexOf(delv.deliverablePlatform) !== -1
				);
			}
			if (filteredManagers) {
				filterDeliverables = filterDeliverables.filter(
					(delv) => filteredManagers.indexOf(delv.manager) !== -1
				);
			}
			if (filteredLeads) {
				filterDeliverables = filterDeliverables.filter(
					(delv) => filteredLeads.indexOf(delv.projectlead) !== -1
				);
			}
			return filterDeliverables;
		}
		return [];
	}, [delivarables, filteredAssignees, filteredPlatforms, filteredManagers, filteredLeads]);

	const assigneeGroupedData = useMemo(() => {
		if (filteredData.length > 0) {
			const grpData = groupByValue({
				data: filteredData,
				groupByVariable: "assignees"
			});
			let dataLength = grpData.length;
			if (dataLength > 1 && !grpData[dataLength - 1].groupValue) {
				let lastElement = grpData.pop();
				grpData.unshift(lastElement);
			}
			return grpData;
		} else {
			return [];
		}
	}, [filteredData]);

	const onSave = (options, varName) => {
		let selected = options.length > 0 ? options.toString() : null;
		switch (varName) {
			case "assignees":
				setAssigneeFilter(selected);
				break;
			case "platforms":
				setPlatformFilter(selected);
				break;
			case "managers":
				setManagerFilter(selected);
				break;
			case "leads":
				setLeadFilter(selected);
				break;
			default:
				break;
		}
	};
	return (
		<Stack direction={"column"} pb={2} sx={{ overflowX: "scroll" }}>
			<Stack
				direction={{ xs: "column", sm: "row" }}
				spacing={{ xs: 1, sm: 2, md: 2, xl: 4 }}
				pb={2}
			>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow className="t-h-row">
								<CustomTableHeader
									label="Assignee"
									filterable
									options={filterValues.assignees}
									varName="assignees"
									selectedOptions={filteredAssignees?.split(",") || []}
									onSave={onSave}
								/>
								<TableCell size="verysmall">Sub-Status</TableCell>
								<TableCell size="verysmall">Project Name</TableCell>
								<TableCell size="verysmall">Deliverable Name</TableCell>
								<TableCell size="verysmall">Dev Status</TableCell>
								<CustomTableHeader
									label="Deliverable Platform"
									filterable
									options={filterValues.deliverablePlatforms}
									varName="platforms"
									selectedOptions={filteredPlatforms?.split(",") || []}
									onSave={onSave}
								/>
								{/* <TableCell size="verysmall">Deliverable Platform</TableCell> */}
								<TableCell size="verysmall">Start Date</TableCell>
								<TableCell size="verysmall">Due Date</TableCell>
								{/* <TableCell size="verysmall">Manager</TableCell> */}
								<CustomTableHeader
									label="Manager"
									filterable
									options={filterValues.managers}
									varName="managers"
									selectedOptions={filteredManagers?.split(",") || []}
									onSave={onSave}
								/>
								<TableCell size="verysmall">Lead</TableCell>
								{/* <CustomTableHeader
                  label="Lead"
                  filterable
                  options={filterValues.leads}
                  varName="leads"
                  selectedOptions={filteredLeads?.split(",") || []}
                  onSave={onSave}
                /> */}
								<TableCell size="verysmall">Estimated Hours </TableCell>
								<TableCell size="verysmall">Billed Hours</TableCell>
								<TableCell size="verysmall">Hours Remaining</TableCell>
								<TableCell size="verysmall">Days Remaining</TableCell>
								<TableCell size="verysmall">Health</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{loadingState === "loading" && (
								<TableRow>
									<TableCell colSpan={17} style={{ textAlign: "center", height: 300 }}>
										<CircularProgress color="primary" />
									</TableCell>
								</TableRow>
							)}
							{loadingState === "failed" && (
								<TableRow>
									<TableCell colSpan={17} style={{ textAlign: "center", height: 300 }}>
										Error in loading the data! Please try again later.
									</TableCell>
								</TableRow>
							)}
							{loadingState === "success" && delivarables.length === 0 && (
								<TableRow>
									<TableCell colSpan={17} style={{ textAlign: "center", height: 300 }}>
										No Records found!
									</TableCell>
								</TableRow>
							)}

							{loadingState === "success" &&
								assigneeGroupedData.map((assignee, i) => (
									<AssigneeRow
										key={`Act-del-${assignee.groupValue}`}
										assigneeGroupedData={assignee}
									/>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
		</Stack>
	);
};

// ActiveDeliverables.propTypes = {};

export default ActiveDeliverables;
