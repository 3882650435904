import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow, styled } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { calculateEmployeeExperience } from "../../Utils/dates";
import { StyledTableRow } from "../../ForcastPacingDashboard/LeadRow";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Stack } from "../../UIComponents";
import IconButton from "../../UIComponents/DesignSystem/IconButtton";
dayjs.extend(relativeTime);

export const StyledIconButton = styled(IconButton)(() => ({
	height: "18px",
	padding: 0,
	aspectRatio: "1 / 1",
	"&> svg": {
		height: "1rem",
		width: "1rem"
	}
}));

const EmployeeRow = (props) => {
	const { employeeUtilisation = [], handleReportClick } = props;
	const manangerReporteeIds = useMemo(
		() => Object.values(employeeUtilisation).map(({ mtg_member_id }) => mtg_member_id),
		[employeeUtilisation]
	);
	const managerTotals = useMemo(() => {
		let totals = Object.values(employeeUtilisation).reduce(
			(acc, resourceData) => {
				const {
					billable_hours,
					total_available_hours,
					total_pto_hours,
					total_holiday_hours,
					total_billable_utilization,
					scheduled_hours,
					non_billable_hours
				} = resourceData;
				acc.m_billed_hrs += Number(billable_hours) || 0;
				acc.m_avlb_hrs += Number(total_available_hours) || 0;
				acc.m_pto_hrs += Number(total_pto_hours) || 0;
				acc.m_holiday_hrs += Number(total_holiday_hours) || 0;
				acc.m_billable_utilization += Number(total_billable_utilization) || 0;
				acc.m_scheduled_hours += Number(scheduled_hours) || 0;
				const logged_hours = Number(non_billable_hours) + Number(billable_hours);
				acc.m_logged_hour += Number(logged_hours);
				return { ...acc };
			},
			{
				m_pto_hrs: 0,
				m_holiday_hrs: 0,
				m_avlb_hrs: 0,
				m_billed_hrs: 0,
				m_billable_utilization: 0,
				m_scheduled_hours: 0,
				m_logged_hour: 0
			}
		);
		totals.m_billable_utilization =
			(totals.m_billable_utilization / employeeUtilisation.length).toFixed(2) + "%";
		totals.m_billed_hrs = totals.m_billed_hrs.toFixed(2);
		totals.m_avlb_hrs = totals.m_avlb_hrs.toFixed(2);
		totals.m_holiday_hrs = totals.m_holiday_hrs.toFixed(2);
		totals.m_pto_hrs = totals.m_pto_hrs.toFixed(2);
		totals.m_scheduled_hours = totals.m_scheduled_hours.toFixed(2);
		totals.m_logged_hour = totals.m_logged_hour.toFixed(2);
		return totals;
	}, [employeeUtilisation]);

	return (
		<React.Fragment>
			<TableRow>
				<TableCell size="verysmall" rowSpan={employeeUtilisation.length + 1} className="td-b-r">
					<Stack
						direction="row"
						alignItems="start"
						justifyContent="space-between"
						gap={1}
						sx={{
							width: "100%"
						}}
					>
						{employeeUtilisation[0].manager}
						<StyledIconButton
							color="secondary"
							variant="outlined"
							size="small"
							onClick={() =>
								handleReportClick({
									userIds: manangerReporteeIds.toString()
								})
							}
						>
							<AssessmentIcon />
						</StyledIconButton>
					</Stack>
				</TableCell>
			</TableRow>
			{employeeUtilisation.map((data) => {
				const {
					mtg_member_id,
					billable_hours,
					non_billable_hours,
					total_available_hours,
					total_pto_hours,
					total_holiday_hours,
					total_billable_utilization,
					member_name: name,
					member_start_date,
					// member_end_date,
					scheduled_hours
				} = data;
				const number_of_months = member_start_date
					? calculateEmployeeExperience(member_start_date)
					: "-";

				const total_logged_hours = Number(billable_hours) + Number(non_billable_hours);

				return (
					<TableRow id={mtg_member_id}>
						<TableCell className="td-b-r" size="verysmall">
							<Stack
								direction="row"
								alignItems="start"
								justifyContent="space-between"
								gap={1}
								sx={{
									width: "100%"
								}}
							>
								{name}
								<StyledIconButton
									color="secondary"
									variant="outlined"
									size="small"
									onClick={() => handleReportClick({ userIds: mtg_member_id })}
								>
									<AssessmentIcon />
								</StyledIconButton>
							</Stack>
						</TableCell>
						<TableCell className="td-b-r" size="verysmall">
							{member_start_date ? dayjs(member_start_date).format("MM-DD-YYYY") : "-"}
						</TableCell>
						<TableCell className="td-b-r" size="verysmall">
							{number_of_months}
						</TableCell>
						{/* <TableCell className="td-b-r" size="verysmall">
              {member_end_date
                ? dayjs(member_end_date).format("DD-MM-YYYY")
                : "-"}
            </TableCell> */}
						<TableCell className="td-b-r" size="verysmall">
							{total_pto_hours ? parseFloat(total_pto_hours).toFixed(2) : "0.00"}
						</TableCell>
						<TableCell className="td-b-r" size="verysmall">
							{total_holiday_hours ? parseFloat(total_holiday_hours).toFixed(2) : "0.00"}
						</TableCell>
						<TableCell className="td-b-r" size="verysmall">
							{scheduled_hours ? parseFloat(scheduled_hours).toFixed(2) : "0.00"}
						</TableCell>
						<TableCell className="td-b-r" size="verysmall">
							{parseFloat(total_available_hours).toFixed(2) || "0.00"}
						</TableCell>
						<TableCell className="td-b-r" size="verysmall">
							{total_logged_hours ? parseFloat(total_logged_hours).toFixed(2) : "0.00"}
						</TableCell>
						<TableCell className="td-b-r" size="verysmall">
							{billable_hours ? parseFloat(billable_hours).toFixed(2) : "0.00"}
						</TableCell>
						<TableCell className="td-b-r" size="verysmall">
							{parseFloat(total_billable_utilization).toFixed(2) || "0.00"}%
						</TableCell>
					</TableRow>
				);
			})}
			<StyledTableRow>
				<TableCell size="verysmall" className="td-b-r">
					Total
				</TableCell>
				<TableCell size="verysmall" colSpan={3} className="td-b-r" />
				<TableCell size="verysmall" className="td-b-r">
					{managerTotals.m_pto_hrs}
				</TableCell>
				<TableCell size="verysmall" className="td-b-r">
					{managerTotals.m_holiday_hrs}
				</TableCell>
				<TableCell size="verysmall" className="td-b-r">
					{managerTotals.m_scheduled_hours}
				</TableCell>
				<TableCell size="verysmall" className="td-b-r">
					{managerTotals.m_avlb_hrs}
				</TableCell>
				<TableCell size="verysmall" className="td-b-r">
					{managerTotals.m_logged_hour}
				</TableCell>
				<TableCell size="verysmall" className="td-b-r">
					{managerTotals.m_billed_hrs}
				</TableCell>
				<TableCell size="verysmall" className="td-b-r">
					{managerTotals.m_billable_utilization}
				</TableCell>
			</StyledTableRow>
		</React.Fragment>
	);
};

EmployeeRow.propTypes = {
	employeeUtilisation: PropTypes.object.isRequired,
	workingDays: PropTypes.object.isRequired
};

export default EmployeeRow;
