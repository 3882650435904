import { styled } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper } from "../../../UIComponents/index.js";
import Dashboard from "./Dashboard.js";
import { fetchCalculatedProjectStatus } from "../../../redux/reducers/projectHealthDashboard.js";
import dayjs from "dayjs";

export const StyledComponentWrapper = styled(Paper)(() => ({
	width: "inherit",
	padding: "1.25rem"
}));

const ProjectHealthDashboard = () => {
	const dispatch = useDispatch();
	const calculatedProjectReports = useSelector(
		(state) => state.projectHealthDashboard.calculated.ids
	);
	useEffect(() => {
		if (!calculatedProjectReports.length) {
			const endDate = dayjs().format("YYYY-MM-DD");
			const startDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");
			dispatch(fetchCalculatedProjectStatus({ startDate, endDate }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} xl={12}>
					<Dashboard />
				</Grid>
			</Grid>
		</>
	);
};

export default ProjectHealthDashboard;
