import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import SelectOption from "./SelectOption";
import InputText from "./InputText";
import moment from "moment";
import { usePrevious } from "../CustomHooks/usePrevious";

const InputSubmitSectionComponent = (props) => {
	const {
		optionsEnabled,
		monthOptions,
		managerOptions,
		onSubmitComment,
		initialValue,
		cancellable,
		onCancel,
		loading
	} = props;

	const monthEnabled = optionsEnabled?.includes("month");
	const managerEnabled = optionsEnabled?.includes("manager");

	const [comment, setComment] = useState(initialValue || "");
	const [manager, setManager] = useState("");
	const [month, setMonth] = useState(moment().month());
	const [error, setError] = useState({});
	const prevLoading = usePrevious({ loading });

	useEffect(() => {
		if (prevLoading?.loading === true && prevLoading?.loading !== loading) {
			if (loading === false) {
				console.log("setting as false");
				setComment("");
			}
		}
	}, [loading, prevLoading]);

	const onSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let update = comment.trim();
		if (managerEnabled && !manager) {
			setError({ manager: "Please set your identity" });
		} else if (!update) {
			setError({ comment: "Please enter your update here." });
		} else {
			//   setLoading(true);
			onSubmitComment({
				comment: update,
				month: monthEnabled ? parseInt(month) + 1 : null,
				manager: managerEnabled ? manager : null
			});
		}
	};

	return (
		<form onSubmit={onSubmit} noValidate>
			<Grid container spacing={1}>
				{managerEnabled && (
					<Grid item xs={12} md={6}>
						<SelectOption
							value={manager}
							label="Manager"
							options={managerOptions}
							style={{ width: "100%" }}
							onChange={(e) => {
								setManager(e.target.value);
								if (error?.manager) {
									setError((prev) => ({ ...prev, manager: "" }));
								}
							}}
							error={error?.manager ? true : false}
							helperText={error?.manager}
						/>
					</Grid>
				)}
				{monthEnabled && (
					<Grid item xs={12} md={6}>
						<SelectOption
							value={month}
							label="Select Month"
							options={monthOptions}
							style={{ width: "100%" }}
							onChange={(e) => setMonth(parseInt(e.target.value))}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<InputText
						value={comment}
						name="Project-Comment-Input"
						multiline
						minRows={3}
						maxRows={10}
						placeholder={"Please enter your update here"}
						style={{ width: "100%" }}
						onChange={(e) => {
							setComment(e.target.value);
							if (error?.comment) {
								setError((prev) => ({ ...prev, comment: "" }));
							}
						}}
						error={error?.comment ? true : false}
						helperText={error?.comment}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					style={{
						display: "flex",
						justifyContent: "flex-end",
						paddingTop: "8px"
					}}
				>
					{cancellable && (
						<Button
							variant="text"
							disableElevation
							sx={{ textTransform: "capitalize" }}
							onClick={onCancel}
						>
							cancel
						</Button>
					)}
					<Button
						variant="contained"
						disableElevation
						disabled={loading}
						sx={{ textTransform: "capitalize" }}
						type="submit"
					>
						Submit
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

InputSubmitSectionComponent.propTypes = {
	optionsEnabled: PropTypes.arrayOf(PropTypes.oneOf(["month", "manager"])),
	onSubmitComment: PropTypes.func.isRequired,
	initialValue: PropTypes.string,
	cancellable: PropTypes.bool,
	onCancel: PropTypes.func,
	loading: PropTypes.bool,
	monthOptions: ({ optionsEnabled, monthOptions }) => {
		if (optionsEnabled.includes("month")) {
			if (!Array.isArray(monthOptions)) {
				return new Error("monthOptions needs to an array");
			}
			return null;
		}
	},
	managerOptions: ({ optionsEnabled, monthOptions }) => {
		if (optionsEnabled?.includes("manager")) {
			if (!Array.isArray(monthOptions)) {
				return new Error("managerOptions needs to an array");
			}
			return null;
		}
	}
};

export default InputSubmitSectionComponent;
