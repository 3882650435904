import { styled, tableCellClasses } from "@mui/material";
import { TableCell as TableCellComp } from "../index.js";

const StyledTableCell = styled(TableCellComp)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.secondary.light
	}
}));

const TableCell = (props) => {
	return <StyledTableCell {...props} />;
};

export default TableCell;
