import { httpCall } from "./httpCalls";

export const fetchCertificationDataAPI = async () => {
	const { data: certificationsArr } = await httpCall({
		type: "GET",
		url: "certifications"
	});
	return certificationsArr;
};

export const submitCertificationAttemptAPI = async ({
	certification_track_key,
	status,
	acquired_date
}) => {
	const certificationAttemptResponse = await httpCall({
		type: "POST",
		url: `certification/attempt`,
		data: {
			certification_track_key,
			status,
			acquired_date
		}
	});

	const { certification_attempt, acquired_certifications = [] } =
		certificationAttemptResponse.data.data;

	return acquired_certifications;
};
