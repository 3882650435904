import { styled } from "@mui/material";
import { Stack } from "../../../UIComponents";
import CreateNewMeetingButton from "./CreateNewMeet";

const StyledStack = styled(Stack)(() => ({
	height: "100%"
}));

const EmptyMeetingList = ({ workspace_id }) => (
	<StyledStack justifyContent="center" alignItems="center">
		<CreateNewMeetingButton workspace_id={workspace_id} />
	</StyledStack>
);

export default EmptyMeetingList;
