import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

const InputText = (props) => {
	const { variant, type, size, onChange, value, name, placeholder } = props;
	return (
		<TextField
			variant={variant || "outlined"}
			type={type || "text"}
			name={name}
			value={value}
			placeholder={placeholder || "Please enter text"}
			onChange={onChange}
			size={size || "small"}
			//   fullWidth={!notFullWidth}
			{...props}
		/>
	);
};

InputText.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onChange: PropTypes.func.isRequired,
	size: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	variant: PropTypes.string
};

export default InputText;
