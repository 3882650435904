import { httpCall } from "../httpCalls";

export const features = {
	view_dashboard: 11,
	update_non_billable: 1
};

export const getUserTeamMembersAPI = async ({ userId }) => {
	return await httpCall({ type: "GET", url: `team-members/${userId}` });
};

export const getTargetMembersAPI = async ({ userId, featureId }) => {
	const data = {
		sourceMemberId: userId,
		featureId
	};
	return await httpCall({ type: "POST", url: `getTargetMembers`, data });
};
