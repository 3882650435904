import TableHead from "../../../UIComponents/DesignSystem/TableHead.js";
import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import { Stack, Typography } from "../../../UIComponents/index.js";
import InfoPopover from "../../../UIComponents/DesignSystem/InfoPopover.js";
import PMGrageInfo from "./PMGradeInfo.js";
import ProjectGradeInfo from "./ProjectGrade.js";

const columnInfoMap = {
	"Billable Hours": "The total number of billable hours worked on this project since its start.",
	"Non-Billable Hours":
		"The total number of non-billable hours worked on this project since its start.",
	"Utilization Rate (%)":
		"The billable utilization percentage, calculated as Billable Hours / Total Hours * 100.",
	"Budget Used (%)": "The percentage of the project budget consumed by the billed hours.",
	"Start Date": "The day the project was initiated.",
	"Original Due Date": "The originally planned project completion date, which does not change.",
	"Expected Due Date": "The current projected completion date as of today.",
	"Percentage Complete": "The percentage of tasks that have been completed.",
	"Days Since Last Report": "The number of days since the last report was submitted.",
	"PM Grade": <PMGrageInfo />,
	"Health Grade": <ProjectGradeInfo />
};

const TableHeadComponent = ({ columns, indent = 1 }) => {
	return (
		<TableHead>
			<TableRow>
				<TableCell colSpan={indent} />
				{columns.map((col, index) => (
					<TableCell colSpan={col === "Days since last report" ? 2 : 1} key={index}>
						{columnInfoMap[col] ? (
							<Stack direction="row" alignItems="center" justifyContent="center">
								<Typography>{col}</Typography>
								<InfoPopover>{columnInfoMap[col]}</InfoPopover>
							</Stack>
						) : (
							<Stack direction="row" alignItems="center" justifyContent="center">
								<Typography>{col}</Typography>
							</Stack>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeadComponent;
