import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import { getAllManagersAPI } from "../../apis/User/index.js";

const managersAdapter = createEntityAdapter({
	selectId: (manager) => manager.id,
	sortComparer: (a, b) => a.title.localeCompare(b.title)
});

export const fetchAllManagers = createAsyncThunk("managers/fetchAll", async () => {
	const managers = await getAllManagersAPI();
	return managers;
});

const managersSlice = createSlice({
	name: "managers",
	initialState: managersAdapter.getInitialState({
		loading: false,
		error: null
	}),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllManagers.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchAllManagers.fulfilled, (state, action) => {
				state.loading = false;
				managersAdapter.setAll(state, action.payload);
			})
			.addCase(fetchAllManagers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	}
});

// Export the generated selectors
export const {
	selectAll: selectAllManagers,
	selectById: selectManagerById,
	selectIds: selectManagerIds
} = managersAdapter.getSelectors((state) => state.members.managers);

// Export the reducer to be added to the store
export default managersSlice.reducer;
