import React, { useState } from "react";
import { IconButton, Popover, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const InfoPopover = ({ children }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<>
			<IconButton
				aria-describedby={id}
				onClick={handleClick}
				sx={{ height: "15px", width: "15px", padding: 0 }} // Set size to 10px
			>
				<InfoIcon sx={{ fontSize: "15px" }} />
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
			>
				<Typography sx={{ p: 2 }}>{children}</Typography>
			</Popover>
		</>
	);
};

export default InfoPopover;
