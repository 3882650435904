/* eslint-disable react-hooks/exhaustive-deps */
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Stack, styled } from "@mui/material";
import dayjs from "dayjs";
import { StyledTypography } from "./styledComponents";
import { useDispatch, useSelector } from "react-redux";
import { getVisibleWeekObject } from "./index.js";
import { getUsersTimeData } from "../../../redux/reducers/users";
import Button from "../../../UIComponents/DesignSystem/Button";
import IconButton from "../../../UIComponents/DesignSystem/IconButtton";
import { preventCustomPropForwarding } from "../../../Utils/MUI.js";

const StyledSquareIconButton = styled(
	IconButton,
	preventCustomPropForwarding(["wrapperSx"])
)(({ theme, color }) => ({
	borderRadius: "4px",
	padding: "2px",
	border: `.5px solid ${theme.palette.border.main}`
}));

const IconButtonStyles = {
	borderRadius: 0,
	padding: 0,
	backgroundColor: "transparent"
};

const ControlBar = ({ setStartDate, allMembersIds }) => {
	const dispatch = useDispatch();
	const { isLoading: usersTimeDataLoading = false } =
		useSelector((state) => state.apiStatus["users/getUsersTimeData"]) || {};
	const usersTimeData = useSelector((state) => state.users.timeData) || {};

	const handleClickNext = () => {
		setStartDate((prev) => {
			const startDate = prev.add(1, "week");
			const { key, sow } = getVisibleWeekObject({
				startDate: startDate.add(8, "week")
			});
			const dataExists = Object.values(usersTimeData)[0]?.[key];
			if (!dataExists && !usersTimeDataLoading) {
				const startDate = sow.format("YYYY-MM-DD");
				const endDate = sow.add(12, "week").endOf("week").format("YYYY-MM-DD");
				dispatch(
					getUsersTimeData({
						startDate,
						endDate,
						...(allMembersIds && { givenUserIds: allMembersIds })
					})
				);
			}
			return startDate;
		});
	};
	const handleClickPrev = () => {
		setStartDate((prev) => {
			const startDate = prev.subtract(1, "week");
			const { key, eow } = getVisibleWeekObject({ startDate });
			const dataExists = Object.values(usersTimeData)[0]?.[key];
			if (!dataExists && !usersTimeDataLoading) {
				const startDate = eow.subtract(12, "week").startOf("week").format("YYYY-MM-DD");
				const endDate = eow.format("YYYY-MM-DD");
				dispatch(
					getUsersTimeData({
						startDate,
						endDate,
						...(allMembersIds && { givenUserIds: allMembersIds })
					})
				);
			}
			return startDate;
		});
	};
	const handleClickToday = () => {
		setStartDate(dayjs().startOf("week").add(1, "day"));
	};
	return (
		<Stack direction="row" justifyContent="flex-end" alignItems="center">
			<Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
				<StyledSquareIconButton
					variant="outlined"
					key="prev"
					color="secondary"
					aria-label="Previous Week"
					size="medium"
					wrapperSx={IconButtonStyles}
					onClick={handleClickPrev}
				>
					<ChevronLeftIcon fontSize="medium" />
				</StyledSquareIconButton>
				<Button
					key="curr"
					color="secondary"
					variant="outlined"
					disableRipple
					onClick={handleClickToday}
					size="medium"
				>
					<StyledTypography>Today</StyledTypography>
				</Button>
				<StyledSquareIconButton
					key="next"
					variant="outlined"
					aria-label="Next Week"
					color="secondary"
					wrapperSx={IconButtonStyles}
					onClick={handleClickNext}
					size="medium"
				>
					<ChevronRightIcon fontSize="medium" />
				</StyledSquareIconButton>
			</Stack>
		</Stack>
	);
};

export default ControlBar;
