/* eslint-disable react-hooks/exhaustive-deps */
import Deliverables from "./Deliverables.js";
import {
	Stack,
	Grid,
	NewQuillEditor,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Tooltip,
	Popover,
	TextField
} from "../../UIComponents/index.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import { StyledIconButton } from "./DecisionTracker/DeliverableItemHeader.js";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import MeetingAgenda from "./MeetingAgenda.js";
import DiscussedDeliverables from "./DiscussedDeliverables.js";
import CopyMeetingNotes from "./CopyMeetingNotes.js";
import PublishMeetingNotes from "./PublishMeetingNotes.js";
import { ValidationContext } from "./DecisionTrackerForm.js";
import Button from "../../UIComponents/DesignSystem/Button.js";
import Typography from "../../UIComponents/DesignSystem/Typography.js";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";

const areAONsEqual = ({ AON1, AON2 }) => {
	if ((AON1.length === AON2?.length) === 0) return true;
	if (AON1 === AON2) return true;
	if (AON1 === null || AON2 === null) return false;
	if (AON1.length !== AON2.length) return false;
	const arr1 = AON1.sort((a, b) => b - a).toString();
	const arr2 = AON2.sort((a, b) => b - a).toString();
	return arr1 === arr2;
};

const deliverablesConstant = [
	{
		title: "Deliverables"
	}
];

const getMergedDeliverablesIds = ({
	allDeliverablesForThisWorkspace,
	discussed_deliverables_ids = "[]",
	notedDeliverables
}) => {
	const parse_discussed_deliverables_ids = JSON.parse(discussed_deliverables_ids) || [];
	return Object.values(allDeliverablesForThisWorkspace)
		.filter(
			(deliverable) =>
				parse_discussed_deliverables_ids?.includes(String(deliverable.id)) ||
				notedDeliverables.some((del) => del.id === deliverable.id)
		)
		.map((deliverable) => deliverable.id);
};

export const MeetingLocalStateContext = createContext();

const DecisionTrackerMeeting = ({ workspaceId, meetingId, handleMeetingSave }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const { isLoading } =
		useSelector((state) => state.apiStatus["decisions/updateWorkspaceMeetingThunk"]) || {};

	const current_meeting = useSelector(
		(state) => state.decisions.meetings[workspaceId]?.[meetingId]
	);
	const {
		magna_next_steps = "",
		client_next_steps = "",
		general_notes = "",
		agenda = "",
		title,
		discussed_deliverables_ids = []
	} = current_meeting || {};

	const { mentionList } = useSelector((state) => state.workspaces.resources[workspaceId]) || {};

	const allDeliverablesForThisWorkspace =
		useSelector((state) => state.workspaces.deliverables[workspaceId]) || {};
	const allNotedDeliverablesForThisWorkspace =
		useSelector((state) => state.decisions.deliverables_notes[workspaceId]) || {};
	const listOfNotedDeliverables = useMemo(
		() =>
			Object.entries(allNotedDeliverablesForThisWorkspace)
				.filter(([_, deliverables]) =>
					Object.values(deliverables).some(({ meeting_id }) => meetingId === meeting_id)
				)
				.map(([id]) => Number(id)),
		[allNotedDeliverablesForThisWorkspace, meetingId]
	);
	const notedDeliverables = useMemo(
		() =>
			Object.values(allDeliverablesForThisWorkspace).filter((deliverable) =>
				listOfNotedDeliverables.includes(Number(deliverable.id))
			),
		[listOfNotedDeliverables]
	);

	const discussed_deliverables_ids_merged = useMemo(
		() =>
			getMergedDeliverablesIds({
				allDeliverablesForThisWorkspace,
				discussed_deliverables_ids,
				notedDeliverables
			}),
		[listOfNotedDeliverables, current_meeting]
	);

	const {
		d_t_validation_setters: { add_unsaved_meeting, remove_unsaved_meeting }
	} = useContext(ValidationContext);

	const [generalNotesHTML, setGeneralNotesHTML] = useState(general_notes);
	const [companyNextStepsHTML, setCompanyNextSteps] = useState(magna_next_steps);
	const [clientNextStepsHTML, setClientNextSteps] = useState(client_next_steps);
	const [agendaHTML, setAgendaHTML] = useState(agenda);
	const [meetingTitle, setMeetingTitle] = useState(title);
	const [isTitleBiengEdit, setIsTitleBeingEdit] = useState(false);
	const [discussedDeliverablesIds, setDiscussedDeliverablesIds] = useState(
		discussed_deliverables_ids_merged || []
	);

	const isSaveButtonDisabled = useMemo(
		() =>
			isLoading ||
			(generalNotesHTML === general_notes &&
				companyNextStepsHTML === magna_next_steps &&
				clientNextStepsHTML === client_next_steps &&
				agendaHTML === agenda &&
				title === meetingTitle &&
				areAONsEqual({ AON1: discussedDeliverablesIds, AON2: discussed_deliverables_ids_merged })),
		[
			generalNotesHTML,
			general_notes,
			companyNextStepsHTML,
			clientNextStepsHTML,
			agendaHTML,
			meetingTitle,
			discussedDeliverablesIds,
			current_meeting
		]
	);

	useEffect(() => {
		setDiscussedDeliverablesIds((prev) => {
			const ddIds = prev?.length ? JSON.stringify(prev) : discussed_deliverables_ids;
			return getMergedDeliverablesIds({
				allDeliverablesForThisWorkspace,
				discussed_deliverables_ids: ddIds,
				notedDeliverables
			});
		});
	}, [allNotedDeliverablesForThisWorkspace]);

	useEffect(() => {
		if (isSaveButtonDisabled) {
			remove_unsaved_meeting({ meetingId });
			return;
		}
		add_unsaved_meeting({ meetingId });
	}, [isSaveButtonDisabled]);

	const handleMeetingReset = (e) => {
		e.stopPropagation(); /** Stopping propogation so btn does not toggle Accordian */
		setGeneralNotesHTML(general_notes);
		setCompanyNextSteps(magna_next_steps);
		setClientNextSteps(client_next_steps);
		setAgendaHTML(agenda);
		setMeetingTitle(title);
		setDiscussedDeliverablesIds(discussed_deliverables_ids_merged || []);
	};

	const handleAgendaClick = (e) => {
		e.stopPropagation(); /** Stopping propogation so btn does not toggle Accordian */
		setAnchorEl(e.currentTarget);
	};

	const handleTitleEdit = (e) => {
		e.stopPropagation(); /** Stopping propogation so btn does not toggle Accordian */
		setIsTitleBeingEdit(true);
	};

	const handleTitleDone = (e) => {
		e.stopPropagation(); /** Stopping propogation so btn does not toggle Accordian */
		setIsTitleBeingEdit(false);
	};

	const handleTitleChange = (e) => {
		setMeetingTitle(e.target.value);
	};

	return (
		<>
			<Accordion
				elevation={0}
				variant="outlined"
				disableGutters
				TransitionProps={{ unmountOnExit: true }}
				sx={{
					borderRadius: "8px",
					"&:before": {
						height: 0
					}
				}}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						sx={{
							width: "-webkit-fill-available",
							padding: "4px 8px 4px 0"
						}}
					>
						<Stack direction="row" alignItems="center" justifyContent="flex-start" gap={2}>
							<Stack direction="row" gap={1}>
								{isTitleBiengEdit ? (
									<>
										<Stack
											direction="row"
											alignItems="center"
											sx={{
												width: "200px"
											}}
										>
											<TextField
												color="secondary"
												size="small"
												variant="outlined"
												sx={{ width: "100%" }}
												value={meetingTitle}
												onClick={(e) => e.stopPropagation()}
												onChange={handleTitleChange}
											/>
										</Stack>
										<Tooltip title="Done">
											<StyledIconButton
												color="success"
												variant="filledTonal"
												size="small"
												onClick={handleTitleDone}
											>
												<DoneIcon color="success" />
											</StyledIconButton>
										</Tooltip>
									</>
								) : (
									<>
										<Stack
											direction="row"
											alignItems="center"
											sx={{
												width: "200px",
												height: "34.25px"
											}}
										>
											<Tooltip title={meetingTitle}>
												<Typography fontSize={14} fontWeight={700} noWrap>
													{meetingTitle}
												</Typography>
											</Tooltip>
										</Stack>
										<Tooltip title="Edit">
											<StyledIconButton
												color="secondary"
												variant="contained"
												size="small"
												onClick={handleTitleEdit}
											>
												<EditIcon fontSize="small" />
											</StyledIconButton>
										</Tooltip>
									</>
								)}
							</Stack>
							<Tooltip title="Agenda">
								<StyledIconButton
									color="secondary"
									variant="contained"
									size="small"
									active={Boolean(agendaHTML)}
									onClick={handleAgendaClick}
								>
									<ViewAgendaIcon fontSize="small" />
								</StyledIconButton>
							</Tooltip>
						</Stack>
						<Stack direction="row" alignItems="center" justifyContent="space-around" gap={2}>
							<PublishMeetingNotes
								workspace_id={workspaceId}
								meeting_id={meetingId}
								isSaveButtonDisabled={isSaveButtonDisabled}
							/>
							<CopyMeetingNotes workspaceId={workspaceId} meetingId={meetingId} />
							<Button
								color="secondary"
								startIcon={<RestartAltIcon />}
								variant="contained"
								size="small"
								disabled={isSaveButtonDisabled}
								onClick={(e) => handleMeetingReset(e)}
							>
								<Typography>Reset</Typography>
							</Button>
							<Button
								variant="contained"
								startIcon={<SaveIcon />}
								color="secondary"
								size="small"
								disabled={isSaveButtonDisabled}
								onClick={(e) =>
									handleMeetingSave(e, {
										meeting_id: meetingId,
										magna_next_steps: companyNextStepsHTML,
										client_next_steps: clientNextStepsHTML,
										general_notes: generalNotesHTML,
										agenda: agendaHTML,
										discussedDeliverablesIds,
										title: meetingTitle
									})
								}
							>
								<Typography>Save</Typography>
							</Button>
						</Stack>
					</Stack>
				</AccordionSummary>
				<AccordionDetails>
					<Stack gap={2}>
						{/* Discussed Deliverables */}
						<DiscussedDeliverables
							workspaceId={workspaceId}
							discussedDeliverablesIds={discussedDeliverablesIds}
							setDiscussedDeliverablesIds={setDiscussedDeliverablesIds}
							disabled_values={notedDeliverables}
						/>
						{/* Notes */}
						<Grid container gap={2}>
							<Grid item sm={3.8}>
								<Stack>
									<Typography color="disabled" fontWeight={700}>
										General Notes
									</Typography>
									<NewQuillEditor
										mentionList={mentionList}
										placeholder="Start taking notes..."
										value={generalNotesHTML}
										onChange={({ html }) => setGeneralNotesHTML(html)}
										editorHeight="100px"
									/>
								</Stack>
							</Grid>
							<Grid item sm={3.8}>
								<Stack>
									<Typography color="disabled" fontWeight={700}>
										Magna Next Steps
									</Typography>
									<NewQuillEditor
										mentionList={mentionList}
										placeholder="Start taking notes..."
										value={companyNextStepsHTML}
										onChange={({ html }) => setCompanyNextSteps(html)}
										editorHeight="100px"
									/>
								</Stack>
							</Grid>
							<Grid item sm={3.8}>
								<Stack>
									<Typography color="disabled" fontWeight={700}>
										Client Next Steps
									</Typography>
									<NewQuillEditor
										mentionList={mentionList}
										placeholder="Start taking notes..."
										value={clientNextStepsHTML}
										onChange={({ html }) => setClientNextSteps(html)}
										editorHeight="100px"
									/>
								</Stack>
							</Grid>
						</Grid>
						{/* Client Deliverables */}
						<MeetingLocalStateContext.Provider
							value={{
								meetingTitle
							}}
						>
							<Stack gap={2} sx={{ width: "100%" }}>
								{deliverablesConstant.map(({ title }) => (
									<Deliverables
										key={title}
										title={title}
										workspace_id={workspaceId}
										meeting_id={meetingId}
										discussedDeliverablesIds={discussedDeliverablesIds}
									/>
								))}
							</Stack>
						</MeetingLocalStateContext.Provider>
					</Stack>
				</AccordionDetails>
			</Accordion>
			<Popover
				anchorOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: "left"
				}}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "8px"
						}
					}
				}}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
			>
				<MeetingAgenda agendaHTML={agendaHTML} setAgendaHTML={setAgendaHTML} />
			</Popover>
		</>
	);
};

export default DecisionTrackerMeeting;
