import { useSelector } from "react-redux";
import { Autocomplete, TextField, Typography } from "../../UIComponents";
import { Chip } from "@mui/material";
import { useMemo } from "react";

const sortedDeliverables = ({ allDeliverablesForThisWorkspace }) => {
	const ns_deliverbales_temp = [],
		s_deliverbales_temp = [],
		c_deliverbales_temp = [];
	Object.values(allDeliverablesForThisWorkspace).forEach((deliverable) => {
		if (deliverable.state === "started") s_deliverbales_temp.push(deliverable);
		else if (deliverable.state === "not started") ns_deliverbales_temp.push(deliverable);
		else c_deliverbales_temp.push(deliverable);
	});
	return [...s_deliverbales_temp, ...ns_deliverbales_temp, ...c_deliverbales_temp];
};

const DiscussedDeliverables = ({
	workspaceId,
	discussedDeliverablesIds,
	setDiscussedDeliverablesIds,
	disabled_values
}) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const allDeliverablesForThisWorkspace =
		useSelector((state) => state.workspaces.deliverables[workspaceId]) || {};
	const sortedAllDeliverables = useMemo(
		() => sortedDeliverables({ allDeliverablesForThisWorkspace }),
		[allDeliverablesForThisWorkspace]
	);
	const selectedDeliverables = useMemo(
		() =>
			Object.values(allDeliverablesForThisWorkspace).filter((deliverable) =>
				discussedDeliverablesIds.includes(deliverable.id)
			),
		[allDeliverablesForThisWorkspace, discussedDeliverablesIds]
	);
	return (
		<Autocomplete
			multiple
			disableClearable
			filterSelectedOptions
			disableCloseOnSelect
			limitTags={4}
			options={sortedAllDeliverables}
			value={selectedDeliverables}
			onChange={(_, newValue) => {
				setDiscussedDeliverablesIds(newValue.map((selectedDeliverable) => selectedDeliverable.id));
			}}
			getOptionLabel={(option) => option.title}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					size="small"
					color="border"
					label={
						<Typography color="disabled" fontWeight={700}>
							Discussed Deliverables
						</Typography>
					}
					placeholder="Deliverables to be discussed"
				/>
			)}
			renderTags={(tagValue, getTagProps) =>
				tagValue.map((option, index) => (
					<Chip
						label={option.title}
						{...getTagProps({ index })}
						size="small"
						color="primaryLight"
						disabled={
							disabled_values.findIndex((deliverable) => deliverable.id === option.id) !== -1
						}
					/>
				))
			}
			style={{ width: 800 }}
		/>
	);
};

export default DiscussedDeliverables;
