import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ProjectUtilisationTable from "./BillableUtilisationTable.js";
import "./index.css";
import { Grid, Typography, styled } from "@mui/material";
import SearchSelect from "../../ComponentsLibrary/SearchSelect";
import axios from "axios";
import { url } from "../../config.js";
import IndexChart from "../../UIComponents/DesignSystem/IndexChart.js";
import { Paper, Popover, Stack } from "../../UIComponents/index.js";
import dayjs from "dayjs";
import Button from "../../UIComponents/DesignSystem/Button.js";
import TimeRange from "./TimeRange.js";
import GlobalModal from "../../ComponentsLibrary/GlobalModal.js";
import CustomTimeUtilization from "./CustomTimeUtilization.js";
import AssessmentIcon from "@mui/icons-material/Assessment";
import IconButton from "../../UIComponents/DesignSystem/IconButtton.js";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter);
dayjs.extend(quarterOfYear);

const StyledPaper = styled(Paper)(({ theme }) => ({
	borderRadius: "6px",
	padding: "16px"
}));

const commonSelectStyle = {
	minWidth: "150px"
};
const commontInputStyle = {
	".MuiFilledInput-root": {},
	".MuiFilledInput-root:before": {
		borderBottom: "none"
	}
};

const groupDataByManagers = (data) => {
	const dataByManagers = data.reduce((acc, curr) => {
		let managerName = curr.manager;
		if (!managerName) return acc;
		if (!acc[managerName]) {
			acc[managerName] = { managerName, details: [] };
		}
		acc[managerName].details.push(curr);
		return acc;
	}, {});
	Object.keys(dataByManagers).forEach((manager) => {
		dataByManagers[manager].details = dataByManagers[manager].details.sort(
			({ total_billable_utilization: a }, { total_billable_utilization: b }) => b - a
		);
	});
	return dataByManagers;
};

const getManagers = (data) => {
	return [
		{ id: "all", title: "All" },
		...Object.entries(data).map(([manager_name, { details: [{ manager_id }] = [] }]) => ({
			id: manager_id,
			title: manager_name
		}))
	];
};

export const formatDate = (date) => {
	return date.format("YYYY-MM-DD");
};

const isChipDisabled = ({ startDate, endDate }) => {
	if (startDate.isSameOrAfter(endDate)) return true;
	return false;
};

const getTimeRange = (today) => {
	/** Current Calculations */
	const SOW = today.startOf("w");
	const SOM = today.startOf("M");
	const SOBillingCycle = today.format("D") > 15 ? SOM.add(15, "day") : SOM;
	const SOQ = today.startOf("Q");
	const yesterday = today.subtract(1, "day");
	const latestDate = formatDate(yesterday);

	/** Past Calculations */
	const prev_SOW = SOW.subtract(1, "week");
	const prev_EOW = prev_SOW.endOf("week").subtract(1, "day");
	const prev_SOM = SOM.subtract(1, "month");
	const prev_EOM = SOM.endOf("month");
	const prev_mid_month = prev_SOM.add(15, "day");
	const prev_SOBillingCycle = SOBillingCycle.format("D") > 15 ? SOM : prev_mid_month;
	const prev_EOBillingCycle =
		prev_SOBillingCycle.format("D") > 15
			? prev_SOBillingCycle.endOf("month")
			: prev_SOBillingCycle.add(14, "day");
	const prev_SOQ = today.startOf("Q").subtract(1, "Q");
	const prev_EOQ = prev_SOQ.endOf("Q");

	const timeRange = {
		"Last 7 Days": {
			id: "abs-0",
			title: "Last 7 Days",
			startDate: formatDate(today.subtract(7, "days")),
			endDate: latestDate
		},
		"Last 15 Days": {
			id: "abs-1",
			title: "Last 15 Days",
			startDate: formatDate(today.subtract(15, "days")),
			endDate: latestDate
		},
		"Last 30 Days": {
			id: "abs-2",
			title: "Last 30 Days",
			startDate: formatDate(today.subtract(1, "month")),
			endDate: latestDate
		},
		"Last 60 Days": {
			id: "abs-3",
			title: "Last 60 Days",
			startDate: formatDate(today.subtract(2, "month")),
			endDate: latestDate
		},
		"Last 90 Days": {
			id: "abs-4",
			title: "Last 90 Days",
			startDate: formatDate(today.subtract(3, "month")),
			endDate: latestDate
		},
		"Current Week": {
			id: "ref-0",
			title: "Current Week",
			startDate: formatDate(SOW),
			endDate: latestDate,
			disabled: isChipDisabled({
				startDate: SOW,
				endDate: yesterday
			})
		},
		"Last Week": {
			id: "ref-0-1",
			title: "Last Week",
			startDate: formatDate(prev_SOW),
			endDate: formatDate(prev_EOW),
			disabled: isChipDisabled({
				startDate: prev_SOW,
				endDate: prev_EOW
			})
		},
		"Current Billing Cycle": {
			id: "ref-1",
			title: "Current Billing Cycle",
			startDate: formatDate(SOBillingCycle),
			endDate: latestDate,
			disabled: isChipDisabled({
				startDate: SOBillingCycle,
				endDate: yesterday
			})
		},
		"Last Billing Cycle": {
			id: "ref-1-1",
			title: "Last Billing Cycle",
			startDate: formatDate(prev_SOBillingCycle),
			endDate: formatDate(prev_EOBillingCycle),
			disabled: isChipDisabled({
				startDate: prev_SOBillingCycle,
				endDate: prev_EOBillingCycle
			})
		},
		"Current Month": {
			id: "ref-2",
			title: "Current Month",
			startDate: formatDate(SOM),
			endDate: latestDate,
			disabled: isChipDisabled({
				startDate: SOM,
				endDate: yesterday
			})
		},
		"Last Month": {
			id: "ref-2-1",
			title: "Last Month",
			startDate: formatDate(prev_SOM),
			endDate: formatDate(prev_EOM),
			disabled: isChipDisabled({
				startDate: prev_SOM,
				endDate: prev_EOM
			})
		},
		"Current Quarter": {
			id: "ref-4",
			title: "Current Quarter",
			startDate: formatDate(SOQ),
			endDate: latestDate,
			disabled: isChipDisabled({
				startDate: SOQ,
				endDate: yesterday
			})
		},
		"Last Quarter": {
			id: "ref-4-1",
			title: "Last Quarter",
			startDate: formatDate(prev_SOQ),
			endDate: formatDate(prev_EOQ),
			disabled: isChipDisabled({
				startDate: prev_SOQ,
				endDate: prev_EOQ
			})
		},
		Custom: {
			id: "custom-0",
			title: "Custom",
			startDate: formatDate(today.subtract(7, "days")),
			endDate: latestDate
		}
	};
	return timeRange;
};

export const IGNORE_IDS_LIST = [23, 30, 29, 46];

const BillableUtilisationDashboard = (props) => {
	const dateRanges = useMemo(() => getTimeRange(dayjs(new Date()).startOf("date")), []);
	const [billableUtilisation, setBillableUtilisationData] = useState([]);
	const [dateRange, setDateRange] = useState({});
	const [mgrsGrpdata, setMgrsGrpdata] = useState([]);
	const [allUserIds, setAllUserIds] = useState([]);
	const [loadingState, setLoading] = useState("loading");
	const managers = useRef([]);
	const [timeRangeAnchorEl, setTimeRangeAnchorEl] = useState();
	const [managerSelected, selectManager] = useState({
		id: "all",
		title: "All"
	});

	const [timeUtilizationModal, setTimeUtilizationModal] = useState({
		isOpen: false
	});

	useEffect(() => {
		setDateRange(dateRanges["Last 7 Days"]);
	}, [dateRanges]);

	useEffect(() => {
		if (dateRange.title) {
			const { startDate, endDate } = dateRange;
			setLoading("loading");
			axios
				.get(
					`${url}/dashboard/billable-utilization?start_date=${startDate}&end_date=${endDate}&managers=${managerSelected.id}`
				)
				.then((res) => {
					const filterUsers = (res.data || []).filter(
						({ mtg_member_id: id }) => !IGNORE_IDS_LIST.includes(id)
					);
					const filteredUserIds = filterUsers.map(({ mtg_member_id }) => mtg_member_id);
					setAllUserIds(filteredUserIds);
					const dashboardDataGByManagers = groupDataByManagers(filterUsers);
					const managerList = getManagers(dashboardDataGByManagers);
					if (managers.current.length === 0) {
						managers.current = managerList.filter(({ id }) => (id === null ? false : true));
					}
					setBillableUtilisationData(filterUsers);
					setMgrsGrpdata(dashboardDataGByManagers);
					setLoading("success");
				})
				.catch((err) => {
					setLoading("failed");
					console.log(err);
				});
		}
	}, [dateRange, managerSelected.id]);

	const aggregatedIndexData = useMemo(() => {
		let totalBillableUtilisation = 0,
			totalBilledHours = 0,
			totalAvailableHours = 0,
			totalPTOHours = 0,
			totalHolidayHours = 0,
			totalLoggedHours = 0;
		billableUtilisation.forEach((billableData) => {
			const {
				total_billable_utilization,
				billable_hours,
				non_billable_hours,
				total_available_hours,
				total_pto_hours,
				total_holiday_hours
			} = billableData;
			totalBillableUtilisation +=
				Number(total_billable_utilization) / billableUtilisation.length || 0;
			totalBilledHours += Number(billable_hours) || 0;
			totalAvailableHours += Number(total_available_hours) || 0;
			totalPTOHours += Number(total_pto_hours) || 0;
			totalHolidayHours += Number(total_holiday_hours) || 0;
			const logged_hours = Number(non_billable_hours) + Number(billable_hours);
			totalLoggedHours += Number(logged_hours);
		});
		const aggregatedData = {
			totalBillableUtilisation,
			totalBilledHours,
			totalAvailableHours,
			totalPTOHours,
			totalHolidayHours,
			totalLoggedHours
		};
		return aggregatedData;
	}, [billableUtilisation]);

	const totalBillableUtilisationChartData = [
		{
			title: "Billable Utilization",
			value: parseFloat(Number(aggregatedIndexData.totalBillableUtilisation).toFixed(2)) + "%"
		}
	];

	const totalAvailableChartHours = [
		{
			title: "Billed Hours",
			value: parseFloat(Number(aggregatedIndexData.totalBilledHours).toFixed(2))
		},
		{
			title: "Available Hours",
			value: parseFloat(Number(aggregatedIndexData.totalAvailableHours)).toFixed(2)
		}
	];

	const totalLoggedChartHours = [
		{
			title: "Logged Hours",
			value: parseFloat(Number(aggregatedIndexData.totalLoggedHours).toFixed(2))
		}
	];

	const totalOffChartData = [
		{
			title: "PTO Hours",
			value: parseFloat(Number(aggregatedIndexData.totalPTOHours).toFixed(2))
		},
		{
			title: "Holiday Hours",
			value: parseFloat(Number(aggregatedIndexData.totalHolidayHours)).toFixed(2)
		}
	];

	const handleTimeRangeBtnClick = (e) => {
		setTimeRangeAnchorEl((prev) => (prev ? null : e.target));
	};

	const handleReportClick = useCallback(
		({ userIds }) => {
			setTimeUtilizationModal({
				isOpen: true,
				startDate: dateRange.startDate,
				endDate: dateRange.endDate,
				userIds
			});
		},
		[dateRange.endDate, dateRange.startDate]
	);

	return (
		<Grid container>
			<Grid item xs={12}>
				<StyledPaper direction={"column"} spacing={2} mt={2}>
					<Grid container gap={2} justifyContent="space-between">
						<Grid item xs={12}>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								gap={2}
								flexWrap="wrap"
							>
								<Stack direction="row" gap={2} flexWrap="wrap">
									<IndexChart data={totalOffChartData} />
									<IndexChart data={totalAvailableChartHours} />
									<IndexChart data={totalLoggedChartHours} />
									<IndexChart data={totalBillableUtilisationChartData} />
								</Stack>
								<IconButton
									color="secondary"
									variant="outlined"
									size="small"
									onClick={() =>
										handleReportClick({
											userIds: allUserIds.toString()
										})
									}
									disabled={loadingState !== "success"}
								>
									<AssessmentIcon />
								</IconButton>
							</Stack>
						</Grid>
						<Grid item xs={3}>
							<Typography fontSize="26px">Billable Utilization by Manager</Typography>
						</Grid>
						<Grid item xs={8}>
							<Stack justifyContent="flex-end" direction="row" gap={2}>
								<Button
									color="secondary"
									variant="contained"
									size="small"
									disabled={loadingState !== "success"}
									onClick={handleTimeRangeBtnClick}
								>
									<Typography>{dateRange.title}</Typography>
								</Button>
								<SearchSelect
									id="Forcast-Manager-select"
									value={managerSelected}
									options={managers.current}
									onChange={(manager) => selectManager(manager)}
									optionDisplayVar="title"
									label="Manager"
									inputColor="#000"
									sx={commonSelectStyle}
									disabled={loadingState !== "success"}
									inputSx={commontInputStyle}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<ProjectUtilisationTable
								dataByManager={mgrsGrpdata}
								loadingState={loadingState}
								managers={managers}
								handleReportClick={handleReportClick}
							/>
						</Grid>
					</Grid>
				</StyledPaper>
				<Popover
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					slotProps={{
						paper: {
							sx: {
								borderRadius: "8px"
							}
						}
					}}
					open={Boolean(timeRangeAnchorEl)}
					anchorEl={timeRangeAnchorEl}
					onClose={() => setTimeRangeAnchorEl(null)}
				>
					<TimeRange
						onClose={() => setTimeRangeAnchorEl(null)}
						dateRange={dateRange}
						setDateRange={setDateRange}
						options={Object.values(dateRanges)}
						handleClose={() => setTimeRangeAnchorEl(null)}
					/>
				</Popover>
			</Grid>
			<GlobalModal
				onClose={() => setTimeUtilizationModal({ isOpen: false })}
				open={timeUtilizationModal.isOpen}
				modalTitle="Time Utilization"
				shouldShowDialogActions={false}
			>
				{timeUtilizationModal.isOpen && (
					<CustomTimeUtilization
						userIds={timeUtilizationModal.userIds}
						startDate={timeUtilizationModal.startDate}
						endDate={timeUtilizationModal.endDate}
					/>
				)}
			</GlobalModal>
		</Grid>
	);
};

export default BillableUtilisationDashboard;
