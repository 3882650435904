import TableHead from "../../../UIComponents/DesignSystem/TableHead.js";
import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import { Stack, Typography } from "../../../UIComponents/index.js";

const TableHeadComponent = ({ columns, indent = 1 }) => {
	return (
		<TableHead>
			<TableRow>
				{indent !== 0 && <TableCell colSpan={indent} />}
				{columns.map((col, index) => (
					<TableCell
						key={index}
						style={{
							textAlign: col === "Certification Name" ? "left" : "center"
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent={col === "Certification Name" ? "flex-start" : "center"}
						>
							<Typography>{col}</Typography>
						</Stack>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeadComponent;
