import React from "react";
// import PropTypes from "prop-types";
import "./profile.css";
// import { useAuth } from "../../CustomHooks/AuthenticationHook";
import { Grid } from "@mui/material";
// import UserProfile from "./Profile";
import ProfileSummary from "../MemberPerformanceProfile/ProfileSummary";
import TeamMembers from "../TeamMembers";

const MemberProfile = (props) => {
	// const { user } = useAuth();
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} xl={12}>
				{/* <UserProfile/> */}
				<ProfileSummary />
			</Grid>
			<Grid item xs={12}>
				<TeamMembers />
			</Grid>
		</Grid>
	);
};

// MemberProfile.propTypes = {};

export default MemberProfile;
