import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { CircularProgress, SwipeableDrawer } from "@mui/material";
import LeadRow from "./LeadRow";
import ProjectComments from "./ProjectComments";
import TableCell from "../UIComponents/DesignSystem/TableCell.js";
import TableRow from "../UIComponents/DesignSystem/TableRow.js";
import TableHead from "../UIComponents/DesignSystem/TableHead.js";

const ProjectsTable = (props) => {
	const { projects, loadingState, managers, monthOptions, workingDays } = props;
	//remove first ele in managers => ES6 format
	const [, ...mgrOptions] = managers;

	const [commentsOpen, setCommentsOpen] = useState(false);

	const [projectData, setSeedData] = useState({
		project_title: "",
		project_id: ""
	});

	const openComments = (seedData) => {
		setSeedData({ ...seedData });
		setCommentsOpen(true);
	};
	return (
		<React.Fragment>
			<TableContainer component={Paper}>
				<Table aria-label="Projects grouped by managers">
					<TableHead>
						<TableRow>
							<TableCell size="verysmall">Manager</TableCell>
							<TableCell size="verysmall">Lead</TableCell>
							<TableCell size="verysmall">Project</TableCell>
							<TableCell size="verysmall">Project Status</TableCell>
							<TableCell size="verysmall">Project Close Date</TableCell>
							<TableCell size="verysmall">Project Budget Remaining</TableCell>
							<TableCell size="verysmall">Allocated Hours</TableCell>
							<TableCell size="verysmall" title="Billable Hours">
								Current Hours (B)
							</TableCell>
							<TableCell size="verysmall" title="Current Non Billable Hours">
								Current Hours (NB)
							</TableCell>
							<TableCell size="verysmall">Hours Pacing Delta </TableCell>
							<TableCell size="verysmall">Allocated Revenue </TableCell>
							<TableCell size="verysmall">Current Revenue </TableCell>
							<TableCell size="verysmall">Revenue Pacing Delta </TableCell>
							<TableCell size="verysmall">Forecast Pacing Hours</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{loadingState === "loading" && (
							<TableRow>
								<TableCell colSpan={13} style={{ textAlign: "center", height: 300 }}>
									<CircularProgress color="primary" />
								</TableCell>
							</TableRow>
						)}
						{loadingState === "failed" && (
							<TableRow>
								<TableCell colSpan={13} style={{ textAlign: "center", height: 300 }}>
									Error in loading the data! Please try again later.
								</TableCell>
							</TableRow>
						)}
						{loadingState === "success" && projects.length === 0 && (
							<TableRow>
								<TableCell colSpan={13} style={{ textAlign: "center", height: 300 }}>
									No Records found!
								</TableCell>
							</TableRow>
						)}

						{loadingState === "success" &&
							projects.map((proj, i) => (
								<LeadRow
									key={`Proj-${i}`}
									manager={proj}
									workingDays={workingDays}
									openComments={openComments}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<SwipeableDrawer
				anchor="right"
				open={commentsOpen}
				onClose={() => {
					setCommentsOpen(false);
					setSeedData({});
				}}
				onOpen={() => setCommentsOpen(true)}
				// sx={{zIndex:10000}}
			>
				<ProjectComments
					mgrOptions={mgrOptions}
					monthOptions={monthOptions}
					projectData={projectData}
				/>
			</SwipeableDrawer>
		</React.Fragment>
	);
};

ProjectsTable.propTypes = {
	projects: PropTypes.array.isRequired
};

export default ProjectsTable;
