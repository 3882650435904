import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const TimeLogginHabit = (props) => {
	const { loggedOnTime, notLoggedOnTime, missingCount } = props;

	const data = {
		datasets: [
			{
				data: [loggedOnTime, notLoggedOnTime, missingCount],
				backgroundColor: ["#28C76F", "#FF9F43", "#EA5455"],
				borderColor: ["#28C76F", "#FF9F43", "#EA5455"],
				cutout: "75%"
			}
		]
	};

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false, // Disable aspect ratio to control the size

		// Custom chart size
		width: 200,
		height: 200
	};

	const plugins = [
		{
			beforeDraw: function (chart) {
				const width = chart.width,
					height = chart.height,
					ctx = chart.ctx;
				ctx.restore();
				const fontSize = (height / 160).toFixed(2);
				ctx.font = fontSize + "em Montserrat";
				ctx.textBaseline = "top";
				ctx.fillStyle = "#5E5873";
				const text = `${chart.data.datasets[0].data[0]}/${chart.data.datasets[0].data[0] + chart.data.datasets[0].data[1] + chart.data.datasets[0].data[2]}`,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;
				ctx.fillText(text, textX, textY);
				ctx.save();
			}
		}
	];

	return (
		<div style={{ width: "200px", height: "200px" }}>
			<Doughnut data={data} options={chartOptions} plugins={plugins} />
		</div>
	);
};

export default TimeLogginHabit;
