import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
	Card,
	Stack,
	Typography,
	IconButton,
	Grid,
	Avatar,
	SvgIcon,
	MenuItem,
	ListItemIcon,
	Menu
} from "@mui/material";
import { useAuth } from "../CustomHooks/AuthenticationHook";
import { ReactComponent as InboxIcon } from "../assets/InboxIcon.svg";
// import { ReactComponent as ChatIcon } from "../assets/ChatIcon.svg";
import { ReactComponent as AuthentocatorIcon } from "../assets/AuthentocatorIcon-copy.svg";
// import { ReactComponent as FavouritesIcon } from "../assets/FavouritesIcon.svg";
import { ReactComponent as CalendarIcon } from "../assets/CalendarIcon.svg";
import { ReactComponent as ProfileIcon } from "../assets/ProfileIcon.svg";
import Logout from "@mui/icons-material/Logout";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const IconComponent = (props) => (
	<SvgIcon viewBox="0 0 18 18" inheritViewBox>
		{props.children}
	</SvgIcon>
);

const AppBarMtg = (props) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const isMountedRef = useRef(false);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const { user, setUser, logout } = useAuth();
	const navigate = useNavigate();

	const getUserData = useCallback(() => {
		axios
			.get(`${`${process.env.REACT_APP_PROD}/api/v1`}/users/${user.userId}`)
			.then((res) => {
				setUser({
					...user,
					title: res.data.data[0].title,
					photo: res.data.data[0].photo,
					role_id: res.data.data[0].role_id
				});
			})
			.catch((err) => console.log(err));
	}, [user, setUser]);

	useEffect(() => {
		if (!isMountedRef.current) {
			getUserData();
			isMountedRef.current = true;
		}
	}, [getUserData]);

	const handleNavigate = ({ link, openInNewTab }) => {
		handleClose();
		if (openInNewTab) {
			window.open(link, "_blank", "rel=noopener noreferrer");
		} else {
			navigate(link);
		}
	};
	return (
		<Card
			style={{
				minHeight: 64,
				width: "100%",
				marginTop: 8,
				display: "flex",
				alignItems: "center"
			}}
		>
			<Grid
				container
				flexDirection={"row"}
				justifyContent={"space-between"}
				alignItems={"center"}
				sx={{
					flexWrap: {
						xs: "wrap"
					}
				}}
				p={1}
			>
				<Grid item xs={6}>
					<Stack direction={"row"} spacing={1} alignItems={"center"}>
						<IconButton
							onClick={() => handleNavigate({ link: "dashboard" })}
							aria-label="Auth Codes"
							title="Auth Codes"
						>
							<IconComponent>
								<AuthentocatorIcon />
							</IconComponent>
						</IconButton>
						{/* <IconButton>
              <IconComponent>
                <ChatIcon />
              </IconComponent>
            </IconButton> */}
						<IconButton
							onClick={() =>
								handleNavigate({
									link: "https://mail.google.com/",
									openInNewTab: true
								})
							}
							aria-label="Mail"
							title="Mail"
						>
							<IconComponent>
								<InboxIcon />
							</IconComponent>
						</IconButton>
						<IconButton
							onClick={() =>
								handleNavigate({
									link: "https://calendar.google.com/",
									openInNewTab: true
								})
							}
							aria-label="Calendar"
							title="Calendar"
						>
							<IconComponent>
								<CalendarIcon />
							</IconComponent>
						</IconButton>
						{/* <IconButton>
              <IconComponent>
                <FavouritesIcon />
              </IconComponent>
            </IconButton> */}
					</Stack>
				</Grid>
				<Grid item xs={6}>
					<Stack sx={{ width: "100%" }} direction={"row"} spacing={1} justifyContent={"flex-end"}>
						<Stack direction={"column"} justifyContent={"flex-end"} textAlign={"center"}>
							<Typography variant="Regular" fontSize={14} sx={{ color: "#6E6B7B" }}>
								{user?.name}
							</Typography>
							<Typography variant="Regular" sx={{ color: "#B9B9C3" }}>
								{user?.title || "--"}
							</Typography>
						</Stack>
						<IconButton onClick={handleClick}>
							<Avatar alt={user?.name || "User Name"} src={user.photo}>
								{user?.name.split("")[0] || "U"}
							</Avatar>
						</IconButton>
					</Stack>
				</Grid>
				<Menu
					anchorEl={anchorEl}
					id="account-menu"
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					slotProps={{
						paper: {
							elevation: 0,
							sx: {
								overflow: "visible",
								filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
								mt: 1.5,
								"& .MuiAvatar-root": {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1
								},
								"&:before": {
									content: '""',
									display: "block",
									position: "absolute",
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: "background.paper",
									transform: "translateY(-50%) rotate(45deg)",
									zIndex: 0
								}
							}
						}
					}}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				>
					<MenuItem onClick={() => handleNavigate({ link: `member-profile/${user.userId}` })}>
						<ProfileIcon />
						<Typography variant="Medium" pl={1}>
							My Profile
						</Typography>
					</MenuItem>
					<MenuItem onClick={logout}>
						<ListItemIcon>
							<Logout fontSize="small" />
						</ListItemIcon>
						<Typography variant="Medium">Logout</Typography>
					</MenuItem>
				</Menu>
			</Grid>
		</Card>
	);
};

AppBarMtg.propTypes = {
	userData: PropTypes.object
};

export default AppBarMtg;
