import { styled } from "@mui/material";
import { Grid, Paper, Stack, Typography } from "../../../UIComponents";

import CollapsibleTable from "./CollapsibleTable";
import AssignCertificationModal from "./AssignCertificateModal";
import React, { createContext, useState } from "react";
import FilterPopover from "./FilterPopover";
import { useDispatch } from "react-redux";
import { fetchCertificationDashboard } from "../../../redux/reducers/certificationDashboard";
import ExportButton from "./ExportButton";
import UpdateCertificationModal from "./UpdateCertificateModal";
import DeleteCertificationModal from "./DeleteCertificationModal";

export const ModalContext = createContext();

const StyledPaper = styled(Paper)(({ theme }) => ({
	borderRadius: "6px",
	padding: "16px"
}));

const Dashboard = () => {
	const dispatch = useDispatch();

	const handleFilterChange = (filters) => {
		const { certification_id, platform_id, status_filter, expiry_filter } = filters;
		dispatch(
			fetchCertificationDashboard({
				certification_id,
				platform_id,
				status_filter,
				expiry_filter
			})
		);
	};

	const [assignCertModal, setAssignCertModal] = useState({
		isOpen: false,
		userId: undefined,
		disabled_cert_ids: []
	});

	const [updateCertModal, setUpdateCertModal] = useState({
		isOpen: false,
		userId: undefined,
		userCertificationId: undefined,
		userCertificationDeadline: undefined,
		userCertificationNotes: undefined,
		userCertificationTrackId: undefined
	});

	const [deleteCertModal, setDeleteCertModal] = useState({
		isOpen: false,
		userId: undefined,
		userName: undefined,
		userCertificationId: undefined,
		userCertificationName: undefined,
		userCertificationDeadline: undefined,
		userCertificationTrackId: undefined
	});

	const handleDeleteCertModalOpen = ({
		memberId,
		memberName,
		certification_id,
		certification_name,
		deadline,
		track_id
	}) => {
		setDeleteCertModal(() => ({
			isOpen: true,
			userId: memberId,
			userName: memberName,
			userCertificationId: certification_id,
			userCertificationName: certification_name,
			userCertificationDeadline: deadline,
			userCertificationTrackId: track_id
		}));
	};

	const handleDeleteCertModalClose = () => {
		setDeleteCertModal({
			isOpen: false,
			userId: undefined,
			userName: undefined,
			userCertificationId: undefined,
			userCertificationName: undefined,
			userCertificationDeadline: undefined,
			userCertificationTrackId: undefined
		});
	};

	const handleUpdateCertModalOpen = ({ memberId, certification_id, deadline, notes, track_id }) => {
		setUpdateCertModal(() => ({
			isOpen: true,
			userId: memberId,
			userCertificationId: certification_id,
			userCertificationTrackId: track_id,
			userCertificationDeadline: deadline,
			userCertificationNotes: notes
		}));
	};

	const handleUpdateCertModalClose = () => {
		setUpdateCertModal({
			isOpen: false,
			userId: undefined,
			userCertificationId: undefined,
			userCertificationDeadline: undefined,
			userCertificationNotes: undefined,
			userCertificationTrackId: undefined
		});
	};

	const handleAssignCertModalOpen = ({ memberId, disabled_cert_ids }) => {
		setAssignCertModal({
			isOpen: true,
			userId: memberId,
			disabled_cert_ids
		});
	};

	const handleAssignCertModalClose = () =>
		setAssignCertModal({
			isOpen: false,
			userId: undefined
		});

	return (
		<>
			<ModalContext.Provider
				value={{
					handleAssignCertModalOpen,
					handleUpdateCertModalOpen,
					handleDeleteCertModalOpen
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<StyledPaper direction={"column"} spacing={2} mt={2}>
							<Grid container gap={2} justifyContent="space-between">
								<Grid item xs={3}>
									<Typography fontSize="26px">Certification Dashboard</Typography>
								</Grid>
								<Grid item xs={8}>
									<Stack justifyContent="flex-end" direction="row" gap={2}>
										<ExportButton />
										<FilterPopover handleFilterChange={handleFilterChange} />
									</Stack>
								</Grid>
								<Grid item xs={12}>
									<CollapsibleTable />
								</Grid>
							</Grid>
						</StyledPaper>
					</Grid>
				</Grid>
				<AssignCertificationModal {...assignCertModal} onClose={handleAssignCertModalClose} />
				<UpdateCertificationModal {...updateCertModal} onClose={handleUpdateCertModalClose} />
				<DeleteCertificationModal {...deleteCertModal} onClose={handleDeleteCertModalClose} />
			</ModalContext.Provider>
		</>
	);
};

export default Dashboard;
