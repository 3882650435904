import { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Button } from "../UIComponents";
import IconButton from "../UIComponents/DesignSystem/IconButtton";
import CloseIcon from "@mui/icons-material/Close";

function GlobalModal(props) {
	const {
		onClose,
		value: valueProp,
		open,
		children,
		modalTitle,
		shouldShowDialogActions = true,
		...other
	} = props;
	const [value, setValue] = useState(valueProp);

	useEffect(() => {
		if (!open) {
			setValue(valueProp);
		}
	}, [valueProp, open]);

	const handleCancel = () => {
		onClose();
	};

	const handleOk = () => {
		onClose(value);
	};

	return (
		<Dialog
			sx={{
				"& .MuiDialog-paper": {
					width: "-webkit-fill-available",
					maxWidth: "80vw",
					maxHeight: "90%"
				}
			}}
			open={open}
			{...other}
		>
			<DialogTitle>{modalTitle}</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleCancel}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500]
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers>{children}</DialogContent>
			{shouldShowDialogActions && (
				<DialogActions>
					<Button autoFocus onClick={handleCancel}>
						Cancel
					</Button>
					<Button onClick={handleOk}>Ok</Button>
				</DialogActions>
			)}
		</Dialog>
	);
}
export default GlobalModal;
