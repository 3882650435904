import { useState } from "react";
import { useSelector } from "react-redux";
import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import TableBody from "../../../UIComponents/DesignSystem/TableBody.js";
import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import Table from "../../../UIComponents/DesignSystem/Table.js";
import TableHeadComponent from "./TableHeader.js";

import { Stack, Typography } from "../../../UIComponents/index.js";

import { CircularProgress, styled } from "@mui/material";
import { healthReportMapping } from "./CollapsibleTable.js";
import HistoricalHealthReportRow from "./HistoricalHealthReportRow.js";
import dayjs from "dayjs";

const StyledStack = styled(Stack)(({ theme }) => ({
	backgroundColor: theme.palette.secondaryLight.main
}));

const ProjectHealthReports = ({ historicalReportIds }) => {
	const historicalData = useSelector((state) => state.projectHealthDashboard.historical.entities);
	const historicalDataStatus = useSelector(
		(state) => state.projectHealthDashboard.historical.status
	);
	const [openPHR, setOpenPHR] = useState({});

	const handlePHRClick = (phr_id) => {
		setOpenPHR((prev) => ({ ...prev, [phr_id]: !prev[phr_id] }));
	};

	let historicHealthReports = [];
	if (historicalReportIds.length) {
		historicHealthReports = historicalReportIds.map((reportId) => historicalData[reportId]);
	}

	return (
		<StyledStack gap={1} paddingTop={1} paddingBottom={2} paddingX={2}>
			<Typography fontSize="16px" fontWeight={600}>
				Project Health Reports:
			</Typography>
			<Table aria-label="resources">
				<TableHeadComponent columns={Object.values(healthReportMapping)} />
				<TableBody>
					{historicalDataStatus === "loading" && (
						<TableRow>
							<TableCell colSpan={13} style={{ textAlign: "center", height: 100 }}>
								<CircularProgress color="primary" />
							</TableCell>
						</TableRow>
					)}
					{historicalDataStatus === "failed" && (
						<TableRow>
							<TableCell colSpan={13} style={{ textAlign: "center", height: 100 }}>
								Error in loading the data! Please try again later.
							</TableCell>
						</TableRow>
					)}
					{historicalDataStatus === "succeeded" && historicHealthReports.length === 0 && (
						<TableRow>
							<TableCell colSpan={13} style={{ textAlign: "center", height: 100 }}>
								No Records found!
							</TableCell>
						</TableRow>
					)}
					{historicHealthReports.map((historicalPrjctHealthReport) => {
						const historicalProjectHealthReport = {
							...historicalPrjctHealthReport
						};

						historicalProjectHealthReport.submitted_on = dayjs(
							historicalProjectHealthReport.submitted_on
						).format("MMM DD, YYYY");

						historicalProjectHealthReport.for_week_of = dayjs(
							historicalProjectHealthReport.for_week_of
						).format("MMM DD, YYYY");

						return (
							<HistoricalHealthReportRow
								key={historicalProjectHealthReport.id}
								report={historicalProjectHealthReport}
								openPHR={openPHR}
								handlePHRClick={handlePHRClick}
							/>
						);
					})}
				</TableBody>
			</Table>
		</StyledStack>
	);
};

export default ProjectHealthReports;
