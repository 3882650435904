import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import PTOHolidays from "./PTOHolidays";
import { HoursTabs } from "./HoursTabs";
import axios from "axios";
import ProgressBarWithText from "../../ComponentsLibrary/ProgressBarWithText";
import { useAuth } from "../../CustomHooks/AuthenticationHook";
import InformaticTitle from "../../ComponentsLibrary/InformaticTitle";
import { formatNumber } from "../../utils";
import { getUserBillable } from "../../redux/reducers/users";
import { useDispatch, useSelector } from "react-redux";

const TimeProfile = (props) => {
	const { startDate, endDate, disableTimeLogged, enableAllocatedHours, monthTab, user } = props;
	const dispatch = useDispatch();
	const [timeLogged, setTimeLogged] = useState([]);
	const [scheduledHours, setScheduledHours] = useState([]);
	const [allocatedHours, setAllocatedHours] = useState([]);
	// const { user } = useAuth();
	const billableTarget = useSelector((state) => state.users.billables[user.userId]) || {};

	useEffect(() => {
		dispatch(
			getUserBillable({
				userId: user.userId,
				startDate,
				endDate
			})
		);

		if (!disableTimeLogged) {
			axios
				.get(
					`${`${process.env.REACT_APP_PROD}/api/v1`}/time-logged/${user.userId}?from_date=${startDate}&to_date=${endDate}`
				)
				.then((res) => {
					setTimeLogged(res.data.data);
				})
				.catch((err) => console.log(err));
		}

		axios
			.get(
				`${`${process.env.REACT_APP_PROD}/api/v1`}/scheduled-hours/${user.userId}?from_date=${startDate}&to_date=${endDate}`
			)
			.then((res) => {
				if (res.data?.data?.length > 1) {
					const sortScheduledHrs = res.data.data.sort(
						(a, b) => b.scheduled_hours - a.scheduled_hours
					);
					setScheduledHours(sortScheduledHrs);
				} else {
					setScheduledHours(res.data.data);
				}
			})
			.catch((err) => console.log(err));

		//allocated Hrs
		if (enableAllocatedHours) {
			axios
				.get(
					`${`${process.env.REACT_APP_PROD}/api/v1`}/allocated-hours/${user.userId}?from_date=${startDate}&to_date=${endDate}`
				)
				.then((res) => {
					if (res.data?.data?.length > 1) {
						const allocated = res.data.data.sort((a, b) => b.allocated_hours - a.allocated_hours);
						setAllocatedHours(allocated);
					} else {
						setAllocatedHours(res.data.data);
					}
				})
				.catch((err) => console.log(err));
		}
	}, [user.userId, startDate, endDate, disableTimeLogged, enableAllocatedHours, dispatch]);

	return (
		<Stack direction={"column"} pt={1}>
			{billableTarget?.billable_hours_target && (
				<Fragment>
					<InformaticTitle
						label="Billable Target"
						titleinfo={
							!monthTab
								? "Projected billable hours for the current week will be calculated by subtracting holidays, PTOs, and approved non-billable hours from the weekly target of 40 hours."
								: "Projected billable hours for the current month will be calculated by subtracting holidays, PTOs, and approved non-billable tasks from the monthly target of 160 hours."
						}
						gutterBottom
					/>
					<ProgressBarWithText
						value={formatNumber(billableTarget?.billable_hours_logged) + "h"}
						maxValue={billableTarget?.billable_hours_target + "h"}
						showScale
						minValue={"0h"}
					/>
					<Typography
						variant="Regular"
						textAlign={"end"}
						sx={{ color: "text.disabled" }}
						gutterBottom
					>
						Non-Billable Logged: {billableTarget?.non_billable_hours_logged || 0}h
					</Typography>
				</Fragment>
			)}
			{billableTarget?.holiday_time_off?.length > 0 && (
				<PTOHolidays data={billableTarget.holiday_time_off} />
			)}
			<HoursTabs
				timeLogged={timeLogged}
				scheduledHours={scheduledHours}
				allocatedHours={allocatedHours}
				disableTimeLogged={disableTimeLogged}
				enableAllocatedHours={enableAllocatedHours}
				monthTab={monthTab}
			/>
		</Stack>
	);
};

TimeProfile.propTypes = {
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
	disableTimeLogged: PropTypes.bool,
	enableAllocatedHours: PropTypes.bool
};

export default TimeProfile;
