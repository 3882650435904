import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Card } from "@mui/material";
import moment from "moment";
import PortFolio from "./PortFolio";
import TimeProfile from "./TimeProfile";
import InformaticTitle from "../../ComponentsLibrary/InformaticTitle";

function CustomTabPanel(props) {
	const { children, value, index, boxProps, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 2 }} {...boxProps}>
					{children}
				</Box>
			)}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`
	};
}

function CustomTabContainer(props) {
	const { value, handleChange, tabs, label, titleInfoEnabled } = props;

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs value={value} onChange={handleChange} aria-label={label}>
					{tabs.map((tab, i) => (
						<Tab
							key={`Tab - ${label}-${tab.value || tab}`}
							label={
								!titleInfoEnabled ? (
									<Typography
										variant="Medium"
										fontSize={14}
										textTransform={"capitalize"}
										color={"inherit"}
									>
										{tab.name || tab}
									</Typography>
								) : (
									<InformaticTitle label={tab.name || tab} titleinfo={tab.titleinfo || "TBA"} />
								)
							}
							{...a11yProps(tab.value || i)}
						/>
					))}
				</Tabs>
			</Box>
			{props.children}
		</Box>
	);
}
CustomTabContainer.propTypes = {
	value: PropTypes.number.isRequired,
	handleChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	tabs: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				name: PropTypes.string.isRequired,
				value: PropTypes.any.isRequired
			})
		])
	).isRequired,
	titleInfoEnabled: PropTypes.bool
};

function TimeEntryProfileTabs(props) {
	const [value, setValue] = React.useState(0);
	const currentDate = moment();
	// const classes=useStyles();
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Card sx={{ height: "100%" }}>
			<CustomTabContainer
				value={value}
				handleChange={handleChange}
				label={"Time Entry Tabs"}
				tabs={["This Week", "This Month", "Portfolio"]}
			>
				<CustomTabPanel value={value} index={0}>
					<TimeProfile
						{...props}
						startDate={currentDate.clone().startOf("isoWeek").format("YYYY-MM-DD")}
						endDate={currentDate.clone().endOf("isoWeek").format("YYYY-MM-DD")}
					/>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={1}>
					<TimeProfile
						{...props}
						startDate={currentDate.clone().startOf("month").format("YYYY-MM-DD")}
						endDate={currentDate.clone().endOf("month").format("YYYY-MM-DD")}
						disableTimeLogged
						enableAllocatedHours
						monthTab
					/>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={2}>
					<PortFolio />
				</CustomTabPanel>
			</CustomTabContainer>
		</Card>
	);
}

export { CustomTabPanel, TimeEntryProfileTabs, a11yProps, CustomTabContainer };
