import { useState, useCallback } from "react";
import { Paper } from "../../../UIComponents/index.js";
import Table from "../../../UIComponents/DesignSystem/Table.js";
import TableBody from "../../../UIComponents/DesignSystem/TableBody.js";
import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import TableContainer from "../../../UIComponents/DesignSystem/TableContainer.js";
import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import TableHeadComponent from "./TableHeader.js";
import { selectAllCertificationIds } from "../../../redux/reducers/certificationDashboard.js";
import UserRow from "./UserRow.js";

export const user_mapping = {
	employee_name: "Name",
	email: "Email",
	manager_name: "Manager",
	num_certifications_held: "Number of Certifications held",
	num_certifications_assigned: "Number of Certifications assigned",
	most_recent_expiry_date: "Most Recent expiry date",
	most_recent_due_date: "Most Recent Due date"
};

export const certification_mapping = {
	certification_name: "Certification Name",
	status: "Status",
	expiry_date: "Expiry Date",
	days_to_expire: "Days to Expire",
	counts_towards_partner_count: "Counts towards Partner Count"
};

export const assigned_certification_mapping = {
	certification_name: "Certification Name",
	status: "Status",
	assigned_on: "Assigned on",
	due_on: "Due on",
	number_of_attempts: "Number of attempts",
	assigned_by_name: "Assigned by",
	modified_by_name: "Modified by",
	actions: "Actions"
};

const CollapsibleTable = () => {
	const loadingState = useSelector((state) => state.userCertifications.status);
	const userCertificationIds = useSelector(selectAllCertificationIds);

	const [expandUser, setExpandUser] = useState({});

	const handleUserExpandToggle = useCallback(({ userId }) => {
		setExpandUser((prev) => ({ ...prev, [userId]: !prev[userId] }));
	}, []);

	return (
		<>
			<TableContainer component={Paper}>
				<Table aria-label="collapsible table">
					<TableHeadComponent columns={Object.values(user_mapping)} />
					<TableBody>
						{loadingState === "loading" && (
							<TableRow>
								<TableCell colSpan={13} style={{ textAlign: "center", height: 100 }}>
									<CircularProgress color="primary" />
								</TableCell>
							</TableRow>
						)}
						{loadingState === "failed" && (
							<TableRow>
								<TableCell colSpan={13} style={{ textAlign: "center", height: 100 }}>
									Error in loading the data! Please try again later.
								</TableCell>
							</TableRow>
						)}
						{loadingState === "succeeded" && userCertificationIds.length === 0 && (
							<TableRow>
								<TableCell colSpan={13} style={{ textAlign: "center", height: 100 }}>
									No Records found!
								</TableCell>
							</TableRow>
						)}
						{loadingState === "succeeded" &&
							userCertificationIds.length !== 0 &&
							userCertificationIds.map((userId) => {
								return (
									<UserRow
										key={userId}
										userId={userId}
										expandUser={expandUser}
										handleUserExpandToggle={handleUserExpandToggle}
									/>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default CollapsibleTable;
