import React, { useEffect, useState } from "react";
import { Modal } from "../../UIComponents/index.js";
import axios from "axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./skills.css";

import { Form } from "react-bootstrap";
import { useAuth } from "../../CustomHooks/AuthenticationHook";
import Button from "../../UIComponents/DesignSystem/Button.js";

const SkillsPopup2 = (props) => {
	const { isOpen, handleClose, platformData, userSkills, getMemberDetails } = props;

	const [selectedPlatform, setSelectedPlatform] = useState("");
	const [availableSkills, setAvailableSkills] = useState([]);
	const [existingUserSkills, setExistingUserSkills] = useState(null);
	const [loading, setLoading] = useState(false);
	const { user } = useAuth();
	const [checkedItems, setCheckedItems] = useState([]);
	const toggleValue = (myArray, valueToAddOrRemove) => {
		if (myArray?.includes(valueToAddOrRemove)) {
			// If the value exists, remove it
			const newArray = myArray.filter((item) => item !== valueToAddOrRemove);
			return newArray;
		} else {
			// If the value doesn't exist, add it
			if (myArray) {
				const newArray = [...myArray, valueToAddOrRemove];
				return newArray;
			} else {
				return [valueToAddOrRemove];
			}
		}
	};
	const handleCheckboxChange = (value) => {
		setCheckedItems((prevCheckedItems) => {
			return toggleValue(prevCheckedItems, value);
		});
	};

	useEffect(() => {
		console.log(checkedItems);
	}, [checkedItems]);

	const handleSubmit = () => {
		if (checkedItems.length > 0) {
			setLoading(true);
			axios
				.post(`${`${process.env.REACT_APP_PROD}/api/v1`}/addSkills`, {
					userSkills: checkedItems,
					userId: user.userId
				})
				.then((res) => {
					console.log("Skills added", res);
				})
				.catch((err) => {
					console.log("Something failed while updating skills", err);
				})
				.finally(() => {
					console.log("request finished");
					setLoading(false);
					handleClose();
					setCheckedItems([]);
					getMemberDetails();
				});
		} else {
			handleClose();
		}
	};

	useEffect(() => {
		const userSkillIds = userSkills?.map((eachSkill) => eachSkill.skill_id);
		setExistingUserSkills(userSkillIds);
	}, [userSkills]);

	return (
		<Modal
			className="mtg-skill-popup"
			show={isOpen}
			onHide={() => {
				setSelectedPlatform("");
				setAvailableSkills([]);
				handleClose();
			}}
			centered
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title>Add Skills</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div
					style={{
						display: "flex",
						gap: "20px",
						justifyContent: "center",
						flexDirection: "column"
					}}
				>
					<Box sx={{ minWidth: 120 }}>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Platform</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={selectedPlatform}
								label="Platform"
							>
								{platformData.map((eachPlatform, id) => (
									<MenuItem
										key={id}
										value={eachPlatform}
										onClick={() => {
											setSelectedPlatform(eachPlatform);
											setAvailableSkills(eachPlatform.mtg_skills);
										}}
									>
										{eachPlatform.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					{availableSkills.length > 0 && existingUserSkills ? (
						<Form>
							{availableSkills.map((eachSkill, i) => {
								const doesSkillAlreadyExist = existingUserSkills?.includes(eachSkill.id);
								return (
									<Form.Check
										key={i}
										disabled={doesSkillAlreadyExist}
										type="checkbox"
										label={eachSkill.name}
										checked={checkedItems?.includes(eachSkill.id) || doesSkillAlreadyExist || false}
										onChange={() => handleCheckboxChange(eachSkill.id)}
									/>
								);
							})}
						</Form>
					) : (
						selectedPlatform !== "" && "No skills available for this platform"
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					disabled={loading}
					color="primary"
					variant="contained"
					size="small"
					onClick={handleSubmit}
				>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default SkillsPopup2;
