/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { Typography } from "../../UIComponents";
import { useMemo } from "react";
import { copyHTMLasRichText } from "../../Utils/CopyToClipboard";
import Button from "../../UIComponents/DesignSystem/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const returnTextIfPresent = ({ variable, formattedTextTemplate }) => {
	return variable ? formattedTextTemplate : "";
};

export const getUserTags = ({ allUsersMap = {}, userIdArr = [] }) => {
	return (
		userIdArr?.map(
			(member_id) =>
				`@${allUsersMap[member_id]?.name.replaceAll(" ", "")}` || `MTG Member Id: ${member_id}`
		) || []
	);
};

export const getNoteSummaryString = (
	{
		title,
		total_utlised_hours,
		total_hours,
		this_month_budgeted_hours,
		this_week_budgeted_hours,
		agenda,
		general_notes,
		magna_next_steps,
		client_next_steps,
		note_owners_tags = []
	},
	forClipboard
) =>
	returnTextIfPresent({
		variable: general_notes || magna_next_steps || client_next_steps || forClipboard,
		formattedTextTemplate: `
  <b>Project Title: ${title}</b><br/>
  <br/>
  <b>Hours Summary:</b><br/>
  <b>Overall:&nbsp;</b>${total_utlised_hours}/${total_hours} hours<br/>
  <b>This Month:&nbsp;</b> ${this_month_budgeted_hours} hours<br/>
  <b>This Week:&nbsp;</b> ${this_week_budgeted_hours} hours<br/>
  <br/>
  ${
		note_owners_tags.length
			? `<b>Note Owners</b><br/>
        ${note_owners_tags.map((tag) => `${tag} `)}`
			: ""
	}
  ${note_owners_tags.length && "<br/><br/>"}
  ${returnTextIfPresent({
		variable: agenda,
		formattedTextTemplate: `<b>Agenda: </b> <br/>
  ${agenda}<br/>`
	})}
  ${returnTextIfPresent({
		variable: general_notes,
		formattedTextTemplate: `<b>General Notes: </b> <br/>
  ${general_notes}<br/>`
	})}
  ${returnTextIfPresent({
		variable: magna_next_steps || client_next_steps,
		formattedTextTemplate: `<b>Next Steps:</b><br/>
    <b>MTG</b> <br/>${magna_next_steps}<br/>
    <b>Client</b> <br/>${client_next_steps}<br/>`
	})}
`
	});

const getHTMLClipboardString = ({
	allDeliverablesForThisWorkspace,
	current_meeting_id,
	title,
	total_hours,
	total_utlised_hours,
	this_month_budgeted_hours,
	this_week_budgeted_hours,
	agenda,
	general_notes,
	magna_next_steps,
	client_next_steps,
	deliverables,
	note_owners_tags,
	allUsersMap
}) => {
	let deliverable_string = "<span>";
	const current_meeting_deliverbale_note_map = deliverables.map(
		([deliverableId, deliverableNotesMap]) => {
			let deliverable_note = "";
			let note_owners = [];
			let deliverable_note_owners_tags = [];
			const deliverable_title = allDeliverablesForThisWorkspace[deliverableId]?.title;
			const deliverable_notes = Object.values(deliverableNotesMap).filter(
				(note) => note.meeting_id === current_meeting_id
			);
			if (deliverable_notes.length) {
				deliverable_note = deliverable_notes[0]?.note_content;
				note_owners = deliverable_notes[0]?.note_owners;
				deliverable_note_owners_tags = getUserTags({
					allUsersMap,
					userIdArr: note_owners
				});
			}

			return [deliverable_title, deliverable_note, deliverable_note_owners_tags];
		}
	);

	current_meeting_deliverbale_note_map.forEach(
		([title, noteHTML, deliverable_note_owners_tags], index) => {
			deliverable_string += `
        <b>${index + 1}. ${title}</b> <br/>
        a. ${returnTextIfPresent({
					variable: deliverable_note_owners_tags.length,
					formattedTextTemplate: `<b>Note Owners</b>
        ${note_owners_tags.map((tag) => `${tag} `)} <br/>`
				})}
        b. <b>Content</b>: ${noteHTML}<br/>
        `;
		}
	);

	deliverable_string += "</span>";

	const summaryString = `
    ${getNoteSummaryString(
			{
				title,
				total_utlised_hours,
				total_hours,
				this_month_budgeted_hours,
				this_week_budgeted_hours,
				agenda,
				general_notes,
				magna_next_steps,
				client_next_steps,
				note_owners_tags
			},
			true
		)}
    <br/>
    <b>Deliverables</b><br/>
    ${deliverable_string}
  `;
	return summaryString;
};

const CopyMeetingNotes = ({ workspaceId, meetingId }) => {
	const current_workspace = useSelector((state) => state.workspaces.all[workspaceId]);
	const allUsersMap = useSelector((state) => state.users.all);

	const {
		title,
		budgeted_hours,
		used_budgeted_hours,
		this_month_budgeted_hours = 0,
		this_week_budgeted_hours = 0
	} = current_workspace || {};

	const current_meeting = useSelector(
		(state) => state.decisions.meetings[workspaceId]?.[meetingId]
	);
	const {
		magna_next_steps = "",
		client_next_steps = "",
		general_notes = "",
		agenda = "",
		note_owners = []
	} = current_meeting || {};
	const note_owners_tags = getUserTags({ allUsersMap, userIdArr: note_owners });

	const allDeliverablesForThisWorkspace =
		useSelector((state) => state.workspaces.deliverables[workspaceId]) || {};

	const allNotedDeliverablesForThisWorkspace =
		useSelector((state) => state.decisions.deliverables_notes[workspaceId]) || {};
	const listOfNotedDeliverables = useMemo(
		() =>
			Object.entries(allNotedDeliverablesForThisWorkspace).filter(([_, deliverables]) =>
				Object.values(deliverables).some(({ meeting_id }) => meetingId === meeting_id)
			),
		[allNotedDeliverablesForThisWorkspace, meetingId]
	);

	const handleCopyMeetingNotes = async (e) => {
		e.stopPropagation(); /** Stopping propogation so btn does not toggle Accordian */
		const htmlString = getHTMLClipboardString({
			allDeliverablesForThisWorkspace,
			current_meeting_id: meetingId,
			title,
			total_hours: budgeted_hours,
			total_utlised_hours: used_budgeted_hours,
			this_month_budgeted_hours: this_month_budgeted_hours ?? 0,
			this_week_budgeted_hours: this_week_budgeted_hours ?? 0,
			agenda,
			general_notes,
			magna_next_steps,
			client_next_steps,
			deliverables: listOfNotedDeliverables,
			note_owners_tags,
			allUsersMap
		});
		copyHTMLasRichText({ htmlString });
	};

	return (
		<Button
			startIcon={<ContentCopyIcon />}
			color="primaryLight"
			variant="contained"
			size="small"
			onClick={(e) => handleCopyMeetingNotes(e)}
		>
			<Typography>Copy Meeting Notes</Typography>
		</Button>
	);
};

export default CopyMeetingNotes;
