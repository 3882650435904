import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { formatDate } from "../../utils";

const PTOHolidays = (props) => {
	const { data } = props;

	const sortedData = [...data].sort((a, b) => {
		const dateA = new Date(a.holiday_time_off_date);
		const dateB = new Date(b.holiday_time_off_date);
		return dateA - dateB;
	});

	return (
		<Box
			p={2}
			sx={{
				backgroundColor: "#EA545512",
				borderRadius: "10px"
			}}
		>
			<Typography variant="Medium" gutterBottom>
				Time Off
				<Divider sx={{ borderColor: "#00000061" }} />
			</Typography>
			<Stack direction={"column"} spacing={1 / 2}>
				{sortedData.map((dayOff, i) => (
					<Stack
						key={`pto-${dayOff.holiday_timeoff_id || i}`}
						direction={"row"}
						justifyContent={"space-between"}
					>
						<Typography variant="Medium" fontSize={10}>
							{dayOff.holiday_type === "time-off"
								? `PTO (${dayOff.hours}h)`
								: `${dayOff.holiday_type} (${dayOff.hours}h)`}
						</Typography>
						<Typography variant="Medium" fontSize={10}>
							{formatDate(dayOff.holiday_time_off_date)}
						</Typography>
					</Stack>
				))}
			</Stack>
		</Box>
	);
};

PTOHolidays.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			holiday_time_off_date: PropTypes.string.isRequired,
			holiday_type: PropTypes.string.isRequired,
			holiday_timeoff_id: PropTypes.number
		})
	)
};

export default PTOHolidays;
