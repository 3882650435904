/* eslint-disable react-hooks/exhaustive-deps */
import ReactQuill from "react-quill";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";
import "quill-mention/dist/quill.mention.css";
import { Stack, styled } from "@mui/material";
import { preventCustomPropForwarding } from "../Utils/MUI";

const StyledQuillWrapper = styled(
	Stack,
	preventCustomPropForwarding(["editorHeight", "toolbarHeight", "hideToolbar"])
)(({ theme, editorHeight, toolbarHeight, hideToolbar }) => ({
	"& > .quill": {
		display: "flex",
		flexDirection: "column-reverse"
	},
	".ql-toolbar": {
		display: hideToolbar && "none",
		borderRadius: "0 0 8px 8px",
		border: `1px solid ${theme.palette.border.main} !important`
	},
	".ql-toolbar.ql-snow + .ql-container.ql-snow": {
		borderBottom: 0,
		borderTop: `1px solid ${theme.palette.border.main} !important`,
		borderRadius: hideToolbar ? "8px 8px 8px 8px" : "8px 8px 0 0"
	},
	".ql-container.ql-snow": {
		border: `1px solid ${theme.palette.border.main} !important`,
		borderBottom: !hideToolbar && "0 !important"
	},
	".ql-editor": {
		height: `calc(${editorHeight} + ${toolbarHeight}px) !important`,
		maxHeight: `calc(${editorHeight} + ${toolbarHeight}px) !important`,
		overflow: "auto"
	}
}));

export default function NewQuillEditor({
	placeholder,
	value,
	onChange,
	editorHeight = "200px",
	hideToolbar = false,
	sx,
	mentionList,
	...rest
}) {
	const editor = useRef(null);
	const [toolbarHeight, setToolbarHeigh] = useState();

	const modules = {
		toolbar: [
			"bold",
			"italic",
			"strike",
			"link",
			{ header: [] },
			{ list: "ordered" },
			{ list: "bullet" }
		],
		clipboard: {
			matchVisual: false
		},
		mention: useMemo(
			() => ({
				allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
				mentionDenotationChars: ["@"],
				source: function (searchTerm, renderList, mentionChar) {
					let values;

					if (mentionChar === "@") {
						values = mentionList || [];
					}

					if (searchTerm.length === 0) {
						renderList(values, searchTerm);
					} else {
						const matches = [];
						for (let i = 0; i < values.length; i++) {
							if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
								matches.push(values[i]);
						}
						renderList(matches, searchTerm);
					}
				}
			}),
			[mentionList]
		)
	};

	useEffect(() => {
		if (value) {
			const delta = editor.current.editor.clipboard.convert(value);
			editor.current.editor.setContents(delta, "silent");
		}
	}, []);

	useEffect(() => {
		if (hideToolbar) {
			setToolbarHeigh(0);
			return;
		}
		const height = document.getElementsByClassName("ql-toolbar")[0].clientHeight;
		setToolbarHeigh(height);
	}, [hideToolbar]);

	return (
		<StyledQuillWrapper
			sx={sx}
			hideToolbar={hideToolbar}
			editorHeight={editorHeight}
			toolbarHeight={toolbarHeight}
		>
			<ReactQuill
				ref={editor}
				theme="snow"
				modules={modules}
				onKeyUp={() => {
					if (editor.current.editor) {
						const delta = editor.current.editor.getContents();
						const html = editor.current.editor.root.innerHTML;
						if (editor.current.editor.getText() === "\n") onChange({ html: "", delta });
						else onChange({ html, delta });
					}
				}}
				placeholder={placeholder}
				{...rest}
			/>
		</StyledQuillWrapper>
	);
}
