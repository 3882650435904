import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../components/Login";
import AddAuth from "../components/AddAuth";
import AllClient from "../components/AllClient";
import Trainer from "../components/pdfView";
import ChangePass from "../components/change-password";
// import DelivarablesDashboardIndex from "../DelivarablesDashboard";
import ForcastPacingDashboard from "../ForcastPacingDashboard";
import { AuthorisedRoute } from "../Routes/AuthorisedRoute";
import MemberPerfProfile from "../components/MemberPerformanceProfile";
import MemberProfile from "../components/MemberProfile";
import ActiveDeliverables from "../components/ActiveDeliverables";
import UpdateNonBillable from "../components/UpdateNonBillable";
import DecisonTracker from "../components/DecisionTracker";
import DecisionTrackerForm from "../components/DecisionTracker/DecisionTrackerForm";
// import NewQuillEditor from "../UIComponents/NewQuill";
import BillableUtilisationDashboard from "../components/BillableUtilisation";
import TimeEntryDashboard from "../components/TimeEntry/index.js";
import ProjectHealthDashboard from "../components/Reports/ProjectHealthDashboard/index.js";
import CertificationDashboard from "../components/Reports/CertificationDashboard/index.js";

const MtgBridgeRoutes = (props) => {
	return (
		<Routes>
			<Route exact path="/" element={<Login />}></Route>
			<Route element={<AuthorisedRoute />}>
				<Route path="auth-codes" element={<AllClient />}></Route>
				<Route path="dashboard" element={<MemberPerfProfile />}></Route>
				<Route path="dashboard/:userId" element={<MemberPerfProfile />}></Route>
				<Route path="add-auth" element={<AddAuth />}></Route>
				<Route path="manual" element={<Trainer />}></Route>
				<Route path="change-password" element={<ChangePass />}></Route>
				{/* <Route
          path="delivarables"
          element={<DelivarablesDashboardIndex />}
        ></Route> */}
				<Route path="development-deliverables" element={<ActiveDeliverables />}></Route>
				<Route path="project-health-dashboard" element={<ProjectHealthDashboard />}></Route>
				<Route path="forecast" element={<ForcastPacingDashboard />}></Route>
				<Route path="billable-utilization" element={<BillableUtilisationDashboard />} />
				<Route path="time-sheet-summary" element={<TimeEntryDashboard />} />
				<Route path="member-profile/:userId" element={<MemberProfile />}></Route>
				<Route path="update-non-billable-time" element={<UpdateNonBillable />}></Route>
				<Route path="decision-tracker" element={<DecisonTracker />} />
				<Route path="decision-tracker/:workspaceId" element={<DecisionTrackerForm />} />
				<Route path="user-certifications" element={<CertificationDashboard />} />
				{/* <Route path="test" element={<NewQuillEditor />}></Route> */}
			</Route>
			<Route path="*" element={<div>404</div>}></Route>
		</Routes>
	);
};

export default MtgBridgeRoutes;
