import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	createNewDeliverableNoteAPI,
	createNewMeetingAPI,
	getDeliverablesNotesByWorkspaceAPI,
	getMeetingsByWorkspaceAPI,
	publishMeetingNotesAPI,
	updateDeliverableNoteAPI,
	updateWorkspaceMeetingAPI
} from "../../apis/Decisions";
import { toast } from "react-toastify";

export const publishMeetingNotesThunk = createAsyncThunk(
	"decisions/publishMeetingNotesThunk",
	async (payload, { dispatch }) => {
		const {
			meeting_id,
			workspace_id,
			meeting_note_summary,
			post_id,
			deliverable_notes_summary_map
		} = payload;
		const {
			workspace_post_id,
			deliverables_post_id
			// error_obj
		} = await publishMeetingNotesAPI({
			meeting_id,
			workspace_id,
			post_id,
			meeting_note_summary,
			deliverable_notes_summary_map
		});
		dispatch(updatePostIdOfMeeting({ workspace_id, meeting_id, workspace_post_id }));
		dispatch(updatePostIdOfDeliverableNotes({ workspace_id, deliverables_post_id }));
		toast("Notes posted successfully");
	}
);

export const getMeetingsByWorkspace = createAsyncThunk(
	"decisions/getMeetingsByWorkspace",
	async (payload, { dispatch }) => {
		const { workspace_id } = payload;
		const workspace_meetings = await getMeetingsByWorkspaceAPI({
			workspace_id
		});
		dispatch(updateWorkspaceMeetings({ workspace_id, workspace_meetings }));
	}
);

export const createNewMeeting = createAsyncThunk(
	"decisions/createNewMeeting",
	async (payload, { dispatch }) => {
		const { workspace_id } = payload;
		const workspace_meeting = await createNewMeetingAPI({ workspace_id });
		dispatch(addMeetingToWorkspace({ meeting: workspace_meeting, workspace_id }));
		toast("Meeting Created successfully");
	}
);

export const updateWorkspaceMeetingThunk = createAsyncThunk(
	"decisions/updateWorkspaceMeetingThunk",
	async (payload, { dispatch }) => {
		const {
			meeting_id,
			magna_next_steps,
			client_next_steps,
			general_notes,
			agenda,
			discussed_deliverables_ids,
			title
		} = payload;
		const workspace_meeting = await updateWorkspaceMeetingAPI({
			meeting_id,
			magna_next_steps,
			client_next_steps,
			general_notes,
			agenda,
			discussed_deliverables_ids,
			title
		});
		dispatch(updateWorkspaceMeeting({ workspace_meeting }));
		toast("Meeting Saved Successfully");
	}
);

export const getDeliverablesNotesByWorkspace = createAsyncThunk(
	"decisions/getDeliverablesNotesByWorkspace",
	async ({ workspace_id }, { dispatch }) => {
		const deliverables_notes = await getDeliverablesNotesByWorkspaceAPI({
			workspace_id
		});
		dispatch(updateDeliverablesNotes({ workspace_id, deliverables_notes }));
	}
);

export const createNewDeliverableNote = createAsyncThunk(
	"decisions/createNewDeliverableNote",
	async (payload, { dispatch }) => {
		const { workspace_id, deliverable_id, meeting_id, note_content } = payload;
		const deliverable_note = await createNewDeliverableNoteAPI({
			workspace_id,
			deliverable_id,
			meeting_id,
			note_content
		});
		dispatch(addDeliverableNote({ deliverable_note, workspace_id, deliverable_id }));
		toast.success("Note is created");
		return deliverable_note;
	}
);

export const updateDeliverablesNoteThunk = createAsyncThunk(
	"decision/updateDeliverablesNoteThunk",
	async ({ note_id, note_content }, { dispatch }) => {
		const deliverable_note = await updateDeliverableNoteAPI({
			note_id,
			note_content
		});
		const { workspace_id, deliverable_id } = deliverable_note;
		dispatch(
			updateDeliverablesNote({
				workspace_id,
				deliverable_id,
				note_id,
				deliverable_note
			})
		);
		toast.success("Note is updated");
		return deliverable_note;
	}
);

const initialState = {
	meetings: {},
	deliverables_notes: {}
};

export const decisionsSlice = createSlice({
	name: "decisions",
	initialState,
	reducers: {
		updateWorkspaceMeetings: (state, { payload }) => {
			const { workspace_id, workspace_meetings } = payload;
			state.meetings[workspace_id] = workspace_meetings;
		},
		addMeetingToWorkspace: (state, { payload }) => {
			const { workspace_id, meeting } = payload;
			const { meeting_id } = meeting;
			state.meetings[workspace_id][meeting_id] = meeting;
		},
		updateWorkspaceMeeting: (state, { payload }) => {
			const { workspace_meeting } = payload;
			const { workspace_id, meeting_id } = workspace_meeting;
			state.meetings[workspace_id][meeting_id] = workspace_meeting;
		},
		updateDeliverablesNotes: (state, { payload }) => {
			const { workspace_id, deliverables_notes } = payload;
			state.deliverables_notes[workspace_id] = deliverables_notes;
		},
		addDeliverableNote: (state, { payload }) => {
			const { workspace_id, deliverable_id, deliverable_note } = payload;
			const { note_id } = deliverable_note;
			state.deliverables_notes[workspace_id] = {
				...state.deliverables_notes[workspace_id],
				[deliverable_id]: {
					...state.deliverables_notes[workspace_id][deliverable_id],
					[note_id]: deliverable_note
				}
			};
		},
		updateDeliverablesNote: (state, { payload }) => {
			const { workspace_id, deliverable_id, note_id, deliverable_note } = payload;
			state.deliverables_notes[workspace_id][deliverable_id][note_id] = deliverable_note;
		},
		updatePostIdOfDeliverableNotes: (state, { payload }) => {
			const { workspace_id, deliverables_post_id = {} } = payload;
			Object.entries(deliverables_post_id).forEach(([deliverable_id, { post_id, note_id }]) => {
				state.deliverables_notes[workspace_id][deliverable_id][note_id].post_id = post_id;
			});
		},
		updatePostIdOfMeeting: (state, { payload }) => {
			const { workspace_id, meeting_id, workspace_post_id: post_id } = payload;
			state.meetings[workspace_id][meeting_id].post_id = post_id;
		}
	}
});

const { actions, reducer } = decisionsSlice;

export const {
	updateWorkspaceMeetings,
	addMeetingToWorkspace,
	updateWorkspaceMeeting,
	updateDeliverablesNotes,
	addDeliverableNote,
	updateDeliverablesNote,
	updatePostIdOfMeeting,
	updatePostIdOfDeliverableNotes
} = actions;

export default reducer;
