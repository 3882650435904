import { styled } from "@mui/material";
import { TableContainer as TableContainerComp } from "../index.js";

const StyledTableContainer = styled(TableContainerComp)(({ theme }) => ({}));

const TableContainer = (props) => {
	return <StyledTableContainer {...props} />;
};

export default TableContainer;
