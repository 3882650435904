import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserTeamMembers } from "./users";

export const getInitData = createAsyncThunk(
	"apiStatus/getInitData",
	async ({ userId }, { dispatch }) => {
		await dispatch(getUserTeamMembers({ userId }));
	}
);

const getApiIdentifier = (type) => {
	let apiIdentifier = type.split("/");
	apiIdentifier.pop();
	return apiIdentifier.join("/");
};

function isPendingAction(action) {
	return action.type.endsWith("/pending");
}

function isRejectedAction(action) {
	return action.type.endsWith("/rejected");
}

function isFulfilledAction(action) {
	return action.type.endsWith("/fulfilled");
}

export const apiStatusSlice = createSlice({
	name: "apiStatus",
	initialState: {},
	reducers: {
		resetStatusData: (state, { payload }) => {
			const { apiIdentifier } = payload;
			delete state[apiIdentifier];
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(isPendingAction, (state, { type }) => {
			const apiIdentifier = getApiIdentifier(type);
			state[apiIdentifier] = {
				isLoading: true,
				isSuccess: false,
				error: false
			};
		});
		builder.addMatcher(isRejectedAction, (state, { type }) => {
			const apiIdentifier = getApiIdentifier(type);
			state[apiIdentifier] = {
				isLoading: false,
				isSuccess: false,
				error: true
			};
		});
		builder.addMatcher(isFulfilledAction, (state, { type }) => {
			const apiIdentifier = getApiIdentifier(type);
			state[apiIdentifier] = {
				isLoading: false,
				isSuccess: true,
				error: false
			};
		});
	}
});

const { actions, reducer } = apiStatusSlice;
export const { resetStatusData } = actions;
export default reducer;
