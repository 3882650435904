import { styled } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper } from "../../../UIComponents/index.js";
import {
	fetchCertificationDashboard,
	selectAllCertifications
} from "../../../redux/reducers/certificationDashboard.js";
import Dashboard from "./Dashboard.js";

export const StyledComponentWrapper = styled(Paper)(() => ({
	width: "inherit",
	padding: "1.25rem"
}));

const CertificationDashboard = () => {
	const dispatch = useDispatch();
	const userCertifications = useSelector(selectAllCertifications);
	useEffect(() => {
		if (!userCertifications.length) {
			dispatch(fetchCertificationDashboard());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} xl={12}>
					<Dashboard />
				</Grid>
			</Grid>
		</>
	);
};

export default CertificationDashboard;
