import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

const SelectOption = (props) => {
	const { label, value, onChange, options, error, helperText } = props;
	return (
		<FormControl
			sx={{ m: 1, minWidth: 200, marginLeft: 0, ...props.style }}
			size="small"
			error={error}
		>
			<InputLabel id="demo-select-small">{label}</InputLabel>
			<Select
				labelId="demo-select-small"
				id="demo-select-small"
				value={value}
				label={label}
				onChange={onChange}
			>
				{options.map(({ id, title }, index) => (
					<MenuItem key={index} value={id}>
						{title}
					</MenuItem>
				))}
			</Select>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};

SelectOption.propTypes = {
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onChange: PropTypes.func.isRequired
};

export default SelectOption;
