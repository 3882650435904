import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { Stack, Typography, styled } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { preventCustomPropForwarding } from "../Utils/MUI";

const StyledTypography = styled(
	Typography,
	preventCustomPropForwarding(["strikeThrough"])
)(({ theme, strikeThrough }) => ({
	color: strikeThrough && theme.palette.disabled.main,
	textDecoration: strikeThrough ? "line-through !important" : "inherit"
}));

const CustomBadge = (props) => {
	let {
		text,
		state,
		helperText,
		backgroundColor,
		color,
		enableIcon,
		strikeThrough,
		squareBorder,
		onClick
	} = props;

	if (backgroundColor) {
		backgroundColor = props.backgroundColor;
		color = props.color;
	} else if (state === "Current" || state === "success") {
		backgroundColor = "rgba(40, 199, 111, 0.12)";
		color = "#28C76F";
	} else if (state === "Expired") {
		backgroundColor = "rgba(234, 84, 85, 0.12)";
		color = "#EA5455";
	} else if (state === "Maintenance Due" || state === "due") {
		backgroundColor = "rgba(255, 159, 67, 0.12)";
		color = "#FF9F43";
	} else if (state === "Overdue" || state === "overdue") {
		backgroundColor = "rgba(234, 84, 85, 0.12)";
		color = "#EA5455";
	} else if (state === "Ongoing") {
		backgroundColor = "rgba(255, 159, 67, 0.12)";
		color = "#FF9F43";
	} else {
		backgroundColor = "#00CFE84d";
		color = "#00CFE8";
	}

	const getBadgeIcon = useMemo(() => {
		if (state === "overdue") {
			return <ClearIcon fontSize="small" sx={{ color: color, pr: 1 / 2 }} />;
		}
		return <CheckIcon fontSize="small" sx={{ color: color, pr: 1 / 2 }} />;
	}, [state, color]);

	return (
		<Fragment>
			<Stack direction={"column"}>
				<div
					style={{
						padding: squareBorder ? "2px 8px" : "2px 16px",
						borderRadius: squareBorder ? 5 : 15,
						backgroundColor: backgroundColor,
						textAlign: "center",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						width: "max-content",
						cursor: onClick ? "pointer" : "inherit"
					}}
					onClick={onClick}
				>
					{enableIcon && <Fragment> {getBadgeIcon}</Fragment>}
					<StyledTypography variant="SemiBold" color={color} strikeThrough={strikeThrough}>
						{text}
					</StyledTypography>
				</div>
				{helperText && (
					<StyledTypography
						variant="SemiBold"
						strikeThrough={strikeThrough}
						fontSize={"8px"}
						lineHeight={"18px"}
						ml={1}
					>
						{helperText}
					</StyledTypography>
				)}
			</Stack>
		</Fragment>
	);
};

CustomBadge.propTypes = {
	text: PropTypes.string,
	helperText: PropTypes.string,
	state: PropTypes.oneOf(["success", "due", "overdue"]),
	backgroundColor: PropTypes.string,
	enableIcon: PropTypes.bool,
	squareBorder: PropTypes.bool,
	onClick: PropTypes.func
};

export default CustomBadge;
