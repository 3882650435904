import { Typography } from "../../UIComponents";
import Button from "../../UIComponents/DesignSystem/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyHTMLasRichText } from "../../Utils/CopyToClipboard";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const IGNORE_IDS_LIST = [3];

const getMissingTimeEntriesData = ({ timeEntryData, allUsersMap }) => {
	const missingTimeEntriesByResourceId = timeEntryData.reduce((acc, curr) => {
		const { member_id, calculated_status, date_performed: date_performed_string } = curr;
		const date_performed = dayjs(date_performed_string, "YYYY-MM-DD");
		if (calculated_status === "Missing" && !IGNORE_IDS_LIST.includes(member_id)) {
			if (!acc[member_id]) {
				acc[member_id] = [];
			}
			acc[member_id].push(date_performed.format("M/D"));
		}
		return acc;
	}, {});

	const missingTimeEntriesByResource = Object.fromEntries(
		Object.entries(missingTimeEntriesByResourceId)
			.map(([resource_id, data]) => [allUsersMap[resource_id]?.name, data])
			.sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
	);
	return missingTimeEntriesByResource;
};

const getHTMLString = ({ missingTimeEntriesByResources }) => {
	let HTMLString = `
    <h2 id="missing-time-sheet-entries">Missing Time Sheet Entries</h2><br/>
    <ol>
    `;
	Object.entries(missingTimeEntriesByResources).forEach(
		([resource_name, time_sheet_missing_data]) => {
			HTMLString += `
            <li>
                ${resource_name} : ${time_sheet_missing_data.toString()}
            </li>
        `;
		}
	);

	HTMLString += `</ol>`;
	console.log({ HTMLString });
	return HTMLString;
};

const CopyTimeEntryReport = ({ timeEntryData, disabled }) => {
	const allUsersMap = useSelector((state) => state.users.all);

	const handleCopyTimeEntryReport = async () => {
		const missingTimeEntriesByResources = getMissingTimeEntriesData({
			timeEntryData,
			allUsersMap
		});
		const htmlString = getHTMLString({ missingTimeEntriesByResources });
		await copyHTMLasRichText({ htmlString });
	};
	return (
		<Button
			startIcon={<ContentCopyIcon />}
			color="primaryLight"
			variant="contained"
			size="small"
			disabled={disabled}
			onClick={handleCopyTimeEntryReport}
		>
			<Typography>Copy Time Entry Report</Typography>
		</Button>
	);
};

export default CopyTimeEntryReport;
