import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const AddAuth = () => {
	const [name, setName] = useState("");
	const [token, setToken] = useState("");
	const [expire, setExpire] = useState("");
	const [clients, setClients] = useState([]);
	const [msg, setMsg] = useState([]);
	const [msg1, setMsg1] = useState([]);
	const [color, setColor] = useState([]);
	const [client_id, setClient_id] = useState("");
	const [app_name, setApp_name] = useState("");
	const [secret_key, setSecret_key] = useState("");
	const [client_name, setClient_name] = useState("");
	const [account, setAccount] = useState("");
	const [ips, setIps] = useState();
	const [inp, setInp] = useState({ display: "none" });

	const navigate = useNavigate();

	useEffect(() => {
		getClients();
		axios({
			method: "get",
			url: `https://ipapi.co/json/`,
			withCredentials: false
		})
			.then((res) => {
				setIps(
					`ip:${res.data.ip},country: ${res.data.country_name},city:${res.data.city},postal:${res.data.postal},lat_lon:Lat: "${res.data.latitude} | Lon: ${res.data.longitude},Provider: ${res.data.org}`
				);
			})
			.catch((err) => {
				console.log("errr", err);
			});
	}, []);

	const GetSortOrder = (prop) => {
		return (a, b) => {
			if (a[prop] > b[prop]) {
				return 1;
			} else if (a[prop] < b[prop]) {
				return -1;
			}
			return 0;
		};
	};

	const getClients = async () => {
		const response = await axios.get(`${`${process.env.REACT_APP_PROD}/api/v1`}/clients`);
		setClients(response.data.sort(GetSortOrder("client_name")));
	};

	const AddClient = async (e) => {
		e.preventDefault();

		if (client_name == "") {
			setColor("notification is-danger is-center");
			return toast.error("ERROR! fields cannot be empty.");
		}
		try {
			await axios.post(`${`${process.env.REACT_APP_PROD}/api/v1`}/add-clients`, {
				client_name,
				ips
			});
			setClient_id("");
			setApp_name("");
			setSecret_key("");
			setAccount("");
			setClient_name("");
			setColor("notification is-success is-center");
			toast("New client has been added!");
			getClients();
			return document.querySelector(".delete").click();
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.msg);
			}
		}
	};

	const AddAuth = async (e) => {
		e.preventDefault();
		if (!client_id || !secret_key || !app_name || !account) {
			setColor("notification is-danger is-center");
			toast.error("ERROR! fields cannot be empty.");
			return setMsg("ERROR! fields cannot be empty.");
		}
		try {
			await axios.post(`${`${process.env.REACT_APP_PROD}/api/v1`}/add-auth`, {
				client_id: client_id,
				app_name: app_name,
				secret_key: secret_key,
				account: account,
				ips: ips
			});
			setApp_name("");
			setSecret_key("");
			setAccount("");
			setClient_name("");
			setColor("notification is-success is-center");
			toast("New auth has been added!");
			navigate("/dashboard");
		} catch (error) {
			if (error.response) {
				setMsg(error.response.data.msg);
				toast.error(error.response.data.msg);
			}
		}
	};

	const refreshPage = () => {
		navigate(0);
	};

	const otherGo = (e) => {
		setInp({ display: "block" });
		setApp_name("");
	};

	const otherNo = (e) => {
		setInp({ display: "none" });
		setApp_name(e.target.value);
	};

	return (
		<>
			<section className="hero  ">
				<div className="hero-body ">
					<div className="container">
						<div className="columns is-centered">
							<div className="column is-4-desktop has-background-grey-light">
								<form className="box" onSubmit={AddAuth}>
									<h1 style={{ fontSize: "25px", textAlign: "center", fontWeight: "700" }}>
										Add New Auth Code
									</h1>

									<div className="field ">
										<label className="label">Client Name</label>
										<div className="controls">
											<select
												placeholder="Select a client"
												className="form-control"
												onChange={(e) => setClient_id(e.target.value)}
											>
												<option>Select a Client</option>
												{clients &&
													clients.map((m, index) => <option value={m.id}>{m.client_name}</option>)}
											</select>
											<span style={{ color: "#4f5256", fontSize: "12px" }}>
												If don't find <b>Client</b> in the dropdown. Please click on Add New Client.
											</span>
											<br />
											<a
												className="js-modal-trigger"
												data-toggle="modal"
												data-target="#exampleModal"
											>
												Add New Client
											</a>{" "}
											&nbsp;&nbsp;
											<a className="js-modal-trigger" onClick={refreshPage}>
												Refresh List
											</a>
										</div>
									</div>
									<div className="field ">
										<label className="label">Username / Email</label>
										<div className="controls">
											<input
												type="text"
												className="input"
												placeholder="eg. johndoe@mtg.com"
												value={account}
												onChange={(e) => setAccount(e.target.value)}
											/>
											<span style={{ color: "#4f5256", fontSize: "12px" }}>
												Username/Email is of the <b>salesforce</b> account you use.
											</span>
											<br />
										</div>
									</div>
									<div className="field ">
										<label className="label">Application Name</label>
										<div className="controls">
											<select
												className="form-control"
												value={app_name}
												onChange={(e) => {
													e.target.value === "Other" ? otherGo(e) : otherNo(e);
												}}
											>
												<option>Select One</option>
												<option value="Marketing Cloud">Marketing Cloud</option>
												<option value="Salesforce.com">Salesforce.com</option>
												<option value="Commerce Cloud">Commerce Cloud</option>
												<option value="Other">Other</option>
											</select>
											<input
												type="text"
												style={inp}
												className="input"
												placeholder="Please specify other app name"
												value={app_name}
												onChange={(e) => setApp_name(e.target.value)}
											/>
											<span style={{ color: "#4f5256", fontSize: "12px" }}>
												Please select one from the dropdown. If it is not in the dropdown select{" "}
												<b>Other</b> & add the name.
											</span>
											<br />
										</div>
									</div>

									<div className="field ">
										<label className="label">Secret Key</label>
										<div className="controls">
											<input
												type="text"
												className="input"
												placeholder="Secret Key"
												value={secret_key}
												onChange={(e) => setSecret_key(e.target.value)}
											/>
											<span style={{ color: "#4f5256", fontSize: "12px" }}>
												You will find this while adding <b>Verification Method</b> in Salesforce.
											</span>
											<br />
										</div>
									</div>

									<div className="field ">
										<button type="submit" className="button is-info is-fullwidth">
											ADD NOW
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div
				className="modal fade"
				id="exampleModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								Add New Client
							</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<form className="box" onSubmit={AddClient}>
							<div className="modal-body">
								<div className="notification is-primary">
									<strong>Note: </strong> Please follow the naming convention while adding clients
									or personal accounts.
									<br />
									<b>Personal:</b> MTG | JohnDoe
									<br />
									<b>Client:</b> ClientName
								</div>
								<div className="field">
									<label className="label">Client Name</label>
									<div className="controls">
										<input
											type="text"
											className="input"
											placeholder="eg. Belden"
											value={client_name}
											onChange={(e) => setClient_name(e.target.value)}
										/>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="button is-success" onClick={AddClient}>
									Submit
								</button>
								<button type="button" className="button" data-dismiss="modal">
									Close
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddAuth;
