import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Stack, Typography } from "@mui/material";
import CustomBadge from "../../ComponentsLibrary/CustomBadge";
import { formatDate } from "../../utils";
import InformaticTitle from "../../ComponentsLibrary/InformaticTitle";
import SubmitCertificationAttempt from "./SubmitCertificationAttempt";

const MemberCertifications = (props) => {
	const { acquired_certifications, assigned_certifications, setProfile } = props;

	const [anchorEl, setAnchorEl] = useState(null);
	const [certificationName, setCertificationName] = useState(null);
	const [trackId, setTrackId] = useState(null);

	const handleBadgeClick = (event, certification_name, track_id) => {
		setAnchorEl(event.currentTarget);
		setCertificationName(certification_name);
		setTrackId(track_id);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Grid container direction={"column"} p={1}>
			<InformaticTitle
				label="Certifications"
				titleinfo="A list of certifications you've achieved and those you're currently pursuing. Certifications highlighted in Green indicate those you currently hold. Those in Red have passed their due date, while those in Yellow are in progress and due soon."
			/>
			<Stack pl={2} gap={2}>
				<InformaticTitle
					label="Current"
					titleinfo="A list of certifications you've achieved. Certifications highlighted in Green are the one you are currently holding and the ones highlighted in Red are Expired."
				/>
				<Stack
					direction={"row"}
					spacing={{ xs: 1, sm: 1 }}
					flexWrap={"wrap"}
					useFlexGap
					alignItems="flex-start"
				>
					{acquired_certifications.map(
						({ certification_id, certification_name, expiry_date, validity_status }) => (
							<CustomBadge
								key={certification_id}
								state={validity_status}
								text={certification_name}
								helperText={`Valid till ${formatDate(expiry_date)}`}
							/>
						)
					)}

					{acquired_certifications.length === 0 && (
						<Typography variant="Medium" gutterBottom>
							NA
						</Typography>
					)}
				</Stack>

				<InformaticTitle
					label="Assigned"
					titleinfo="A list of certifications you're currently pursuing. Certifications highlighted in Yellow are in progress and due soon while ones in Red have passed their due date."
				/>
				<Stack
					direction={"row"}
					spacing={{ xs: 1, sm: 1 }}
					flexWrap={"wrap"}
					useFlexGap
					alignItems={"flex-start"}
				>
					{assigned_certifications.map(
						({ certification_id, validity_status, certification_name, due_on, track_id }) => (
							<CustomBadge
								key={certification_id}
								state={validity_status}
								onClick={(e) => handleBadgeClick(e, certification_name, track_id)}
								text={certification_name}
								helperText={`Due on ${formatDate(due_on)}`}
							/>
						)
					)}

					{assigned_certifications.length === 0 && (
						<Typography variant="Medium" gutterBottom>
							NA
						</Typography>
					)}
				</Stack>
			</Stack>
			<SubmitCertificationAttempt
				anchorEl={anchorEl}
				onClose={handleClose}
				certification_name={certificationName}
				track_id={trackId}
				setProfile={setProfile}
			/>
		</Grid>
	);
};

MemberCertifications.propTypes = {
	certifications: PropTypes.array.isRequired
};

export default MemberCertifications;
