import {
	Button as MUIButton,
	Typography as MUITypography,
	Stack as MUIStack,
	Paper as MUIPaper,
	Grid as MUIGrid,
	Accordion as MUIAccordian,
	AccordionSummary as MUIAccordianSummary,
	AccordionDetails as MUIAccordionDetails,
	Card as MUICard,
	CardActions as MUICardActions,
	CardContent as MUICardContent,
	IconButton as MUIIconButton,
	Badge as MUIBadge,
	Tooltip as MUITooltip,
	Popover as MUIPopover,
	TextField as MUITextField,
	Autocomplete as MUIAutocomplete,
	Table as MUITable,
	TableBody as MUITableBody,
	TableCell as MUITableCell,
	TableContainer as MUITableContainer,
	TableHead as MUITableHead,
	TableRow as MUITableRow
} from "@mui/material";

import { Modal as BootstrapModal } from "react-bootstrap";

// import QuillEditor from './QuillEditor.js';
import NewQuillEditor from "./NewQuill.js";

const Grid = MUIGrid;
const Paper = MUIPaper;
const Stack = MUIStack;
const Typography = MUITypography;
const Button = MUIButton;
const Accordion = MUIAccordian;
const AccordionSummary = MUIAccordianSummary;
const AccordionDetails = MUIAccordionDetails;
const Card = MUICard;
const CardContent = MUICardContent;
const CardActions = MUICardActions;
const IconButton = MUIIconButton;
const Tooltip = MUITooltip;
const Popover = MUIPopover;
const TextField = MUITextField;
const Autocomplete = MUIAutocomplete;

const Table = MUITable;
const TableBody = MUITableBody;
const TableCell = MUITableCell;
const TableContainer = MUITableContainer;
const TableHead = MUITableHead;
const TableRow = MUITableRow;

const Badge = MUIBadge;

const Modal = BootstrapModal;

export {
	Grid,
	Paper,
	Stack,
	Typography,
	Button,
	IconButton,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Card,
	CardContent,
	CardActions,
	Modal,
	NewQuillEditor,
	Badge,
	Tooltip,
	Popover,
	TextField,
	Autocomplete,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
};
