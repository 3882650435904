import { styled } from "@mui/material";
import { IconButton as IconButtonComp, Stack } from "../index.js";
import { forwardRef } from "react";
import { preventCustomPropForwarding } from "../../Utils/MUI.js";

const StyledIconButton = styled(IconButtonComp)(() => ({}));

const StyledIconButtonWrapper = styled(
	Stack,
	preventCustomPropForwarding(["wrapperSx"])
)(({ theme, color, wrapperSx }) => ({
	backgroundColor: color && theme.palette[color].light,
	padding: "4px",
	borderRadius: "50%",
	...wrapperSx
}));

const IconButton = forwardRef(({ color, wrapperSx, ...rest }, ref) => {
	return (
		<StyledIconButtonWrapper color={color} wrapperSx={wrapperSx}>
			<StyledIconButton ref={ref} color={color} {...rest} />
		</StyledIconButtonWrapper>
	);
});

export default IconButton;
