import { styled } from "@mui/material";
import { TableHead as TableHeadComp } from "../index.js";

const StyledTableHead = styled(TableHeadComp)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.light,
	color: "#000"
}));

const TableHead = (props) => {
	return <StyledTableHead {...props} />;
};

export default TableHead;
