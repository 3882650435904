import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Stack, TextField, Typography } from "../../../UIComponents";
import { Box, MenuItem, Autocomplete } from "@mui/material";
import { fetchCertificates, selectAllCertificates } from "../../../redux/reducers/certificates";
import {
	assignCertification,
	selectAllCertifications
} from "../../../redux/reducers/certificationDashboard";
import { useAuth } from "../../../CustomHooks/AuthenticationHook";
import { toast } from "react-toastify";

const AssignCertificationModal = ({ isOpen, onClose, userId, disabled_cert_ids }) => {
	const dispatch = useDispatch();
	const { user } = useAuth();

	const members = useSelector(selectAllCertifications);
	const certificates = useSelector(selectAllCertificates);

	const [deadline, setDeadline] = useState(null);
	const [certificationId, setCertificationId] = useState("");
	const [notes, setNotes] = useState("");

	useEffect(() => {
		if (certificates.length === 0) {
			dispatch(fetchCertificates());
		}
	}, [certificates, certificates.length, dispatch]);

	const handleModalClose = () => {
		onClose();

		setNotes(null);
		setDeadline(null);
		setCertificationId(null);
	};

	const handleSubmit = () => {
		if (deadline && userId && certificationId) {
			dispatch(
				assignCertification({
					deadline: deadline.format("YYYY-MM-DD"),
					member_id: userId,
					certification_id: certificationId,
					notes,
					assigned_by_id: user.userId
				})
			);
			onClose();

			setNotes(null);
			setDeadline(null);
			setCertificationId(null);
		} else {
			toast.error("Please fill out all required fields");
		}
	};

	return (
		<Modal show={isOpen} onHide={handleModalClose} centered>
			<Box
				sx={{
					p: 4
				}}
			>
				<Typography fontSize="18px" sx={{ mb: 2 }}>
					Assign Certification
				</Typography>

				<Stack gap={2}>
					{/* Deadline Date Picker */}
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="Deadline"
							value={deadline}
							onChange={(newValue) => setDeadline(newValue)}
							disablePast
							renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
						/>
					</LocalizationProvider>

					{/* Member Select */}
					<TextField select label="Member" value={userId} fullWidth disabled>
						{members.map((member) => (
							<MenuItem key={member.id} value={member.id}>
								{member.employee_name}
							</MenuItem>
						))}
					</TextField>

					{/* Certification Select with Autocomplete */}
					<Autocomplete
						options={certificates}
						getOptionDisabled={(option) =>
							disabled_cert_ids.some((certification_id) => certification_id === option.id)
						}
						getOptionLabel={(option) => option.name || ""}
						value={certificates.find((cert) => cert.id === certificationId) || null}
						onChange={(e, newValue) => setCertificationId(newValue ? newValue.id : "")}
						renderInput={(params) => (
							<TextField {...params} label="Certification" variant="outlined" fullWidth />
						)}
					/>

					{/* Notes Text Field */}
					<TextField
						label="Notes"
						value={notes}
						onChange={(e) => setNotes(e.target.value)}
						multiline
						rows={4}
						fullWidth
					/>
				</Stack>

				{/* Action Buttons */}
				<Box display="flex" justifyContent="flex-end" gap={1} mt={2}>
					<Button variant="outlined" color="secondary" onClick={handleModalClose}>
						Close
					</Button>
					<Button variant="contained" color="primary" onClick={handleSubmit}>
						Assign
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default AssignCertificationModal;
