import { styled } from "@mui/material";
import { Grid, Paper, Stack, Typography } from "../../../UIComponents";

import CollapsibleTable from "./CollapsibleTable";
import FilterPopover from "./Filters.js";
import { useDispatch } from "react-redux";
import { fetchCalculatedProjectStatus } from "../../../redux/reducers/projectHealthDashboard.js";

const StyledPaper = styled(Paper)(({ theme }) => ({
	borderRadius: "6px",
	padding: "16px"
}));

const Dashboard = () => {
	const dispatch = useDispatch();
	const handleFilterChange = ({ filters }) => {
		const { managers, leads, isArchieved, start_date, end_date } = filters;
		dispatch(
			fetchCalculatedProjectStatus({
				startDate: start_date,
				endDate: end_date,
				managers,
				leads,
				isArchieved
			})
		);
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<StyledPaper direction={"column"} spacing={2} mt={2}>
					<Grid container gap={2} justifyContent="space-between">
						<Grid item xs={3}>
							<Typography fontSize="26px">Project Health Dashboard</Typography>
						</Grid>
						<Grid item xs={8}>
							<Stack justifyContent="flex-end" direction="row" gap={2}>
								<FilterPopover handleFilterChange={handleFilterChange} />
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<CollapsibleTable />
						</Grid>
					</Grid>
				</StyledPaper>
			</Grid>
		</Grid>
	);
};

export default Dashboard;
