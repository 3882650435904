import React, { useEffect, useMemo, useRef, useState } from "react";
import "./index.css";
import { Grid, Typography, styled } from "@mui/material";
import SearchSelect from "../../ComponentsLibrary/SearchSelect";
import { Paper, Popover, Stack } from "../../UIComponents/index.js";
import dayjs from "dayjs";
import Button from "../../UIComponents/DesignSystem/Button.js";
import { getTimeEntryDashboardDataAPI } from "../../apis/Dashboards/index.js";
import TimeRange from "./TimeRange.js";
import CollapsibleTable from "./TimeEntryTableNew.js";
import CopyTimeEntryReport from "./CopyTimeEntryReport.js";

const StyledPaper = styled(Paper)(({ theme }) => ({
	borderRadius: "6px",
	padding: "16px"
}));

const commonSelectStyle = {
	minWidth: "150px"
};
const commontInputStyle = {
	".MuiFilledInput-root": {},
	".MuiFilledInput-root:before": {
		borderBottom: "none"
	}
};

const groupDataByResources = ({ data, aggregatedDataByResource }) => {
	const dataByResources = data.reduce((acc, curr) => {
		let { member_id } = curr;
		if (!member_id) return acc;
		if (!acc[member_id]) {
			acc[member_id] = { member_id, timeEntries: [] };
		}
		if (!aggregatedDataByResource[member_id]) {
			aggregatedDataByResource[member_id] = {
				member_id,
				missing_timesheets: 0
			};
		}
		if (curr.calculated_status === "Missing") {
			aggregatedDataByResource[member_id].missing_timesheets += 1;
		}
		acc[member_id].timeEntries.push(curr);
		return acc;
	}, {});
	return dataByResources;
};

const groupDataByManagers = (data) => {
	const aggregatedDataByManager = {};
	const aggregatedDataByResource = {};
	const dataByManagers = data.reduce((acc, curr) => {
		let { manager: managerName, manager_id } = curr;
		if (!managerName) return acc;
		if (!acc[managerName]) {
			acc[managerName] = {
				managerName,
				manager_id,
				manager_row_count: 0,
				resourceData: []
			};
		}
		if (!aggregatedDataByManager[managerName]) {
			aggregatedDataByManager[managerName] = {
				manager_id,
				missing_timesheets: 0
			};
		}
		if (curr.calculated_status === "Missing") {
			aggregatedDataByManager[managerName].missing_timesheets += 1;
		}

		acc[managerName].resourceData.push(curr);
		acc[managerName].manager_row_count += 1;
		return acc;
	}, {});

	const timeEntryData = Object.fromEntries(
		Object.entries(dataByManagers).map(
			([manager_name, { manager_id, manager_row_count, resourceData }]) => {
				const dataByResources = groupDataByResources({
					data: resourceData,
					aggregatedDataByResource
				});
				return [manager_name, { manager_id, manager_name, manager_row_count, dataByResources }];
			}
		)
	);
	return { timeEntryData, aggregatedDataByManager, aggregatedDataByResource };
};

const getManagers = (data) => {
	return [
		{ id: "all", title: "All" },
		...Object.entries(data).map(([manager_name, { manager_id }]) => ({
			id: manager_id,
			title: manager_name
		}))
	];
};

export const formatDate = (date) => {
	return date.format("YYYY-MM-DD");
};

const getTimeRange = (today) => {
	const start_of_month = dayjs().startOf("M");
	const mid_month = start_of_month.add(14, "days");
	const timeRange = {
		C1: {
			id: 0,
			title: "Day 1 - Day 15",
			startDate: formatDate(start_of_month),
			endDate: formatDate(mid_month)
		},
		C2: {
			id: 1,
			title: "Day 16 - Billing Day",
			startDate: formatDate(mid_month.add(1, "day")),
			endDate: formatDate(dayjs().endOf("M"))
		},
		Custom: {
			id: 5,
			title: "Custom",
			startDate: formatDate(today.subtract(7, "days")),
			endDate: formatDate(today)
		}
	};
	return timeRange;
};

export const IGNORE_IDS_LIST = [23, 30, 29, 46];

const TimeEntryDashboard = () => {
	const dateRanges = useMemo(() => getTimeRange(dayjs(new Date())), []);
	const [aggregatedDataByManager, setAggregatedDataByManager] = useState({});
	const [aggregatedDataByResource, setAggregatedDataByResource] = useState({});
	const [dateRange, setDateRange] = useState({});
	const [timeEntryDashboardData, setTimeEntryDashboardData] = useState([]);
	const [timeEntruAPIData, setTimeEntryAPIData] = useState();
	const [loadingState, setLoading] = useState("loading");
	const managers = useRef([]);
	const resourcesByManagers = useRef({});

	const [timeRangeAnchorEl, setTimeRangeAnchorEl] = useState();
	const [managerSelected, selectManager] = useState({
		id: "all",
		title: "All"
	});

	useEffect(() => {
		setDateRange(dayjs().format("D") > 15 ? dateRanges["C2"] : dateRanges["C1"]);
	}, [dateRanges]);

	useEffect(() => {
		const getTimeEntryData = async ({ dateRange }) => {
			const { startDate, endDate } = dateRange;
			try {
				const resource_ids_by_manager = (
					resourcesByManagers.current[managerSelected.title] || []
				).toString();
				const time_entry_data = await getTimeEntryDashboardDataAPI({
					startDate,
					endDate,
					resource_ids: resource_ids_by_manager || "all"
				});
				const { timeEntryData, aggregatedDataByManager, aggregatedDataByResource } =
					groupDataByManagers(time_entry_data);
				const managerList = getManagers(timeEntryData);
				if (managers.current.length === 0) {
					managers.current = managerList.filter(({ id }) => (id === null ? false : true));
				}
				if (Object.keys(resourcesByManagers.current).length === 0) {
					resourcesByManagers.current = Object.fromEntries(
						Object.entries(timeEntryData).map(([manager_name, { dataByResources = {} }]) => {
							console.log({ manager_name, dataByResources });
							return [manager_name, Object.keys(dataByResources)];
						})
					);
				}
				setTimeEntryAPIData(time_entry_data);
				setAggregatedDataByManager(aggregatedDataByManager);
				setAggregatedDataByResource(aggregatedDataByResource);
				setTimeEntryDashboardData(timeEntryData);
				setLoading("success");
			} catch (err) {
				setLoading("failed");
				console.log(err);
			}
		};
		if (dateRange.title) {
			setLoading("loading");
			getTimeEntryData({ dateRange });
		}
	}, [dateRange, managerSelected]);

	const handleTimeRangeBtnClick = (e) => {
		setTimeRangeAnchorEl((prev) => (prev ? null : e.target));
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<StyledPaper direction={"column"} spacing={2} mt={2}>
					<Grid container gap={2} justifyContent="space-between">
						<Grid item xs={3}>
							<Typography fontSize="26px">Time Sheet Summary</Typography>
						</Grid>
						<Grid item xs={8}>
							<Stack justifyContent="flex-end" direction="row" gap={2}>
								<CopyTimeEntryReport
									timeEntryData={timeEntruAPIData}
									disabled={loadingState !== "success"}
								/>
								<Button
									color="secondary"
									variant="contained"
									size="small"
									disabled={loadingState !== "success"}
									onClick={handleTimeRangeBtnClick}
								>
									<Typography>{dateRange.title}</Typography>
								</Button>
								<SearchSelect
									id="Forcast-Manager-select"
									value={managerSelected}
									options={managers.current}
									onChange={(manager) => selectManager(manager)}
									optionDisplayVar="title"
									label="Manager"
									inputColor="#000"
									sx={commonSelectStyle}
									disabled={loadingState !== "success"}
									inputSx={commontInputStyle}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<CollapsibleTable
								dataByManager={timeEntryDashboardData}
								loadingState={loadingState}
								aggregatedDataByManager={aggregatedDataByManager}
								aggregatedDataByResource={aggregatedDataByResource}
								dateRange={dateRange}
							/>
						</Grid>
					</Grid>
				</StyledPaper>
				<Popover
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					slotProps={{
						paper: {
							sx: {
								borderRadius: "8px"
							}
						}
					}}
					open={Boolean(timeRangeAnchorEl)}
					anchorEl={timeRangeAnchorEl}
					onClose={() => setTimeRangeAnchorEl(null)}
				>
					<TimeRange
						onClose={() => setTimeRangeAnchorEl(null)}
						dateRange={dateRange}
						setDateRange={setDateRange}
						options={Object.values(dateRanges)}
						handleClose={() => setTimeRangeAnchorEl(null)}
					/>
				</Popover>
			</Grid>
		</Grid>
	);
};

export default TimeEntryDashboard;
