import { Drawer, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Button, TextField } from "../../../UIComponents/index.js";

import { useDrawer } from "./useDrawer.js";
import { memo, useCallback } from "react";
// import dayjs from "dayjs";

const ReportDrawer = () => {
	const { drawerOpen, drawerData, setDrawerData, closeDrawer, submitProjectHealthReport } =
		useDrawer();

	const handleSubmitReport = useCallback(() => {
		submitProjectHealthReport();
	}, [submitProjectHealthReport]);
	return (
		<Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
			{drawerData && (
				<div style={{ width: 400, padding: 20 }}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<h3>Submit New Report</h3>
						<Button onClick={closeDrawer} color="secondary" variant="outlined">
							Close
						</Button>
					</div>

					<FormControl fullWidth margin="normal">
						<InputLabel htmlFor="client-engagement">Client Engagement</InputLabel>
						<Select
							labelId="client-engagement-label"
							id="client-engagement"
							value={drawerData.client_engagement}
							label="Client Engagement"
							onChange={(e) => {
								setDrawerData((prev) => ({
									...prev,
									client_engagement: e.target.value
								}));
							}}
						>
							<MenuItem value={1} title="Minimal Engagement">
								1 (Minimal Engagement)
							</MenuItem>
							<MenuItem value={2} title="Low Engagement">
								2 (Low Engagement)
							</MenuItem>
							<MenuItem value={3} title="Moderate Engagement">
								3 (Moderate Engagement)
							</MenuItem>
							<MenuItem value={4} title="High Engagement">
								4 (High Engagement)
							</MenuItem>
							<MenuItem value={5} title="Full Engagement">
								5 (Full Engagement)
							</MenuItem>
						</Select>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<InputLabel htmlFor="client-timeliness">Client Timeliness</InputLabel>
						<Select
							labelId="client-timeliness-label"
							id="client-timeliness"
							value={drawerData.client_timeliness}
							label="Client Timeliness"
							onChange={(e) => {
								setDrawerData((prev) => ({
									...prev,
									client_timeliness: e.target.value
								}));
							}}
						>
							<MenuItem value={1} title="Very Poor">
								1 (Very Poor)
							</MenuItem>
							<MenuItem value={2} title="Poor">
								2 (Poor)
							</MenuItem>
							<MenuItem value={3} title="Fair">
								3 (Fair)
							</MenuItem>
							<MenuItem value={4} title="Good">
								4 (Good)
							</MenuItem>
							<MenuItem value={5} title="Excellent">
								5 (Excellent)
							</MenuItem>
						</Select>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<InputLabel htmlFor="budget">Budget</InputLabel>
						<Select
							labelId="budget-label"
							id="budget"
							value={drawerData.budget}
							label="Budget"
							onChange={(e) => {
								setDrawerData((prev) => ({
									...prev,
									budget: e.target.value
								}));
							}}
						>
							<MenuItem value={1} title="Significantly Over Budget">
								1 (Significantly Over Budget)
							</MenuItem>
							<MenuItem value={2} title="Over Budget">
								2 (Over Budget)
							</MenuItem>
							<MenuItem value={3} title="On Track but Risk of Overrun">
								3 (On Track but Risk of Overrun)
							</MenuItem>
							<MenuItem value={4} title="On Budget">
								4 (On Budget)
							</MenuItem>
							<MenuItem value={5} title="Under Budget">
								5 (Under Budget)
							</MenuItem>
						</Select>
					</FormControl>

					{/* <TextField
            fullWidth
            label="Budget Remaining"
            value={`$${parseFloat(
              (drawerData.budget_remaining || "").replace(/[$,]/g, "")
            ).toLocaleString("en-US")}`}
            margin="normal"
            disabled={true}
            InputProps={{
              readOnly: true,
            }}
          /> */}

					<FormControl fullWidth margin="normal">
						<InputLabel htmlFor="schedule">Schedule</InputLabel>
						<Select
							labelId="schedule-label"
							id="schedule"
							value={drawerData.schedule}
							label="Schedule"
							onChange={(e) => {
								setDrawerData((prev) => ({
									...prev,
									schedule: e.target.value
								}));
							}}
						>
							<MenuItem value={1} title="Significantly Behind Schedule">
								1 (Significantly Behind Schedule)
							</MenuItem>
							<MenuItem value={2} title="Behind Schedule">
								2 (Behind Schedule)
							</MenuItem>
							<MenuItem value={3} title="At Risk of Delay">
								3 (At Risk of Delay)
							</MenuItem>
							<MenuItem value={4} title="On Schedule">
								4 (On Schedule)
							</MenuItem>
							<MenuItem value={5} title="Ahead of Schedule">
								5 (Ahead of Schedule)
							</MenuItem>
						</Select>
					</FormControl>

					{/* <TextField
            fullWidth
            label="On Schedule"
            value={dayjs(drawerData.project_close_date).format("MMM D, YYYY")}
            margin="normal"
            InputProps={{
              readOnly: true,
              style: {
                color:
                  new Date(drawerData.project_close_date) > new Date()
                    ? "green"
                    : "red",
              },
            }}
          /> */}

					<TextField
						fullWidth
						multiline
						rows={4}
						label="Health Update"
						placeholder="Start taking notes..."
						value={drawerData.overall}
						onChange={(e) =>
							setDrawerData((prev) => ({
								...prev,
								overall: e.target.value
							}))
						}
						margin="normal"
					/>

					<Button
						variant="contained"
						color="primary"
						style={{ marginTop: 20 }}
						onClick={handleSubmitReport}
					>
						Submit Report
					</Button>
				</div>
			)}
		</Drawer>
	);
};

export default memo(ReportDrawer);
