import { styled } from "@mui/material";
import { TableRow as TableRowComp } from "../index.js";

const StyledTableRow = styled(TableRowComp)(({ theme }) => ({}));

const TableRow = (props) => {
	return <StyledTableRow {...props} />;
};

export default TableRow;
