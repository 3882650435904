import { Accordion, AccordionDetails, AccordionSummary } from "../../UIComponents";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeliverablesItemNotes from "./DecisionTracker/DeliverableNotes";
import DeliverableItemHeader from "./DecisionTracker/DeliverableItemHeader";
import { styled } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { preventCustomPropForwarding } from "../../Utils/MUI";

const StyledAccordionSummary = styled(
	AccordionSummary,
	preventCustomPropForwarding(["isDiscussed"])
)(({ theme, isDiscussed }) => ({
	borderBottom: `1px solid ${theme.palette.border.main}`,
	borderRadius: "4px",
	backgroundColor: isDiscussed && theme.palette.secondary.light
}));

const getCurrentMeetingDeliverableNote = ({ current_meeting_id, notes_map }) => {
	const [currentMeetingDeliverableNote] = Object.values(notes_map).filter(
		(note) => Number(current_meeting_id) === note.meeting_id
	);
	return currentMeetingDeliverableNote;
};

const getNoteContent = ({ note = {} }) => note?.note_content || "";

const DeliverableItem = ({ deliverable_id, meeting_id, workspace_id, isDiscussed }) => {
	const notes_map =
		useSelector((state) => state.decisions.deliverables_notes[workspace_id]?.[deliverable_id]) ||
		{};

	const [selectedNote, setSelectedNote] = useState(() =>
		getCurrentMeetingDeliverableNote({ current_meeting_id: meeting_id, notes_map })
	);
	const [selectedNoteValue, setSelectedNoteValue] = useState(() =>
		getNoteContent({
			note: getCurrentMeetingDeliverableNote({ current_meeting_id: meeting_id, notes_map })
		})
	);

	return (
		<Accordion
			elevation={0}
			variant="outlined"
			disableGutters
			sx={{
				borderRadius: "8px",
				"&:before": {
					height: 0
				}
			}}
			TransitionProps={{ unmountOnExit: true }}
		>
			<StyledAccordionSummary isDiscussed={isDiscussed} expandIcon={<ExpandMoreIcon />}>
				<DeliverableItemHeader
					meeting_id={meeting_id}
					workspace_id={workspace_id}
					deliverable_id={deliverable_id}
					selectedNote={selectedNote}
					selectedNoteValue={selectedNoteValue}
					setSelectedNote={setSelectedNote}
				/>
			</StyledAccordionSummary>
			<AccordionDetails>
				<DeliverablesItemNotes
					meeting_id={meeting_id}
					workspace_id={workspace_id}
					deliverable_id={deliverable_id}
					isDiscussed={isDiscussed}
					selectedNote={selectedNote}
					selectedNoteValue={selectedNoteValue}
					setSelectedNote={setSelectedNote}
					setSelectedNoteValue={setSelectedNoteValue}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

export default DeliverableItem;
