import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import TableBody from "../../../UIComponents/DesignSystem/TableBody.js";
import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import Table from "../../../UIComponents/DesignSystem/Table.js";
import TableHeadComponent from "./TableHeader.js";
import { Stack, Typography } from "../../../UIComponents/index.js";
import { styled } from "@mui/material";
import IconButton from "../../../UIComponents/DesignSystem/IconButtton.js";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext } from "react";
import { ModalContext } from "./Dashboard.js";

const StyledStack = styled(Stack)(({ theme }) => ({
	backgroundColor: theme.palette.secondaryLight.main
}));

const CertificationDetails = ({
	userId,
	userName,
	title,
	headerColMap,
	certification_data = [],
	all_cert_ids = [],
	showAssignIcon = false
}) => {
	const { handleAssignCertModalOpen, handleUpdateCertModalOpen, handleDeleteCertModalOpen } =
		useContext(ModalContext);

	const formatStatus = (status) => {
		if (
			(status === "Current") |
			(status === "Expired") |
			(status === "Maintenance Due") |
			(status === "Overdue") |
			(status === "Ongoing")
		)
			return status;
		return "Unlinked";
	};

	const getStatusColor = (status) => {
		switch (status) {
			case "Current":
				return "#28C76F";
			case "Expired":
				return "#EA5455";
			case "Overdue":
				return "#EA5455";
			case "Maintenance Due":
				return "#FF9F43";
			case "Ongoing":
				return "#FF9F43";
			default:
				return "orange";
		}
	};

	const renderCellContent = (colKey, certification) => {
		if (colKey === "status") {
			return (
				<Stack direction="row" justifyContent="center" alignItems="center">
					<Typography style={{ color: "white" }}>{formatStatus(certification[colKey])}</Typography>
				</Stack>
			);
		}

		if (colKey === "actions") {
			return certification["deleted_date"] ? (
				"-"
			) : (
				<ActionsCell
					certification={certification}
					userId={userId}
					userName={userName}
					handleUpdateCertModalOpen={handleUpdateCertModalOpen}
					handleDeleteCertModalOpen={handleDeleteCertModalOpen}
				/>
			);
		}

		if (colKey === "modified_by_name") {
			return (
				<ModifiedByCell
					modifiedByName={certification[colKey]}
					modifiedDate={certification["modified_date"]}
				/>
			);
		}

		if (colKey === "certification_name") {
			return <CertificationNameCell certificationName={certification[colKey]} />;
		}

		return (
			<Stack direction="row" justifyContent="center" alignItems="center">
				<Typography variant="body3" fontSize="14px" align="center">
					{certification[colKey] ?? "-"}
				</Typography>
			</Stack>
		);
	};

	return (
		<StyledStack gap={1} paddingTop={1} paddingBottom={2} paddingX={2}>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Typography fontSize="16px" fontWeight={600}>
					{title}
				</Typography>
				{showAssignIcon && (
					<IconButton
						color="primary"
						variant="outlined"
						size="small"
						onClick={() =>
							handleAssignCertModalOpen({
								memberId: userId,
								disabled_cert_ids: all_cert_ids
							})
						}
					>
						<AddIcon />
					</IconButton>
				)}
			</Stack>
			<Table aria-label="resources">
				<TableHeadComponent columns={Object.values(headerColMap)} indent={0} />
				<TableBody>
					{certification_data?.length === 0 && (
						<TableRow>
							<TableCell colSpan={13} style={{ textAlign: "center", height: 100 }}>
								No Records found!
							</TableCell>
						</TableRow>
					)}
					{certification_data?.map((certification) => (
						<TableRow key={certification.certification_id}>
							{Object.keys(headerColMap).map((colKey) => (
								<TableCell
									key={colKey}
									style={{
										backgroundColor:
											colKey === "status" ? getStatusColor(certification[colKey]) : "inherit",
										color: colKey === "status" ? "white" : "inherit"
									}}
								>
									{renderCellContent(colKey, certification)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</StyledStack>
	);
};

const ActionsCell = ({
	certification,
	userId,
	userName,
	handleUpdateCertModalOpen,
	handleDeleteCertModalOpen
}) => (
	<Stack direction="row" justifyContent="center" gap="10px" alignItems="center">
		<IconButton
			color="secondary"
			variant="outlined"
			size="small"
			onClick={() =>
				handleUpdateCertModalOpen({
					memberId: userId,
					certification_id: certification.certification_id,
					deadline: certification.due_on,
					notes: certification.notes || "",
					track_id: certification.track_id
				})
			}
		>
			<EditIcon color="secondary" />
		</IconButton>
		<IconButton
			color="secondary"
			variant="outlined"
			size="small"
			onClick={() =>
				handleDeleteCertModalOpen({
					memberId: userId,
					memberName: userName,
					certification_id: certification.certification_id,
					certification_name: certification.certification_name,
					deadline: certification.due_on,
					track_id: certification.track_id
				})
			}
		>
			<DeleteIcon color="error" />
		</IconButton>
	</Stack>
);

const ModifiedByCell = ({ modifiedByName, modifiedDate }) => (
	<Stack gap="1px" direction="column" justifyContent="center" alignItems="center">
		{modifiedByName && modifiedDate ? (
			<>
				<Typography>{modifiedByName}</Typography>
				<Typography color="secondary" fontSize={13}>
					{modifiedDate}
				</Typography>
			</>
		) : (
			"-"
		)}
	</Stack>
);

const CertificationNameCell = ({ certificationName }) => (
	<Stack direction="row" justifyContent="start" alignItems="start">
		<Typography variant="body3" fontSize="14px" align="left">
			{certificationName}
		</Typography>
	</Stack>
);

export default CertificationDetails;
