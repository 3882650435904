import { combineReducers } from "@reduxjs/toolkit";
import usersSlice from "./reducers/users";
import apiStatusSlice from "./reducers/apiStatus";
import workspacesSlice from "./reducers/workspaces";
import decisionsSlice from "./reducers/decisions";
import projectHealthDashboardSlice from "./reducers/projectHealthDashboard";
import membersReducer from "./reducers/members";
import certificationDashboardSlice from "./reducers/certificationDashboard";
import certificateReducer from "./reducers/certificates";
import platformReducer from "./reducers/platforms";

const createRootReducer = () =>
	combineReducers({
		apiStatus: apiStatusSlice,
		users: usersSlice,
		workspaces: workspacesSlice,
		decisions: decisionsSlice,
		projectHealthDashboard: projectHealthDashboardSlice,
		userCertifications: certificationDashboardSlice,
		members: membersReducer,
		certificates: certificateReducer,
		platforms: platformReducer
	});

export default createRootReducer;
