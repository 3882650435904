import React, { useEffect, useState } from "react";
import { Popover, MenuItem } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Stack, TextField } from "../../UIComponents";
import Button from "../../UIComponents/DesignSystem/Button";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InformaticTitle from "../../ComponentsLibrary/InformaticTitle";
import { useDispatch } from "react-redux";
import { submitCertificationAttempt } from "../../redux/reducers/certificationDashboard";
import { useAuth } from "../../CustomHooks/AuthenticationHook";

const SubmitCertificationAttempt = ({
	anchorEl,
	onClose,
	certification_name,
	track_id,
	setProfile
}) => {
	const dispatch = useDispatch();
	const { user } = useAuth();

	const [formValues, setFormValues] = useState({
		date: null,
		status: ""
	});

	const onSubmit = () => {
		dispatch(
			submitCertificationAttempt({
				track_id,
				attempt_date: formValues.date,
				status: formValues.status,
				member_id: user.userId
			})
		)
			.unwrap()
			.then(({ acquired_certifications }) => {
				setProfile((prev) => {
					return {
						...prev,
						acquired_certifications,
						assigned_certifications:
							formValues.status === "Passed"
								? (prev.assigned_certifications || []).filter((cert) => cert.track_id !== track_id)
								: prev.assigned_certifications || []
					};
				});
			});
	};

	useEffect(() => {
		return () =>
			setFormValues({
				date: null,
				status: ""
			});
	}, [anchorEl]);

	const handleInputChange = (field, value) => {
		setFormValues((prevValues) => ({
			...prevValues,
			[field]: value
		}));
	};

	const handleSubmit = () => {
		if (onSubmit) {
			onSubmit(formValues);
		}
		onClose();
	};

	const open = Boolean(anchorEl);

	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right"
			}}
		>
			<Stack spacing={2} sx={{ p: 2, minWidth: 300 }}>
				<InformaticTitle
					label={`Submit attempt for ${certification_name}`}
					titleinfo="Mark your certification attemps here."
				/>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						label="Select Date"
						value={formValues.date}
						disableFuture
						onChange={(newValue) => handleInputChange("date", newValue)}
						renderInput={(params) => <TextField {...params} fullWidth />}
					/>
				</LocalizationProvider>
				<TextField
					select
					label="Status"
					value={formValues.status}
					onChange={(event) => handleInputChange("status", event.target.value)}
					fullWidth
				>
					<MenuItem value="Attempted">Attempted</MenuItem>
					<MenuItem value="Passed">Passed</MenuItem>
				</TextField>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSubmit}
					disabled={!formValues.date || !formValues.status}
				>
					Submit
				</Button>
			</Stack>
		</Popover>
	);
};

export default SubmitCertificationAttempt;
