import React, { useEffect, useState } from "react";
import { Collapse, IconButton, Paper, CircularProgress } from "@mui/material";
import {
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon
} from "@mui/icons-material";
import { Stack, Typography } from "../../UIComponents";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/reducers/users";
import dayjs from "dayjs";
import TableRow from "../../UIComponents/DesignSystem/TableRow";
import TableCell from "../../UIComponents/DesignSystem/TableCell";
import TableBody from "../../UIComponents/DesignSystem/TableBody";
import TableHead from "../../UIComponents/DesignSystem/TableHead";
import Table from "../../UIComponents/DesignSystem/Table";
import TableContainer from "../../UIComponents/DesignSystem/TableContainer";

const formatFloat = (floatNumber) => parseFloat(floatNumber).toFixed(2);

const CollapsibleTable = ({
	dataByManager: data,
	loadingState,
	aggregatedDataByManager,
	aggregatedDataByResource,
	dateRange
}) => {
	const dispatch = useDispatch();
	const [openManager, setOpenManager] = useState({});
	const [openResource, setOpenResource] = useState({});
	const { startDate, endDate } = dateRange;

	const allUsersMap = useSelector((state) => state.users.all);
	useEffect(() => {
		if (!Object.keys(allUsersMap).length) {
			dispatch(getAllUsers());
		}
	}, [allUsersMap, dispatch]);

	const handleManagerClick = (managerId) => {
		setOpenManager((prev) => ({ ...prev, [managerId]: !prev[managerId] }));
	};

	const handleResourceClick = (resourceId) => {
		setOpenResource((prev) => ({ ...prev, [resourceId]: !prev[resourceId] }));
	};

	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell>
							<Typography>Manager</Typography>
						</TableCell>
						<TableCell>
							<Typography>Date Range</Typography>
						</TableCell>
						<TableCell colSpan={5}>
							<Typography>Missing Timesheets</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{loadingState === "loading" && (
						<TableRow>
							<TableCell colSpan={13} style={{ textAlign: "center", height: 300 }}>
								<CircularProgress color="primary" />
							</TableCell>
						</TableRow>
					)}
					{loadingState === "failed" && (
						<TableRow>
							<TableCell colSpan={13} style={{ textAlign: "center", height: 300 }}>
								Error in loading the data! Please try again later.
							</TableCell>
						</TableRow>
					)}
					{loadingState === "success" && Object.keys(data)[0].length === 0 && (
						<TableRow>
							<TableCell colSpan={13} style={{ textAlign: "center", height: 300 }}>
								No Records found!
							</TableCell>
						</TableRow>
					)}
					{loadingState === "success" &&
						Object.keys(data).map((managerId) => {
							const manager = data[managerId];
							return (
								<>
									<TableRow key={managerId}>
										<TableCell>
											<IconButton
												aria-label="expand row"
												size="small"
												onClick={() => handleManagerClick(managerId)}
											>
												{openManager[managerId] ? (
													<KeyboardArrowUpIcon />
												) : (
													<KeyboardArrowDownIcon />
												)}
											</IconButton>
										</TableCell>
										<TableCell>
											<Typography fontWeight={900}>{manager.manager_name}</Typography>
										</TableCell>
										<TableCell>
											<Typography>
												{dayjs(startDate, "YYYY-MM-DD").format("DD/MM")} -
												{dayjs(endDate, "YYYY-MM-DD").format("DD/MM")}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography>
												{aggregatedDataByManager[manager.manager_name]?.missing_timesheets}
											</Typography>
										</TableCell>
										<TableCell colSpan={6}></TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ padding: 0 }} colSpan={8}>
											<Collapse in={openManager[managerId]} timeout="auto" unmountOnExit>
												<Table aria-label="resources">
													<TableHead>
														<TableRow>
															<TableCell />
															<TableCell />
															<TableCell>
																<Typography>Resource</Typography>
															</TableCell>
															<TableCell>
																<Typography>Date Range</Typography>
															</TableCell>
															<TableCell>
																<Typography>Missing Timesheets</Typography>
															</TableCell>
															<TableCell />
														</TableRow>
													</TableHead>
													<TableBody>
														{Object.keys(manager.dataByResources).map((resourceId) => {
															const resource = manager.dataByResources[resourceId];
															return (
																<>
																	<TableRow key={resourceId} style={{ padding: 0 }}>
																		<TableCell />
																		<TableCell>
																			<Stack
																				direction="row"
																				alignItems="start"
																				justifyContent="space-between"
																				gap={1}
																				sx={{
																					width: "100%"
																				}}
																			>
																				<IconButton
																					aria-label="expand row"
																					size="small"
																					onClick={() => handleResourceClick(resourceId)}
																				>
																					{openResource[resourceId] ? (
																						<KeyboardArrowUpIcon />
																					) : (
																						<KeyboardArrowDownIcon />
																					)}
																				</IconButton>
																			</Stack>
																		</TableCell>
																		<TableCell>
																			<Typography>
																				{allUsersMap[resource.member_id]?.name || ""}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography>
																				{dayjs(startDate, "YYYY-MM-DD").format("DD/MM")} -
																				{dayjs(endDate, "YYYY-MM-DD").format("DD/MM")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography>
																				{
																					aggregatedDataByResource[resource.member_id]
																						?.missing_timesheets
																				}
																			</Typography>
																		</TableCell>
																		<TableCell />
																	</TableRow>
																	<TableRow style={{ padding: 0 }}>
																		<TableCell
																			style={{
																				padding: 0
																			}}
																			colSpan={6}
																		>
																			<Collapse
																				in={openResource[resourceId]}
																				timeout="auto"
																				unmountOnExit
																			>
																				<Table aria-label="time entries">
																					<TableHead>
																						<TableRow>
																							<TableCell />
																							<TableCell />
																							<TableCell />
																							<TableCell>
																								<Typography>Date Performed</Typography>
																							</TableCell>
																							<TableCell>
																								<Typography>Time Off</Typography>
																							</TableCell>
																							<TableCell>
																								<Typography>Billable Hours</Typography>
																							</TableCell>
																							<TableCell>
																								<Typography>Non-Billable Hours</Typography>
																							</TableCell>
																							<TableCell>
																								<Typography>Hours Logged</Typography>
																							</TableCell>
																							<TableCell>
																								<Typography>Status</Typography>
																							</TableCell>
																						</TableRow>
																					</TableHead>
																					<TableBody>
																						{resource.timeEntries
																							.sort(
																								({ date_performed: a }, { date_performed: b }) =>
																									dayjs(a, "YYYY-MM-DD").diff(
																										dayjs(b, "YYYY-MM-DD")
																									)
																							)
																							.map((entry, index) => {
																								const { holiday_time_off, date_performed } = entry;
																								const todayTimeOff =
																									holiday_time_off?.filter(
																										({ holiday_time_off_date }) =>
																											holiday_time_off_date === date_performed
																									) || [];
																								const total_billed_hours =
																									Number(entry.billable_hours) +
																									Number(entry.non_billable_hours);
																								return (
																									<TableRow key={index}>
																										<TableCell colSpan={3} />
																										<TableCell>
																											<Typography>{date_performed}</Typography>
																										</TableCell>
																										<TableCell>
																											<Typography>
																												{todayTimeOff.length
																													? todayTimeOff[0].holiday_type
																													: "-"}
																											</Typography>
																										</TableCell>
																										<TableCell>
																											<Typography>
																												{formatFloat(entry.billable_hours)}
																											</Typography>
																										</TableCell>
																										<TableCell>
																											<Typography>
																												{formatFloat(entry.non_billable_hours)}
																											</Typography>
																										</TableCell>
																										<TableCell>
																											<Typography>
																												{formatFloat(total_billed_hours)}
																											</Typography>
																										</TableCell>
																										<TableCell
																											sx={() => {
																												let status_color = "black";
																												if (entry.calculated_status === "Time-Off")
																													status_color = "purple";
																												if (entry.calculated_status === "Missing")
																													status_color = "red";
																												if (entry.calculated_status === "Late")
																													status_color = "orange";
																												if (entry.calculated_status === "On-Time")
																													status_color = "green";
																												return {
																													color: status_color
																												};
																											}}
																										>
																											<Typography>
																												{entry.calculated_status}
																											</Typography>
																										</TableCell>
																									</TableRow>
																								);
																							})}
																					</TableBody>
																				</Table>
																			</Collapse>
																		</TableCell>
																	</TableRow>
																</>
															);
														})}
													</TableBody>
												</Table>
											</Collapse>
										</TableCell>
									</TableRow>
								</>
							);
						})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CollapsibleTable;
