import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import {
	assignCertificationAPI,
	deleteCertificationAPI,
	getCertificationDashboardAPI,
	updateCertificationAPI
} from "../../apis/Dashboards";
import { submitCertificationAttemptAPI } from "../../apis/certificates";
import dayjs from "dayjs";

/** Utils functions */
function getMostRecentDueDate({ AOO = [] }) {
	if (AOO.length === 0) return null;
	const most_recent_due_date = AOO.reduce(
		(latest, obj) => (dayjs(obj.due_on).isBefore(dayjs(latest)) ? obj.due_on : latest),
		dayjs(AOO[0].due_on)
	);
	return dayjs(most_recent_due_date).format("YYYY-MM-DD");
}

const certificationAdapter = createEntityAdapter({
	selectId: (certification) => certification.id
});

const initialState = certificationAdapter.getInitialState({
	status: "idle", // For fetchCertificationDashboard
	assignStatus: "idle", // For assignCertification
	error: null
});

export const fetchCertificationDashboard = createAsyncThunk(
	"certificationDashboard/fetchCertificationDashboard",
	async (params = {}, { rejectWithValue }) => {
		try {
			const data = await getCertificationDashboardAPI(params);
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const assignCertification = createAsyncThunk(
	"userCertifications/assignCertification",
	async (
		{ deadline, member_id, certification_id, notes = null, assigned_by_id },
		{ rejectWithValue }
	) => {
		try {
			const [certificationData] = await assignCertificationAPI({
				deadline,
				member_id,
				certification_id,
				notes,
				assigned_by_id
			});
			return { ...certificationData, member_id };
		} catch (error) {
			return rejectWithValue(error.response?.data?.message || "Failed to assign certification");
		}
	}
);

export const submitCertificationAttempt = createAsyncThunk(
	"certificationDashboard/submitCertificationAttempt",
	async ({ track_id, attempt_date, status, member_id }, { rejectWithValue }) => {
		try {
			const acquired_certifications = await submitCertificationAttemptAPI({
				certification_track_key: track_id,
				acquired_date: attempt_date,
				status
			});
			return { acquired_certifications, member_id, track_id, status };
		} catch (error) {
			return rejectWithValue(
				error.response?.data?.message || "Failed to submit certification attempt"
			);
		}
	}
);

export const updateCertification = createAsyncThunk(
	"userCertifications/updateCertification",
	async (
		{ deadline, member_id, certification_id, notes = null, modified_by_id, track_id },
		{ rejectWithValue }
	) => {
		try {
			const [certificationData] = await updateCertificationAPI({
				deadline,
				member_id,
				certification_id,
				notes,
				modified_by_id,
				track_id
			});
			return { ...certificationData, member_id };
		} catch (error) {
			return rejectWithValue(error.response?.data?.message || "Failed to update certification");
		}
	}
);

export const deleteCertification = createAsyncThunk(
	"userCertifications/deleteCertification",
	async ({ member_id, modified_by_id, track_id, certification_id }, { rejectWithValue }) => {
		try {
			const [certificationData] = await deleteCertificationAPI({
				certification_id,
				member_id,
				modified_by_id,
				track_id
			});
			return { ...certificationData, member_id };
		} catch (error) {
			return rejectWithValue(error.response?.data?.message || "Failed to delete certification");
		}
	}
);

const certificationDashboardSlice = createSlice({
	name: "certificationDashboard",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// fetchCertificationDashboard loading states
			.addCase(fetchCertificationDashboard.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchCertificationDashboard.fulfilled, (state, action) => {
				state.status = "succeeded";
				certificationAdapter.setAll(state, action.payload);
			})
			.addCase(fetchCertificationDashboard.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload || "Failed to fetch certification data";
			})

			// assignCertification loading states
			.addCase(assignCertification.pending, (state) => {
				state.assignStatus = "loading";
			})
			.addCase(assignCertification.fulfilled, (state, action) => {
				state.assignStatus = "succeeded";
				const { member_id, ...certification } = action.payload;
				if (!state.entities[member_id]) {
					state.entities[member_id] = {
						assigned_certificates: []
					};
				}

				state.entities[member_id].assigned_certificates.push({
					...certification
				});
				state.entities[member_id].num_certifications_assigned += 1;
				state.entities[member_id].most_recent_due_date = getMostRecentDueDate({
					AOO: [...JSON.parse(JSON.stringify(state.entities[member_id].assigned_certificates))]
				});
			})
			.addCase(assignCertification.rejected, (state, action) => {
				state.assignStatus = "failed";
				state.error = action.payload;
			})

			.addCase(submitCertificationAttempt.fulfilled, (state, action) => {
				const { acquired_certifications, member_id, track_id, status } = action.payload;
				if (!state.entities[member_id]) {
					return;
				}

				const existingTrackIndex = state.entities[member_id].assigned_certificates.findIndex(
					(track) => track.track_id === track_id
				);
				if (existingTrackIndex !== -1) {
					const number_of_attempts =
						state.entities[member_id].assigned_certificates[existingTrackIndex].number_of_attempts;
					state.entities[member_id].assigned_certificates[existingTrackIndex].number_of_attempts =
						number_of_attempts ? number_of_attempts + 1 : 1;
				}
				if (status === "Passed") {
					state.entities[member_id].certificates = acquired_certifications;
					state.entities[member_id].assigned_certificates = state.entities[
						member_id
					].assigned_certificates.filter((cert) => cert.track_id !== track_id);
					state.entities[member_id].num_certifications_assigned -= 1;
					state.entities[member_id].num_certifications_held += 1;
					state.entities[member_id].most_recent_due_date = getMostRecentDueDate({
						AOO: [...JSON.parse(JSON.stringify(state.entities[member_id].assigned_certificates))]
					});
				}
			})
			.addCase(updateCertification.fulfilled, (state, action) => {
				const { member_id, ...updatedTrack } = action.payload;
				if (state.entities[member_id]) {
					const existingTrackIndex = state.entities[member_id].assigned_certificates.findIndex(
						(track) => track.track_id === updatedTrack.track_id
					);

					const tempAssignedCertifications = [...state.entities[member_id].assigned_certificates];

					if (existingTrackIndex !== -1) {
						tempAssignedCertifications[existingTrackIndex] = {
							...updatedTrack
						};
						state.entities[member_id].assigned_certificates = [...tempAssignedCertifications];

						state.entities[member_id].most_recent_due_date = getMostRecentDueDate({
							AOO: [...tempAssignedCertifications]
						});
					} else {
						console.warn(
							`Track with id ${updatedTrack.track_id} not found for member_id ${member_id}`
						);
					}
				}
			})
			.addCase(deleteCertification.fulfilled, (state, action) => {
				const { member_id, ...deletedTrack } = action.payload;
				if (state.entities[member_id]) {
					state.entities[member_id].assigned_certificates = state.entities[
						member_id
					].assigned_certificates.filter((track) => track.track_id !== deletedTrack.track_id);
					state.entities[member_id].num_certifications_assigned -= 1;
					state.entities[member_id].most_recent_due_date = getMostRecentDueDate({
						AOO: [...JSON.parse(JSON.stringify(state.entities[member_id].assigned_certificates))]
					});
				}
			});
	}
});

export default certificationDashboardSlice.reducer;

export const {
	selectIds: selectAllCertificationIds,
	selectAll: selectAllCertifications,
	selectById: selectCertificationById
} = certificationAdapter.getSelectors((state) => state.userCertifications);
