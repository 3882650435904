const axios = require("axios");
const moment = require("moment");

function formatCurrecy(amount, options) {
	if (amount !== 0 && !amount) {
		return "NA";
	}
	let zone = options?.zone || "en-US";
	let currency = options?.currency || "USD";

	return amount.toLocaleString(zone, {
		style: "currency",
		currency: currency
	});
}

function formatNumber(value, toFixed) {
	const decimalPoints = toFixed || 2;
	return parseFloat(Number(value).toFixed(decimalPoints));
}

function formatDate(date, analysePattern) {
	if (!date) return "NA";
	if (analysePattern === "abs") {
		let absDate = date.split("T")[0];
		return moment(absDate).format("MM/DD/YY");
	}
	return moment(date).format("MM/DD/YY");
}

function getWorkingAndCurrentDayInAMonth() {
	const currentDate = moment().format("YYYY-MM-DD");
	const splitDate = currentDate.split("-");
	let currentDay = 1;
	const daysInMonth = moment(`${splitDate[0]}-${splitDate[1]}`, "YYYY-MM").daysInMonth();
	let totalWorkDays = 0;
	for (let day = 1; day <= daysInMonth; day++) {
		const date = moment(`${splitDate[0]}-${splitDate[1]}-${day}`, "YYYY-MM-DD");
		const isWeekend = date.isoWeekday() >= 6; // 6: Saturday, 7: Sunday

		// considering weekends and adding +1 to maintain the count
		if (moment(date).isSame(currentDate)) {
			currentDay = totalWorkDays + 1;
		}

		if (!isWeekend) {
			totalWorkDays++;
		}
	}
	return { totalWorkDays, currentDay };
}
const setAuthToken = (token) => {
	// Apply auth header to every request
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const deleteAuthToken = () => {
	delete axios.defaults.headers.common["Authorization"];
};

//Group data by a value of variable in an Array
const groupByValue = ({ data, groupByVariable }) => {
	let groupedData = data.reduce((acc, current) => {
		let groupValue = current[groupByVariable];
		if (!acc[groupValue]) {
			acc[groupValue] = { groupValue, details: [] };
		}
		acc[groupValue].details.push(current);
		return acc;
	}, {});
	return Object.values(groupedData);
};

const openURL = ({ url }) => window.open(url, "_blank", "noreferrer");

const getValueFromPath = (obj, path) => {
	return path.split(".").reduce((acc, key) => acc && acc[key], obj);
};

module.exports = {
	formatCurrecy,
	formatNumber,
	formatDate,
	getWorkingAndCurrentDayInAMonth,
	setAuthToken,
	deleteAuthToken,
	groupByValue,
	openURL,
	getValueFromPath
};
