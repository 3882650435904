import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Typography, styled } from "@mui/material";
import { Stack } from "../UIComponents";
import { preventCustomPropForwarding } from "../Utils/MUI";

const colorPallette = ["#7367f0", "#6f6f6f8a", "#00CFE8", "#28C76F", "#FF9F43", "#EA5455"];

const StyledBGStack = styled(
	Stack,
	preventCustomPropForwarding(["steps", "color"])
)(({ theme, steps, color }) => ({
	width: "100%",
	alignItems: "center",
	flexDirection: "row",
	justifyContent: steps?.length > 0 ? "flex-start" : "space-between",
	textAlign: "end",
	background: color ? theme.palette[color].light : "rgba(115, 103, 240, 0.12)",
	borderRadius: 15
}));

const StyledProgressStack = styled(
	Stack,
	preventCustomPropForwarding(["handled_progess", "color", "isCompleted"])
)(({ theme, handled_progess, color, isCompleted }) => ({
	width: isCompleted ? "100%" : `${handled_progess}%`,
	minWidth: 25,
	background: color ? theme.palette[color].main : "#7367F0",
	borderRadius: 15,
	textAlign: "center"
}));

const ProgressBarWithText = (props) => {
	const { value, maxValue, showScale, minValue, steps, color } = props;
	const isCompleted = parseFloat(value) >= parseFloat(maxValue);
	const progress = (parseFloat(value) / (parseFloat(maxValue) || 100)) * 100;
	const handled_progess = progress > 100.0 ? 100 : progress;

	return (
		<Fragment>
			<StyledBGStack color={color}>
				{steps?.length > 0 ? (
					steps.map((ele, i) => (
						<div
							key={`step-pbwt-${i}`}
							style={{
								width: `${ele / maxValue}%`,
								minWidth: 25,
								background: colorPallette[i % 6],
								borderRadius: 15,
								textAlign: "center"
							}}
						>
							<Typography variant="Regular" color={"#FFF"}>
								{ele}
							</Typography>{" "}
						</div>
					))
				) : (
					<StyledProgressStack
						color={color}
						isCompleted={isCompleted}
						handled_progess={handled_progess}
					>
						<Typography variant="Regular" color={"#FFF"}>
							{value}
							{parseFloat(value) >= parseFloat(maxValue) && " / " + maxValue}
						</Typography>
					</StyledProgressStack>
				)}
				{!showScale && parseFloat(value) < parseFloat(maxValue) && (
					<div style={{ paddingRight: 8 }}>
						<Typography variant="Regular"> {maxValue}</Typography>
					</div>
				)}
			</StyledBGStack>
			{showScale && (
				<div
					style={{
						width: "100%",
						minWidth: 20,
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between"
					}}
				>
					<Typography variant="Regular" textAlign={"start"} sx={{ color: "text.disabled" }}>
						{minValue || 0}
					</Typography>
					<Typography variant="Regular" textAlign={"end"} sx={{ color: "text.disabled" }}>
						{maxValue || 0}
					</Typography>
				</div>
			)}
		</Fragment>
	);
};

ProgressBarWithText.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	showScale: PropTypes.bool,
	steps: PropTypes.arrayOf(PropTypes.number)
};
export default ProgressBarWithText;
