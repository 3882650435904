import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { formatDate, formatNumber, groupByValue } from "../../utils";
import { TableCell, TableRow, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
const ManagerRow = (props) => {
	const { deliverable } = props;
	const goodHealth = useMemo(() => {
		if (deliverable.daysRemaining < 0 || deliverable.hoursRemaining < 0) {
			return false;
		}
		return true;
	}, [deliverable.daysRemaining, deliverable.hoursRemaining]);

	return (
		<TableRow>
			<TableCell size="verysmall">
				<Typography
					variant="caption"
					component={"a"}
					href={deliverable.url}
					target="_blank"
					sx={{
						color:
							deliverable.priority === "high" || deliverable.priority === "critical"
								? "#ff4c4c"
								: "#0d6efd"
					}}
				>
					{deliverable.deliverableName}
				</Typography>
			</TableCell>
			<TableCell size="verysmall">{deliverable.deliverableStatus}</TableCell>
			<TableCell size="verysmall">{deliverable.deliverablePlatform}</TableCell>
			<TableCell size="verysmall">{formatDate(deliverable.startDate)}</TableCell>
			<TableCell size="verysmall">{formatDate(deliverable.endDate)}</TableCell>
			<TableCell size="verysmall">{deliverable.manager}</TableCell>
			<TableCell size="verysmall">{deliverable.projectlead}</TableCell>
			<TableCell size="verysmall">{formatNumber(deliverable.estimatedHours)}</TableCell>
			<TableCell size="verysmall">{formatNumber(deliverable.billedHours)}</TableCell>
			<TableCell
				size="verysmall"
				sx={{ color: deliverable.hoursRemaining < 0 ? "#ff4c4c" : "inherit" }}
			>
				{formatNumber(deliverable.hoursRemaining)}
			</TableCell>
			<TableCell
				size="verysmall"
				sx={{ color: deliverable.daysRemaining < 0 ? "#ff4c4c" : "inherit" }}
			>
				{formatNumber(deliverable.daysRemaining)}
			</TableCell>
			<TableCell size="verysmall">
				{goodHealth ? <CheckCircleIcon sx={{ color: "green" }} /> : <ErrorIcon color="error" />}
			</TableCell>
		</TableRow>
	);
};

const ProjectRow = (props) => {
	const { project } = props;
	const workspaceUrl = project.details[0].url.match(/(.*?)\/tracker/)[1];
	return (
		<Fragment>
			<TableRow>
				<TableCell size="verysmall" rowSpan={project.details.length + 1} className="td-b-r">
					<Typography variant="caption" component={"a"} href={workspaceUrl} target="_blank">
						{project.groupValue}
					</Typography>
				</TableCell>
			</TableRow>
			{project.details.map((deliverable, index) => (
				<ManagerRow
					key={`Delv-${index}-${deliverable.deliverableName}`}
					deliverable={deliverable}
				/>
			))}
		</Fragment>
	);
};
const AssigneeRow = (props) => {
	const { assigneeGroupedData } = props;

	const groupedSubStatus = useMemo(
		() =>
			groupByValue({
				data: assigneeGroupedData.details,
				groupByVariable: "subStatus"
			}),
		[assigneeGroupedData]
	);
	const groupedProjectsData = useMemo(() => {
		if (groupedSubStatus.length > 0) {
			let grpData = {};
			for (let i = 0; i < groupedSubStatus.length; i++) {
				const groupedProjects = groupByValue({
					data: groupedSubStatus[i].details,
					groupByVariable: "projectName"
				});
				grpData[groupedSubStatus[i].groupValue] = groupedProjects;
			}
			return grpData;
		}
	}, [groupedSubStatus]);

	const derivedRowSpanFromGroupedSubStatus = useMemo(() => {
		let sum = 1;
		for (let gs = 0; gs < groupedSubStatus.length; gs++) {
			sum +=
				groupedSubStatus[gs].details.length +
				groupedProjectsData[groupedSubStatus[gs].groupValue].length +
				1;
		}
		return sum;
	}, [groupedSubStatus, groupedProjectsData]);

	return (
		<React.Fragment>
			<TableRow>
				<TableCell size="verysmall" rowSpan={derivedRowSpanFromGroupedSubStatus} className="td-b-r">
					{assigneeGroupedData.groupValue || "Unassigned"}
				</TableCell>
			</TableRow>
			{groupedSubStatus.map((substatus, i) => (
				<Fragment>
					<TableRow
						key={`substatus-${i}-${assigneeGroupedData.groupValue}}-${substatus.groupValue}`}
					>
						<TableCell
							size="verysmall"
							rowSpan={
								substatus.details.length + groupedProjectsData[substatus.groupValue].length + 1
							}
							className="td-b-r"
						>
							{substatus.groupValue}
						</TableCell>
					</TableRow>
					{groupedProjectsData[substatus.groupValue].map((project, ix) => (
						<ProjectRow project={project} key={`project-${ix}-${project.groupValue}`} />
					))}
				</Fragment>
			))}
		</React.Fragment>
	);
};

AssigneeRow.propTypes = {
	assigneeGroupedData: PropTypes.object.isRequired
};

export default AssigneeRow;
