import React, { useState } from "react";

const Trainer = () => {
	const [vid, setVid] = useState(false);
	const [pdf, setPdf] = useState(false);
	const [work, setWork] = useState(true);

	const subChange = (p) => {
		if (p === "p") {
			setPdf(true);
			setVid(false);
			setWork(false);
		} else if (p === "v") {
			setPdf(false);
			setVid(true);
			setWork(false);
		} else if (p === "z") {
			setPdf(false);
			setVid(false);
			setWork(true);
		}
	};

	return (
		<section className="hero">
			<div className="hero-body ">
				<div className="container">
					<h3>Training Resources </h3>
					<br />
					<hr />
					<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
						<li className="nav-item" role="presentation">
							<button
								className={work ? "nav-link active" : "nav-link"}
								type="button"
								onClick={() => subChange("z")}
							>
								SCRIBE{" "}
							</button>
						</li>
						<li className="nav-item" role="presentation">
							<button
								className={pdf ? "nav-link active" : "nav-link"}
								type="button"
								onClick={() => subChange("p")}
							>
								PDF{" "}
							</button>
						</li>
						<li className="nav-item" role="presentation">
							<button
								className={vid ? "nav-link active" : "nav-link"}
								type="button"
								onClick={() => subChange("v")}
							>
								VIDEO
							</button>
						</li>
					</ul>
					<hr />

					<div
						className="columns is-centered"
						style={work ? { display: "block" } : { display: "none" }}
					>
						<div className="column is-12-desktop">
							<iframe
								title="View PDF doc"
								id="Pdf-viewer"
								src="https://scribehow.com/embed/Workflow__qdQqvF24RjOKc_IDwdWcVg"
								width="100%"
								height="640"
								allowfullscreen
								frameborder="0"
							></iframe>
						</div>
					</div>

					<div
						className="columns is-centered"
						style={pdf ? { display: "block" } : { display: "none" }}
					>
						<div className="column is-12-desktop">
							<iframe
								src="/pdf/training.pdf"
								title="Manual"
								style={{ width: "100%", height: "70vh", border: "none" }}
							></iframe>
						</div>
					</div>

					<div
						className="columns is-centered"
						style={vid ? { display: "block" } : { display: "none" }}
					>
						<div className="column is-12-desktop">
							<video width="100%" height="500px" controls>
								<source src="pdf/Manual.mp4" type="video/mp4" />
							</video>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Trainer;
