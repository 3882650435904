import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import MtgBridgeRoutes from "./MtgBridgeRoutes";
import axios from "axios";
import jwt_decode from "jwt-decode";

import { usePrevious } from "../CustomHooks/usePrevious";
import { deleteAuthToken, setAuthToken } from "../utils";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../CustomHooks/AuthenticationHook";

axios.defaults.withCredentials = true;

function AuthWrapper() {
	const [loading, setLoading] = useState(true);
	const [expire, setExpire] = useState("");
	const isMountedRef = useRef(false);

	const location = useLocation();
	const navigate = useNavigate();
	const timerRef = useRef(null);

	const { token, setToken } = useAuth();

	const prevToken = usePrevious(token);

	const getAccessToken = useCallback(() => {
		axios
			.get(`${`${process.env.REACT_APP_PROD}/api/v1`}/token`)
			.then((res) => {
				setAuthToken(res.data.accessToken);
				setToken(res.data.accessToken);
				setLoading(false);
				if (location.pathname === "/") navigate("/dashboard");
			})
			.catch((err) => {
				console.log({ err });
				setLoading(false);
				if (err.response.status === 401) {
					setToken(null);
					deleteAuthToken();
					setLoading(false);
					if (location.pathname !== "/") navigate("/");
				}
			});
	}, [setToken, location.pathname, navigate]);

	useEffect(() => {
		if (token && token !== prevToken) {
			setAuthToken(token);
			const decoded = jwt_decode(token);
			setExpire(decoded.exp);
		}
	}, [prevToken, token, getAccessToken]);

	useEffect(() => {
		let timer;

		if (expire) {
			const currentTime = new Date().getTime();
			const expirationTime = expire * 1000;

			if (expirationTime > currentTime) {
				setLoading(false);

				const timeGap = expirationTime - currentTime;
				timer = setTimeout(() => {
					getAccessToken();
				}, timeGap);
			} else {
				getAccessToken();
			}
		}

		// Save the timer value to the ref
		timerRef.current = timer;

		return () => {
			// Clear the timeout using the saved timer value from the ref
			if (timer && location.pathname === "/") {
				console.log("Clearing token time out in login page");
				clearTimeout(timerRef.current);
			}
		};
	}, [expire, getAccessToken, location.pathname]);

	useEffect(() => {
		if (!isMountedRef.current) {
			getAccessToken();
			isMountedRef.current = true;
		}
	}, [getAccessToken]);

	return (
		<Fragment>
			{loading ? (
				<Backdrop
					sx={{
						background: "#F3F3F3",
						zIndex: (theme) => theme.zIndex.drawer + 1
					}}
					open={loading}
				>
					<CircularProgress sx={{ color: "#00CFE8" }} />
				</Backdrop>
			) : (
				<MtgBridgeRoutes />
			)}
		</Fragment>
	);
}

export default AuthWrapper;
