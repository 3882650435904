import { useState, useCallback } from "react";
import { Paper } from "../../../UIComponents/index.js";
import Table from "../../../UIComponents/DesignSystem/Table.js";
import TableBody from "../../../UIComponents/DesignSystem/TableBody.js";
import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import TableContainer from "../../../UIComponents/DesignSystem/TableContainer.js";
import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import { CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { fetchHistoricalReports } from "../../../redux/reducers/projectHealthDashboard.js";
import dayjs from "dayjs";
import TableHeadComponent from "./TableHeader.js";
import ProjectRow from "./ProjectRow.js"; // Make sure ProjectRow uses React.memo
import ReportDrawer from "./Drawer.js";
import { DrawerProvider } from "./useDrawer.js";

export const current_state_fields_mapping = {
	total_billable_minutes: "Billable Hours",
	total_nonbillable_minutes: "Non-Billable Hours",
	utilization_rate: "Utilization Rate",
	percent_of_budget_used: "Budget Used(%)",
	project_start_date: "Start Date",
	original_due_date: "Original Due Date",
	expected_due_date: "Expected Due Date",
	percentage_complete: "Percentage Complete",
	pm_grade: "PM Grade",
	days_since_last_report: "Days since last report"
};

export const healthReportMapping = {
	for_week_of: "Week of",
	submitted_on: "Date Submitted",
	"snapshot.utilization_rate": "Utilization Rate",
	"snapshot.percent_of_budget_used": "Budget Used(%)",
	"snapshot.pm_grade": "PM Grade",
	overall: "Health Update",
	client_engagement: "Client Engagement",
	client_timeliness: "Client Timeliness",
	schedule: "Project Schedule",
	budget: "Project Budget",
	project_grade: "Health Grade"
};

const CollapsibleTable = () => {
	const dispatch = useDispatch();

	const loadingState = useSelector((state) => state.projectHealthDashboard.calculated.status);

	const projectHealthDashboardSortedIds = useSelector(
		(state) => state.projectHealthDashboard.calculated.ids
	);

	const projectHealthDashboardData = useSelector(
		(state) => state.projectHealthDashboard.calculated.entities
	);

	const data = projectHealthDashboardSortedIds.map((id) => projectHealthDashboardData[id]);

	const [openProject, setOpenProject] = useState({});

	// Memoized function to handle project click
	const handleProjectClick = useCallback(
		({ project_id, historicalReportIds }) => {
			setOpenProject((prev) => ({ ...prev, [project_id]: !prev[project_id] }));
			if (!historicalReportIds.length) {
				dispatch(fetchHistoricalReports({ project_id }));
			}
		},
		[dispatch]
	);

	return (
		<>
			<DrawerProvider>
				<TableContainer component={Paper}>
					<Table aria-label="collapsible table">
						<TableHeadComponent
							columns={[
								"Project Name",
								"Manager",
								"Project Lead",
								...Object.values(current_state_fields_mapping)
								// "Submit New Report",
							]}
						/>
						<TableBody>
							{loadingState === "loading" && (
								<TableRow>
									<TableCell colSpan={13} style={{ textAlign: "center", height: 100 }}>
										<CircularProgress color="primary" />
									</TableCell>
								</TableRow>
							)}
							{loadingState === "failed" && (
								<TableRow>
									<TableCell colSpan={13} style={{ textAlign: "center", height: 100 }}>
										Error in loading the data! Please try again later.
									</TableCell>
								</TableRow>
							)}
							{loadingState === "succeeded" && data.length === 0 && (
								<TableRow>
									<TableCell colSpan={13} style={{ textAlign: "center", height: 100 }}>
										No Records found!
									</TableCell>
								</TableRow>
							)}
							{loadingState === "succeeded" &&
								data.length &&
								data.map((prjct) => {
									const project = { ...prjct };
									// project.utilization_rate =
									//   (
									//     ((project.total_billable_minutes ?? 0) /
									//       (project.total_minutes ?? 1)) *
									//     100
									//   ).toFixed(2) || "-";
									project.utilization_rate =
										Number.isFinite(project.total_billable_minutes) &&
										Number.isFinite(project.total_minutes) &&
										project.total_minutes > 0
											? ((project.total_billable_minutes / project.total_minutes) * 100).toFixed(
													2
												) + "%"
											: "-";
									project.total_billable_minutes =
										(project.total_billable_minutes / 60).toFixed(2) || "-";
									project.total_nonbillable_minutes =
										(project.total_nonbillable_minutes / 60).toFixed(2) || "-";

									project.project_start_date = project.project_start_date
										? dayjs(project.project_start_date).format("MMM DD, YYYY")
										: "-";

									project.original_due_date = project.original_due_date
										? dayjs(project.original_due_date).format("MMM DD, YYYY")
										: "-";

									project.expected_due_date = project.expected_due_date
										? dayjs(project.expected_due_date).format("MMM DD, YYYY")
										: "-";

									project.percent_of_budget_used = `${project.percent_of_budget_used}%`;
									project.percentage_complete = `${project.percentage_complete}%`;

									const historicalReportIds = project?.historicalReportIds ?? [];

									return (
										<ProjectRow
											key={project.project_id}
											project={project}
											openProject={openProject}
											handleProjectClick={handleProjectClick}
											historicalReportIds={historicalReportIds}
										/>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
				<ReportDrawer />
			</DrawerProvider>
		</>
	);
};

export default CollapsibleTable;
