import styled from "@emotion/styled";
import { Stack } from "../../UIComponents";

/** Product Details */
export const StyledProjectDetailsWrapper = styled(Stack)(({ theme }) => ({
	borderBottom: `2px solid ${theme.palette.border.main}`,
	boxSizing: "border-box",
	padding: "0.5rem",
	paddingBottom: "1.5rem"
}));
