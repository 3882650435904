import axios from "axios";
import { toast } from "react-toastify";
const urlPrefix = `${process.env.REACT_APP_PROD}/api/v1`;

/**
 * A common http call method
 * @param {string} type : Method type
 * @param {*} url : URL of the call
 * @param {*} data : payload which is to be passed
 */
export const httpCall = async ({ type, url, data, customURLPrefix, axiosProps = {} }) => {
	const requestParams = {
		method: type,
		url: customURLPrefix ? url : `${urlPrefix}/${url}`,
		...axiosProps
	};

	if (data) {
		requestParams[type.toLowerCase() === "get" ? "params" : "data"] = data;
	}

	try {
		const response = await axios(requestParams);
		return response;
	} catch (e) {
		console.log({ e });
		const statusCode = e.response.status;
		const message = e.response.message;
		switch (statusCode) {
			case 400:
				toast.error("Bad Request");
				break;
			case 401:
				toast.error("Unauthorized");
				// window.location.href = "/login";
				break;
			case 403:
				toast.error("Forbidden");
				break;
			case 404:
				toast.error("Resource not found");
				break;
			case 408:
				toast.error("Request Time out");
				break;
			case 500:
				toast.error(message || "Internal Server error");
				break;
			case 503:
				toast.error("Service Unavailable");
				break;
			default:
		}
	}
};

export const getMavenlinkAPIResponse = async ({ endpoint, data, type }) => {
	const response = await httpCall({
		type: "POST",
		url: `mavenlink`,
		data: {
			endpoint,
			type,
			data
		}
	});
	return response;
};
