/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Grid } from "../../UIComponents/index.js";
import ProjectDetails from "./ProjectDetails.js";
import { useNavigate, useParams } from "react-router-dom";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getMeetingsByWorkspace,
	updateWorkspaceMeetingThunk
} from "../../redux/reducers/decisions.js";
import {
	getDeliverablesByWorkspace,
	getWorkspaceResources
} from "../../redux/reducers/workspaces.js";
import { getDeliverablesNotesByWorkspace } from "../../redux/reducers/decisions.js";
import DecisionTrackerMeeting from "./DecisionTrackerMeeting.js";
import EmptyMeetingList from "./DecisionTracker/EmptyMeetingList.js";
import { CircularProgress, styled } from "@mui/material";
import CreateNewMeetingButton from "./DecisionTracker/CreateNewMeet.js";
import { StyledComponentWrapper } from "./index.js";
import ConfirmationModal from "./ConfirmationModal.js";
import { getAllUsers } from "../../redux/reducers/users.js";

const StyledMeetingsWrapper = styled(Stack)(() => ({
	overflow: "auto"
}));

export const ValidationContext = createContext(null);

const curried_add_unsaved_meeting =
	({ d_t_validation, d_t_set_validation }) =>
	({ meetingId }) => {
		if (d_t_validation.unsaved_meeting_ids.includes(meetingId)) return;
		d_t_set_validation((prev) => {
			const newState = structuredClone(prev);
			newState.unsaved_meeting_ids.push(meetingId);
			return newState;
		});
	};

const curried_remove_unsaved_meeting =
	({ d_t_validation, d_t_set_validation }) =>
	({ meetingId }) => {
		if (d_t_validation.unsaved_meeting_ids.includes(meetingId)) {
			d_t_set_validation((prev) => {
				const newState = structuredClone(prev);
				newState.unsaved_meeting_ids = newState.unsaved_meeting_ids.filter(
					(unsaved_meeting_id) => unsaved_meeting_id !== meetingId
				);
				return newState;
			});
		}
	};

const curried_add_unsaved_deliverable =
	({ d_t_validation, d_t_set_validation }) =>
	({ meetingId, noteId, deliverableId, noteValue }) => {
		if (deliverableId) {
			d_t_set_validation((prev) => {
				const newState = structuredClone(prev);
				if (newState.unsaved_deliverable_notes_ids.includes(`${deliverableId}-dummy`)) {
					newState.unsaved_deliverable_notes_entities[`${deliverableId}-dummy`] = {
						value: noteValue
					};
					return newState;
				}
				newState.unsaved_deliverable_notes_ids.push(`${deliverableId}-dummy`);
				if (newState.unsaved_deliverable_notes_ids_by_meeting[meetingId]?.length) {
					newState.unsaved_deliverable_notes_ids_by_meeting[meetingId].push(
						`${deliverableId}-dummy`
					);
				} else {
					newState.unsaved_deliverable_notes_ids_by_meeting[meetingId] = [`${deliverableId}-dummy`];
				}
				newState.unsaved_deliverable_notes_entities[`${deliverableId}-dummy`] = {
					value: noteValue
				};
				return newState;
			});
			return;
		}
		d_t_set_validation((prev) => {
			const newState = structuredClone(prev);
			if (newState.unsaved_deliverable_notes_ids.includes(noteId)) {
				newState.unsaved_deliverable_notes_entities[noteId].value = noteValue;
				return newState;
			}
			newState.unsaved_deliverable_notes_ids.push(noteId);
			if (newState.unsaved_deliverable_notes_ids_by_meeting[meetingId]?.length) {
				newState.unsaved_deliverable_notes_ids_by_meeting[meetingId].push(noteId);
			} else {
				newState.unsaved_deliverable_notes_ids_by_meeting[meetingId] = [noteId];
			}
			newState.unsaved_deliverable_notes_entities[noteId] = {
				value: noteValue
			};
			return newState;
		});
	};

const curried_remove_unsaved_deliverable =
	({ d_t_validation, d_t_set_validation }) =>
	({ meetingId, noteId, deliverableId }) => {
		if (deliverableId) {
			d_t_set_validation((prev) => {
				if (!prev.unsaved_deliverable_notes_ids.includes(`${deliverableId}-dummy`)) return prev;
				console.log({ prev });
				const newState = structuredClone(prev);
				if (newState.unsaved_deliverable_notes_ids_by_meeting[meetingId]?.length) {
					newState.unsaved_deliverable_notes_ids_by_meeting[meetingId] =
						newState.unsaved_deliverable_notes_ids_by_meeting[meetingId].filter(
							(unsaved_deliverable_note_id) =>
								unsaved_deliverable_note_id !== `${deliverableId}-dummy`
						);
				}
				newState.unsaved_deliverable_notes_ids = newState.unsaved_deliverable_notes_ids.filter(
					(unsaved_deliverable_note_id) => unsaved_deliverable_note_id !== `${deliverableId}-dummy`
				);
				delete newState.unsaved_deliverable_notes_entities[`${deliverableId}-dummy`];
				return newState;
			});
			return;
		}
		d_t_set_validation((prev) => {
			if (!prev.unsaved_deliverable_notes_ids.includes(noteId)) return prev;
			const newState = structuredClone(prev);
			if (newState.unsaved_deliverable_notes_ids_by_meeting[meetingId]?.length) {
				newState.unsaved_deliverable_notes_ids_by_meeting[meetingId] =
					newState.unsaved_deliverable_notes_ids_by_meeting[meetingId].filter(
						(unsaved_deliverable_notes_id) => unsaved_deliverable_notes_id !== noteId
					);
			}
			newState.unsaved_deliverable_notes_ids = newState.unsaved_deliverable_notes_ids.filter(
				(unsaved_deliverable_notes_id) => unsaved_deliverable_notes_id !== noteId
			);
			delete newState.unsaved_deliverable_notes_entities[noteId];
			return newState;
		});
	};

const get_d_t_setters = ({ d_t_validation, d_t_set_validation }) => ({
	add_unsaved_meeting: curried_add_unsaved_meeting({
		d_t_validation,
		d_t_set_validation
	}),
	remove_unsaved_meeting: curried_remove_unsaved_meeting({
		d_t_validation,
		d_t_set_validation
	}),
	add_unsaved_deliverable: curried_add_unsaved_deliverable({
		d_t_validation,
		d_t_set_validation
	}),
	remove_unsaved_deliverable: curried_remove_unsaved_deliverable({
		d_t_validation,
		d_t_set_validation
	})
});

const get_workspace_close_modal = ({ navigate, setModalState }) => ({
	isOpen: true,
	description: "Some meeting(s) have unsaved changes. Do you wish you discard unsaved changes?",
	OkText: "Continue Editing",
	cancleText: "Discard",
	onCancle: () => navigate("/decision-tracker"),
	onOk: () =>
		setModalState((prev) => {
			const newState = {
				...prev,
				isOpen: false
			};
			return newState;
		})
});

const get_meeting_save_modal = ({ setModalState }) => ({
	isOpen: true,
	description:
		"Some deliverable note(s) have unsaved changes. Please save them before saving the meeting",
	OkText: "Ok",
	noCancleButton: true,
	onOk: () =>
		setModalState((prev) => {
			const newState = {
				...prev,
				isOpen: false
			};
			return newState;
		})
});

export const get_closing_deliverable_modal = ({ setModalState }) => ({
	isOpen: true,
	description:
		"Some deliverable note(s) have unsaved changes. Please save them before closing the deliverables",
	OkText: "Ok",
	noCancleButton: true,
	onOk: () =>
		setModalState((prev) => {
			const newState = {
				...prev,
				isOpen: false
			};
			return newState;
		})
});

const DecisionTrackerForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { workspaceId } = useParams();
	const allUsersMap = useSelector((state) => state.users.all);

	const { isLoading, isSuccess } =
		useSelector((state) => state.apiStatus["decisions/getMeetingsByWorkspace"]) || {};

	const workspace_meetings_map =
		useSelector((state) => state.decisions.meetings[workspaceId]) || {};
	const workspace_meetings = useMemo(
		() =>
			Object.values(workspace_meetings_map).sort(({ meeting_id: a }, { meeting_id: b }) => b - a),
		[workspace_meetings_map]
	);
	const no_meetings = workspace_meetings?.length === 0;

	const [d_t_validation, d_t_set_validation] = useState({
		unsaved_meeting_ids: [],
		unsaved_deliverable_notes_ids: [],
		unsaved_deliverable_notes_ids_by_meeting: {},
		unsaved_deliverable_notes_entities: {}
	});

	const d_t_validation_setters = useMemo(
		() => get_d_t_setters({ d_t_validation, d_t_set_validation }),
		[d_t_validation]
	);
	const { remove_unsaved_meeting } = d_t_validation_setters;

	const [modalState, setModalState] = useState();

	const handleClose = useCallback(() => {
		if (
			d_t_validation.unsaved_meeting_ids.length ||
			d_t_validation.unsaved_deliverable_notes_ids.length
		) {
			setModalState(get_workspace_close_modal({ navigate, setModalState }));
		} else navigate("/decision-tracker");
	}, [d_t_validation, setModalState]);

	const handleMeetingSave = useCallback(
		(
			e,
			{
				meeting_id,
				magna_next_steps,
				client_next_steps,
				general_notes,
				agenda,
				discussedDeliverablesIds,
				title
			}
		) => {
			e.stopPropagation(); /** Stopping propogation so btn does not toggle Accordian */
			if (d_t_validation.unsaved_deliverable_notes_ids_by_meeting[meeting_id]?.length) {
				setModalState(get_meeting_save_modal({ setModalState }));
				return;
			}
			remove_unsaved_meeting({
				meetingId: meeting_id
			}); /** Removing meeting from validation object */
			const discussed_deliverables_ids_string = JSON.stringify(discussedDeliverablesIds);
			dispatch(
				updateWorkspaceMeetingThunk({
					meeting_id,
					magna_next_steps,
					client_next_steps,
					general_notes,
					agenda,
					discussed_deliverables_ids: discussed_deliverables_ids_string,
					title
				})
			);
		},
		[setModalState, d_t_validation]
	);

	/** Data call */
	useEffect(() => {
		if (!workspace_meetings.length) dispatch(getMeetingsByWorkspace({ workspace_id: workspaceId }));
		dispatch(getDeliverablesByWorkspace({ workspace_id: workspaceId }));
		dispatch(getDeliverablesNotesByWorkspace({ workspace_id: workspaceId }));
		dispatch(getWorkspaceResources({ workspace_id: workspaceId }));
	}, [dispatch, workspaceId, workspace_meetings.length]);

	useEffect(() => {
		if (!Object.keys(allUsersMap).length) {
			dispatch(getAllUsers());
		}
	}, [allUsersMap, dispatch]);

	return (
		<>
			<ValidationContext.Provider
				value={{
					d_t_validation,
					d_t_validation_setters,
					setModalState
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} xl={12}>
						<StyledComponentWrapper>
							<Stack gap={2}>
								{/* Header */}
								<ProjectDetails workspaceId={workspaceId} handleClose={handleClose} />

								{isLoading && (
									<Stack alignItems="center" justifyContent="center">
										<CircularProgress color="primary" />
									</Stack>
								)}
								{isSuccess &&
									(no_meetings ? (
										<EmptyMeetingList workspace_id={workspaceId} />
									) : (
										<StyledMeetingsWrapper gap={2} p={1}>
											<Stack alignItems="end" justifyContent="center">
												<CreateNewMeetingButton workspace_id={workspaceId} />
											</Stack>
											{workspace_meetings.map(({ meeting_id, workspace_id }) => (
												<DecisionTrackerMeeting
													key={meeting_id}
													workspaceId={workspace_id}
													meetingId={meeting_id}
													handleMeetingSave={handleMeetingSave}
												/>
											))}
										</StyledMeetingsWrapper>
									))}
							</Stack>
						</StyledComponentWrapper>
					</Grid>
				</Grid>
			</ValidationContext.Provider>
			<ConfirmationModal {...modalState} />
		</>
	);
};

export default DecisionTrackerForm;
