import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import IconButton from "../../../UIComponents/DesignSystem/IconButtton.js";
import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import {
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon
} from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { Stack, Typography } from "../../../UIComponents/index.js";
import {
	assigned_certification_mapping,
	certification_mapping,
	user_mapping
} from "./CollapsibleTable.js";
import { useSelector } from "react-redux";
import { selectCertificationById } from "../../../redux/reducers/certificationDashboard.js";
import CertificationDetails from "./CertificationDetails.js";
import dayjs from "dayjs";

const transform_cert_data = (certification) => {
	const cert = { ...certification };
	if (cert.expiry_date) {
		cert.expiry_date = cert.expiry_date ? dayjs(cert.expiry_date).format("MMM DD, YYYY") : "-";
	}
	if (cert.counts_towards_partner_count) {
		cert.counts_towards_partner_count = cert.counts_towards_partner_count === "Y" ? "Yes" : "No";
	}
	if (cert.due_on) {
		cert.due_on = cert.due_on ? dayjs(cert.due_on).format("MMM DD, YYYY") : "-";
	}

	if (cert.assigned_on) {
		cert.assigned_on = cert.assigned_on ? dayjs(cert.assigned_on).format("MMM DD, YYYY") : "-";
	}

	if (cert.modified_date) {
		cert.modified_date = cert.modified_date
			? dayjs(cert.modified_date).format("MMM DD, YYYY")
			: "-";
	}

	return cert;
};

const transform_user_data = (user) => {
	const transformed_user = { ...user };
	transformed_user.most_recent_expiry_date = transformed_user.most_recent_expiry_date
		? dayjs(transformed_user.most_recent_expiry_date).format("MMM DD, YYYY")
		: "-";
	transformed_user.most_recent_due_date = transformed_user.most_recent_due_date
		? dayjs(transformed_user.most_recent_due_date).format("MMM DD, YYYY")
		: "-";
	return transformed_user;
};

const UserRow = ({ userId, expandUser, handleUserExpandToggle }) => {
	const user = useSelector((state) => selectCertificationById(state, userId));
	const transformed_user = transform_user_data(user);
	const acquired_certificates = (user.certificates || []).map(transform_cert_data);
	const assigned_certificates = (user.assigned_certificates || []).map(transform_cert_data);

	return (
		<>
			<TableRow key={userId}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() =>
							handleUserExpandToggle({
								userId: userId
							})
						}
						wrapperSx={{
							height: "40px",
							width: "40px"
						}}
					>
						{expandUser[userId] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				{Object.keys(user_mapping).map((key) => {
					return (
						<TableCell key={key}>
							<Stack alignItems="center" justifyContent="center">
								<Typography>{`${transformed_user[key] ?? "-"}`}</Typography>
							</Stack>
						</TableCell>
					);
				})}
			</TableRow>
			<TableRow>
				<TableCell sx={{ padding: 0 }} colSpan={Object.keys(user_mapping).length + 1}>
					<Collapse in={expandUser[userId]} timeout="auto" unmountOnExit>
						<CertificationDetails
							userId={userId}
							title="Acquired Certifications"
							headerColMap={certification_mapping}
							certification_data={acquired_certificates}
						/>
						<CertificationDetails
							userId={userId}
							userName={user["employee_name"]}
							title="Assigned Certifications"
							headerColMap={assigned_certification_mapping}
							certification_data={assigned_certificates}
							all_cert_ids={[...acquired_certificates, ...assigned_certificates].map(
								(cert) => cert.certification_id
							)}
							showAssignIcon
						/>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default UserRow;
