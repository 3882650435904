import React, { useMemo } from "react";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CustomLabel } from "../components/TimeEntryProfile/HoursTabs";
import { Grid, Typography } from "@mui/material";
import { formatCurrecy, formatNumber, formatDate } from "../utils";

const ProjectRow = (props) => {
	const { project, workingDays, openComments } = props;
	const {
		project_title,
		status_color,
		status_message,
		allocated_minutes,
		billable_minutes,
		project_cost,
		allocated_minutes_revenue,
		// forcast_pacing,
		project_id,
		project_close_date,
		budget_remaining,
		nonbillable_minutes
	} = project;

	const allocatedHours = useMemo(() => formatNumber(allocated_minutes / 60), [allocated_minutes]);

	const currentHours = useMemo(() => formatNumber(billable_minutes / 60), [billable_minutes]);

	const currentHoursNonBillable = useMemo(
		() => formatNumber(nonbillable_minutes / 60),
		[nonbillable_minutes]
	);

	const hoursDelta = useMemo(
		() => formatNumber(allocatedHours - currentHours, 1),
		[allocatedHours, currentHours]
	);

	const forcastPacingHrsDelta = useMemo(
		() =>
			formatNumber(
				currentHours - (allocatedHours / workingDays.totalWorkDays) * workingDays.currentDay
			),
		[allocatedHours, currentHours, workingDays]
	);

	const handleComments = () => {
		openComments({ project_id, project_title });
	};
	return (
		<TableRow>
			<TableCell className="td-b-r" size="verysmall">
				<a
					href={`https://magna.mavenlink.com/workspaces/${project_id}`}
					target="_blank"
					rel="noreferrer"
					style={{ textDecoration: "none", color: "inherit" }}
				>
					{project_title}
				</a>
			</TableCell>
			<TableCell className="td-b-r" size="verysmall">
				<CustomLabel
					color={
						status_color === "yellow" ? "orange" : status_color === "grey" ? "black" : status_color
					}
					textColor="#000000DE"
					label={status_message}
				/>
			</TableCell>
			<TableCell className="td-b-r" size="verysmall">
				{formatDate(project_close_date, "abs")}
			</TableCell>
			<TableCell className="td-b-r" size="verysmall">
				{budget_remaining}
			</TableCell>
			<TableCell className="td-b-r" size="verysmall">
				{allocatedHours}
			</TableCell>
			<TableCell className="td-b-r" size="verysmall">
				{currentHours}
			</TableCell>
			<TableCell className="td-b-r" size="verysmall">
				{currentHoursNonBillable}
			</TableCell>
			<TableCell className="td-b-r" size="verysmall">
				{hoursDelta}
			</TableCell>
			<TableCell className="td-b-r" size="verysmall">
				{formatCurrecy(allocated_minutes_revenue)}
			</TableCell>
			<TableCell className="td-b-r" size="verysmall">
				{formatCurrecy(project_cost)}
			</TableCell>
			<TableCell className="td-b-r" size="verysmall">
				{formatCurrecy(formatNumber(allocated_minutes_revenue - project_cost))}
			</TableCell>
			<TableCell className="td-b-r" size="verysmall">
				<Grid container direction="row" alignItems="center" justifyContent="space-between">
					<Grid item xs={8}>
						<Typography variant="body2" color={forcastPacingHrsDelta < 0 ? "error" : "green"}>
							{forcastPacingHrsDelta}
						</Typography>
					</Grid>
					<Grid item xs={4} className="forecast-svg" onClick={handleComments}>
						<svg xmlns="http://www.w3.org/2000/svg" width="52px" height="52px" viewBox="0 0 52 52">
							<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<path
									d="M4,8 L48,8 L48,44 L4,44 L4,8 Z M8,12 L8,40 L44,40 L44,12 L8,12 Z M30,14 L42,14 L42,38 L30,38 L30,14 Z"
									fill="#000000"
								/>
							</g>
						</svg>
					</Grid>
				</Grid>
			</TableCell>
		</TableRow>
	);
};

ProjectRow.propTypes = {
	project: PropTypes.object.isRequired,
	workingDays: PropTypes.object.isRequired
};

export default ProjectRow;
