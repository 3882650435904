import { styled } from "@mui/material";
import { Grid, Paper } from "../../UIComponents/index.js";
import WorkspacesTable from "./WorkspaceTable.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/reducers/users.js";

export const StyledComponentWrapper = styled(Paper)(() => ({
	width: "inherit",
	padding: "1.25rem"
}));

const DecisonTracker = () => {
	const dispatch = useDispatch();
	const allUsersMap = useSelector((state) => state.users.all);
	useEffect(() => {
		if (!Object.keys(allUsersMap).length) {
			dispatch(getAllUsers());
		}
	}, [allUsersMap, dispatch]);
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} xl={12}>
					<StyledComponentWrapper>
						<WorkspacesTable />
					</StyledComponentWrapper>
				</Grid>
			</Grid>
		</>
	);
};

export default DecisonTracker;
