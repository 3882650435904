import { Box, Paper, Popover, Stack, Typography, styled, tableCellClasses } from "@mui/material";
import TableCell from "../../../UIComponents/DesignSystem/TableCell";
import TableRow from "../../../UIComponents/DesignSystem/TableRow";

export const NonBillableFormPaper = styled(Paper)(() => ({
	width: "inherit",
	padding: "1.25rem 0 1.25rem 1.25rem",
	borderRight: "1.25rem solid transparent",
	overflow: "auto"
}));

export const StyledTableWrapper = styled(Box)(() => ({
	width: "71.625rem"
}));

export const StyleTopBarWrapper = styled(Stack)(() => ({
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center"
}));

export const StyledSpacerCell = styled(Stack)(() => ({
	alignItems: "center",
	justifyContent: "center",
	flexShrink: 0,
	width: "250px",
	height: "30px",
	backgroundColor: "lightgray",
	border: "1px solid black"
}));

export const StyledVisibleWeekWrapper = styled(Stack)(() => ({
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "flex-start",
	backgroundColor: "lightgray"
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.secondary.light,
		borderWidth: 0,
		height: "50px"
		// display: 'flex',
		// flexDirection: 'row',
		// justifyContent: 'center',
		// alignItems: 'center'
	},
	[`&.${tableCellClasses.body}`]: {
		padding: 0,
		borderRight: `1px solid ${theme.palette.secondary.light}`,
		borderBottom: `1px solid ${theme.palette.secondary.light}`
	}
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	// backgroundColor: theme.palette.secondary.light,
}));

export const StyledVisibleWeekCell = styled(Stack)(({ theme, height = "auto", active }) => ({
	alignItems: "center",
	justifyContent: "center",
	height,
	padding: ".25rem .5rem",
	backgroundColor: !!active && theme.palette.secondary.light
}));

export const SytledTeammateCell = styled(Stack)(() => ({
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "start",
	padding: ".25rem .5rem",
	gap: ".750rem"
}));

export const StyledImg = styled("img")(() => ({
	borderRadius: "50%",
	height: "32px",
	width: "32px"
}));

export const StyledPopover = styled(Popover)(() => ({
	".MuiPaper-elevation1": {
		padding: "1rem"
	}
}));

export const StyledTypography = styled(Typography)(() => ({
	fontSize: "12px"
}));
