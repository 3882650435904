import { Button, Modal, Stack, Typography } from "../../../UIComponents";
import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { useAuth } from "../../../CustomHooks/AuthenticationHook";
import { deleteCertification } from "../../../redux/reducers/certificationDashboard";

const DeleteCertificationModal = ({
	isOpen,
	onClose,
	userId,
	userName,
	userCertificationId,
	userCertificationName,
	userCertificationDeadline,
	userCertificationTrackId
}) => {
	const dispatch = useDispatch();
	const { user } = useAuth();

	const handleModalClose = () => {
		onClose();
	};

	const handleSubmit = () => {
		if (userCertificationTrackId) {
			dispatch(
				deleteCertification({
					certification_id: userCertificationId,
					member_id: userId,
					modified_by_id: user.userId,
					track_id: userCertificationTrackId
				})
			);
			onClose();
		} else {
			alert("Please fill out all required fields");
		}
	};

	return (
		<Modal show={isOpen} onHide={handleModalClose} centered>
			<Box
				sx={{
					p: 4
				}}
			>
				<Typography fontSize="18px" sx={{ mb: 2 }}>
					Delete Certification
				</Typography>

				<Stack gap={1} direction={"column"} justifyContent={"center"} alignItems={"start"}>
					<Typography variant="h1" component="h1" fontSize={16} fontWeight={"bold"}>
						Are you sure you want to delete assigned certification?
					</Typography>
					<Typography fontSize={16} marginTop={2}>
						User name: {userName}
					</Typography>
					<Typography fontSize={16}>Certification name: {userCertificationName}</Typography>
					<Typography fontSize={16}>Due on: {userCertificationDeadline}</Typography>
				</Stack>

				{/* Action Buttons */}
				<Box display="flex" justifyContent="flex-end" gap={1} mt={2}>
					<Button variant="outlined" color="secondary" onClick={handleModalClose}>
						Close
					</Button>
					<Button variant="contained" color="primary" onClick={handleSubmit}>
						Delete
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default DeleteCertificationModal;
