import React, { useCallback, useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { Button, Card, Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import TimeLoggingHabit from "./TimeLoggingHabit";
import { CustomLabel } from "../TimeEntryProfile/HoursTabs";
import { TimeUtilization } from "./TimeUtilization";
import axios from "axios";
import { formatNumber } from "../../utils";
import moment from "moment";
import { usePrevious } from "../../CustomHooks/usePrevious";
import InformaticTitle from "../../ComponentsLibrary/InformaticTitle";

const theme = createTheme({
	palette: {
		primary: { main: "#7367F0" },
		secondary: { main: "#7367f01f" }
	},
	typography: {
		SemiBold: {
			fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
			fontSize: "12px",
			lineHeight: "18px",
			letterSpacing: 0,
			fontWeight: 600
		}
	}
});

const CustomMobileDatePicker = (props) => {
	const { label, onChange, disableFuture, defaultValue } = props;
	return (
		<MobileDatePicker
			label={label}
			format="MM/DD/YYYY"
			onChange={onChange}
			slotProps={{
				textField: {
					size: "small",
					fullWidth: false,
					variant: "outlined",
					sx: {
						maxWidth: "48%"
					},
					InputProps: {
						sx: {
							fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
							fontSize: "12px",
							lineHeight: "18px"
						},
						endAdornment: (
							<InputAdornment position="end">
								<IconButton edge="end" disableRipple>
									<KeyboardArrowDownIcon fontSize="small" />
								</IconButton>
							</InputAdornment>
						)
					},
					InputLabelProps: {
						sx: {
							fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
							fontSize: 12
						}
					}
				}
			}}
			defaultValue={moment(defaultValue)}
			closeOnSelect
			disableFuture={disableFuture}
		/>
	);
};

const CustomButton = (props) => {
	const { label, selected, handleClick } = props;
	return (
		<ThemeProvider theme={theme}>
			<Button
				variant={selected ? "contained" : "outlined"}
				disableElevation
				disableFocusRipple
				sx={{
					color: selected ? "primary" : "#7367F0",
					textTransform: "capitalize",
					minWidth: "48%"
				}}
				color={selected ? "primary" : "secondary"}
				onClick={handleClick}
			>
				<Typography variant="SemiBold"> {label}</Typography>
			</Button>
		</ThemeProvider>
	);
};

const TimeEntrySummary = (props) => {
	const [selected, setSelectedOption] = useState(0);
	const [timeLoggingHabit, setTimeLoggingHabit] = useState({});
	const [utilizationData, setUtilizationData] = useState([]);
	const [customDates, setCustomDates] = useState({
		startDate: null,
		endDate: null
	});
	const { user } = props;

	const prevCustomDates = usePrevious(customDates);

	const handleOption = (option) => {
		setSelectedOption(option);
	};

	const getTimeLogged = useCallback(
		({ startDate, endDate }) => {
			axios
				.get(
					`${`${process.env.REACT_APP_PROD}/api/v1`}/time-habit/${user.userId}?from_date=${startDate}&to_date=${endDate}`
				)
				.then((res) => {
					setTimeLoggingHabit(res.data.data);
				})
				.catch((err) => console.log(err));

			/** @TO-DO: Update below call with getUtilizationChartData in API folder */
			axios
				.get(
					`${`${process.env.REACT_APP_PROD}/api/v1`}/utilizations?member_ids=${user.userId}&from_date=${startDate}&to_date=${endDate}`
				)
				.then((res) => {
					setUtilizationData(res.data.data);
				})
				.catch((err) => console.log(err));
		},
		[user.userId]
	);

	useEffect(() => {
		let currentDate = moment();
		let startDate, endDate;
		if (selected !== 3) {
			// Last 30 days
			if (selected === 0) {
				startDate = currentDate.subtract(1, "month").format("YYYY-MM-DD");
				endDate = moment(new Date()).subtract(1, "day").format("YYYY-MM-DD");
			}
			// Current Quarter
			else if (selected === 1) {
				startDate = moment().startOf("quarter").date(1).format("YYYY-MM-DD");
				endDate = moment(new Date()).subtract(1, "day").format("YYYY-MM-DD");
			}
			// Last Quarter
			else if (selected === 2) {
				startDate = moment().subtract(1, "quarter").startOf("quarter").date(1).format("YYYY-MM-DD");
				endDate = moment().subtract(1, "quarter").endOf("quarter").format("YYYY-MM-DD");
			}
			getTimeLogged({ startDate, endDate });
		}
	}, [selected, getTimeLogged]);

	useEffect(() => {
		if (
			selected === 3 &&
			customDates.startDate &&
			customDates.endDate &&
			(prevCustomDates.startDate !== customDates.startDate ||
				prevCustomDates.endDate !== customDates.endDate)
		) {
			getTimeLogged({
				startDate: customDates?.startDate,
				endDate: customDates.endDate
			});
		}
	}, [selected, customDates, getTimeLogged, prevCustomDates]);

	return (
		<Card sx={{ height: "100%" }}>
			<Grid container p={2} direction={"column"}>
				<Typography variant="Medium" fontSize={18} pb={2}>
					Time Entry Summary
				</Typography>
				<Stack direction={"column"} spacing={1}>
					<Typography
						variant="Medium"
						sx={{ color: "text.disabled", textTransform: "uppercase" }}
						gutterBottom
					>
						Filters
					</Typography>
					<Stack direction={"row"} flexWrap={"wrap"} spacing={1} useFlexGap>
						<CustomButton
							label="Last 30 days"
							selected={selected === 0}
							handleClick={() => handleOption(0)}
						/>
						<CustomButton
							label="Current Quarter"
							selected={selected === 1}
							handleClick={() => handleOption(1)}
						/>
						<CustomButton
							label="Last Quarter"
							selected={selected === 2}
							handleClick={() => handleOption(2)}
						/>
						<CustomButton
							label="Custom Date"
							selected={selected === 3}
							handleClick={() => {
								// if(!customDates?.startDate)setCustomDates({
								//   startDate:moment().startOf("isoWeek").format('YYYY-MM-DD'),
								//   endDate:moment().format('YYYY-MM-DD')});
								handleOption(3);
							}}
						/>
						{selected === 3 && (
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<CustomMobileDatePicker
									label="Start Date"
									onChange={(date) =>
										setCustomDates((prevDates) => ({
											...prevDates,
											startDate: moment(date).format("YYYY-MM-DD")
										}))
									}
									defaultValue={customDates?.startDate}
									value={customDates?.startDate}
									disableFuture
								/>
								<CustomMobileDatePicker
									label="End Date"
									onChange={(date) =>
										setCustomDates((prevDates) => ({
											...prevDates,
											endDate: moment(date).format("YYYY-MM-DD")
										}))
									}
									defaultValue={customDates?.endDate}
									value={customDates?.endDate}
									disableFuture
								/>
							</LocalizationProvider>
						)}
					</Stack>
				</Stack>
				<InformaticTitle
					label="Time Logging Habit"
					titleinfo={
						<Stack direction={"column"} spacing={1}>
							<CustomLabel
								textColor={"#FFF"}
								label={"Indicates that time was logged on the same day the task was performed."}
								color={"#28C76F"}
							/>
							<CustomLabel
								textColor={"#FFF"}
								label={"Indicates that time was logged later than the day the task was performed."}
								color={"#FF9F43"}
							/>
							<CustomLabel
								textColor={"#FFF"}
								label={"Indicates that time is not yet logged and entry is still missing."}
								color={"#EA5455"}
							/>
						</Stack>
					}
					pt={2}
					gutterBottom
				/>
				{!isNaN(timeLoggingHabit?.ontime_count) && (
					<Stack
						direction={"row"}
						spacing={1}
						alignItems={"center"}
						justifyContent={"space-between"}
					>
						<TimeLoggingHabit
							loggedOnTime={formatNumber(timeLoggingHabit?.ontime_count)}
							notLoggedOnTime={formatNumber(timeLoggingHabit?.late_count)}
							missingCount={formatNumber(timeLoggingHabit?.missing_count)}
						/>
						<Stack direction={"column"}>
							<Stack
								direction={"row"}
								spacing={1}
								alignItems={"center"}
								justifyContent={"space-between"}
							>
								<CustomLabel color={"#28C76F"} label={"Logged on Time"} textColor="text.primary" />
								<Typography variant="Medium">
									{formatNumber(timeLoggingHabit?.ontime_count)}d
								</Typography>
							</Stack>
							<Stack
								direction={"row"}
								spacing={1}
								alignItems={"center"}
								justifyContent={"space-between"}
							>
								<CustomLabel color={"#FF9F43"} label={"Logged Late"} textColor="text.primary" />
								<Typography variant="Medium">
									{formatNumber(timeLoggingHabit?.late_count)}d
								</Typography>
							</Stack>
							<Stack
								direction={"row"}
								spacing={1}
								alignItems={"center"}
								justifyContent={"space-between"}
							>
								<CustomLabel color={"#EA5455"} label={"Missing"} textColor="text.primary" />
								<Typography variant="Medium">
									{formatNumber(timeLoggingHabit?.missing_count)}d
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				)}
				<InformaticTitle
					label="Utilization"
					pt={3}
					pb={1}
					titleinfo={
						<Stack direction={"column"} spacing={1} fontSize={"inherit !important"}>
							<CustomLabel
								textColor={"#FFF"}
								label={
									"Billable Utilization: The percentage of the Billable Target achieved, based on the billable target compared to the billable time logged for the selected time range."
								}
								color={"#28C76F"}
							/>
							<CustomLabel
								textColor={"#FFF"}
								label={
									"Allocated Utilization: The percentage of Allocated Hours Utilization, based on the hours allocated to individuals compared to the actual hours logged for the selected time range."
								}
								color={"#00CFE8"}
							/>
							<CustomLabel
								textColor={"#FFF"}
								label={
									"Scheduled Utilization: The percentage of Scheduled Hours Utilization, based on the hours assigned compared to the actual billable hours logged for the selected time range."
								}
								color={"#7367F0"}
							/>
						</Stack>
					}
				/>
				<Stack
					direction={"row"}
					spacing={1}
					alignItems={"center"}
					justifyContent={"space-between"}
					flexWrap={"wrap"}
					useFlexGap
				>
					<CustomLabel color={"#28C76F"} label={"Billable Utilization"} textColor="text.primary" />
					<CustomLabel color={"#00CFE8"} label={"Allocated Utilization"} textColor="text.primary" />
					<CustomLabel color={"#7367F0"} label={"Scheduled Utilization"} textColor="text.primary" />
				</Stack>
				<Grid pt={2}>
					<TimeUtilization data={utilizationData} />
				</Grid>
			</Grid>
		</Card>
	);
};

// TimeEntrySummary.propTypes = {};

export default TimeEntrySummary;
