import {
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Paper,
	Stack,
	TextField,
	Chip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledTypography } from "./styledComponents";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import {
	updateNonBillableTimeData,
	updateUserApprovedNonBillable
} from "../../../redux/reducers/users";
import { Typography } from "../../../UIComponents";
import Button from "../../../UIComponents/DesignSystem/Button";

const convertTimeEntriesArrToObj = ({ timeEntriesProps = {} }) => {
	const id = uuid();
	if (!Object.keys(timeEntriesProps || {}).length) {
		return {
			[id]: {
				hours: "",
				minutes: "",
				note: ""
			}
		};
	}
	return timeEntriesProps;
};

const NonBillableTimeEntry = ({ selectedCell, onClose }) => {
	const dispatch = useDispatch();
	const { data, weekTitle, sow, eow } = selectedCell;
	const {
		approved_hour_entries: timeEntriesProps = {},
		member_id
		// billable_hours_target,
	} = data || {};
	const [timeEntries, setTimeEnteries] = useState(() =>
		convertTimeEntriesArrToObj({ timeEntriesProps })
	);

	useEffect(() => {
		const timeEntryObj = convertTimeEntriesArrToObj({ timeEntriesProps });
		setTimeEnteries(timeEntryObj);
	}, [timeEntriesProps]);

	const addDummyEntry = () => {
		setTimeEnteries((prev) => ({
			...prev,
			[uuid()]: {
				hours: "",
				minutes: "",
				note: ""
			}
		}));
	};

	const handleHourChange = ({ id = uuid(), hours = 0 }) => {
		const positiveHours = hours < 0 ? hours * -1 : hours;
		setTimeEnteries((prev) => {
			const newState = {
				...prev,
				[id]: {
					...(prev[id] || {}),
					hours: positiveHours
				}
			};
			return newState;
		});
	};
	const handleMinuteChange = ({ id = uuid(), minutes = 0 }) => {
		const positiveMinutes = minutes < 0 ? minutes * -1 : minutes;
		setTimeEnteries((prev) => {
			const newState = {
				...prev,
				[id]: {
					...(prev[id] || {}),
					minutes: positiveMinutes
				}
			};
			return newState;
		});
	};
	const handleNoteChange = ({ id = uuid(), note = "" }) => {
		setTimeEnteries((prev) => {
			const newState = {
				...prev,
				[id]: {
					...(prev[id] || {}),
					note
				}
			};
			return newState;
		});
	};

	const handleDelete = ({ id }) => {
		setTimeEnteries((prev) => {
			const newState = { ...prev };
			delete newState[id];
			return newState;
		});
	};

	const handleSave = () => {
		/** Trimming the empty values */
		const trimmed_time_entries = Object.fromEntries(
			Object.entries(timeEntries).filter(([_, entries]) => entries.hours || entries.minutes)
		);

		/** Aggregating the entries into hours */
		const [hours_approved, minutes_approved] = Object.values(trimmed_time_entries).reduce(
			(acc, curr) => {
				const h = acc[0] + Number(curr.hours);
				const m = acc[1] + Number(curr.minutes);
				return [h, m];
			},
			[0, 0]
		);
		const total_hours_approved = Number(
			Number(minutes_approved) / 60 + Number(hours_approved)
		).toFixed(2);

		/** Sending API */
		const start_date = sow.format("YYYY-MM-DD");
		const end_date = eow.format("YYYY-MM-DD");
		const APIData = {
			member_id,
			hours_approved: total_hours_approved,
			start_date,
			end_date,
			approved_hour_entries: trimmed_time_entries
		};
		dispatch(updateUserApprovedNonBillable(APIData));

		/** Updating Local State */
		const localStateUpdateData = {
			member_id,
			approved_hour_entries: trimmed_time_entries,
			non_billable_hours_approved: total_hours_approved,
			start_date,
			end_date
		};
		dispatch(updateNonBillableTimeData(localStateUpdateData));

		onClose();
	};
	return (
		<Paper>
			<Grid
				container
				spacing={2}
				style={{
					width: "25rem"
				}}
			>
				{/* Top Bar  */}
				<Grid item xs={12}>
					<Stack alignItems="center" direction="row" justifyContent="space-between">
						<Typography fontSize="14px" fontWeight={700}>
							{weekTitle}
						</Typography>
						<Chip label="Non-Billables Approved" />
					</Stack>
				</Grid>
				{Object.entries(timeEntries).map(([id, { hours = 0, minutes = 0, note = "" }]) => (
					<Grid key={id} item xs={12} xl={12}>
						<Grid container spacing={2}>
							<Grid item xs={2.5}>
								<Stack justifyContent="start" alignItems="center">
									<OutlinedInput
										type="number"
										size="small"
										sx={{ width: 75, height: "2rem" }}
										value={hours || 0}
										onChange={({ target: { value } = {} }) =>
											handleHourChange({ id, hours: value })
										}
										endAdornment={
											<InputAdornment position="end">
												<StyledTypography>hr</StyledTypography>
											</InputAdornment>
										}
										inputProps={{
											"aria-label": "hours allocated"
										}}
									/>
								</Stack>
							</Grid>
							<Grid item xs={2.5}>
								<Stack justifyContent="start" alignItems="center">
									<OutlinedInput
										type="number"
										size="small"
										sx={{ width: 75, height: "2rem" }}
										value={minutes || 0}
										onChange={({ target: { value } = {} }) =>
											handleMinuteChange({ id, minutes: value })
										}
										endAdornment={
											<InputAdornment position="end">
												<StyledTypography>m</StyledTypography>
											</InputAdornment>
										}
										inputProps={{
											"aria-label": "hours allocated"
										}}
									/>
								</Stack>
							</Grid>
							<Grid item xs={5.5}>
								<Stack
									justifyContent="start"
									alignItems="center"
									sx={{ width: "-webkit-fill-available" }}
								>
									<TextField
										multiline
										size="small"
										label={<StyledTypography>Notes</StyledTypography>}
										rows={2}
										value={note || ""}
										onChange={({ target: { value } = {} }) => handleNoteChange({ id, note: value })}
									/>
								</Stack>
							</Grid>
							<Grid item xs={1.5}>
								{Object.entries(timeEntries).length > 1 && (
									<Stack justifyContent="start" alignItems="end">
										<IconButton
											sx={{
												height: "20px",
												width: "20px"
											}}
											onClick={() => handleDelete({ id })}
											aria-label="delete"
											size="smaller"
										>
											<DeleteIcon fontSize="small" />
										</IconButton>
									</Stack>
								)}
							</Grid>
						</Grid>
					</Grid>
				))}
				<Grid item xs={12}>
					<Stack alignItems="center" direction="row" justifyContent="space-between">
						<Button
							variant="text"
							style={{
								height: "24px",
								padding: 0,
								border: 0,
								boxShadow: "none"
							}}
							onClick={addDummyEntry}
						>
							<StyledTypography>Add another entry</StyledTypography>
						</Button>
						<Stack direction="row" alignContent="center" justifyContent="end" gap={1}>
							<Button variant="outlined" color="secondary" size="small" onClick={onClose}>
								<StyledTypography>Close</StyledTypography>
							</Button>
							<Button variant="contained" color="primary" onClick={handleSave} size="small">
								<StyledTypography>Save</StyledTypography>
							</Button>
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default NonBillableTimeEntry;
