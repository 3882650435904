import React from "react";

export const useInput = (initialValue) => {
	const [value, setValue] = React.useState(initialValue);

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	//update initial set values
	const resetInitialValue = (value) => {
		setValue(value);
	};
	return {
		value,
		onChange: handleChange,
		resetInitialValue
	};
};
