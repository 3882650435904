/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { ReactComponent as CertiLogo } from "./../../assets/CertificatesIcon.svg";
import { ReactComponent as SkillsLogo } from "./../../assets/SkillsIcon.svg";
import { ReactComponent as YearsLogo } from "./../../assets/YearsIcon.svg";
import {
	Avatar,
	Badge,
	Card,
	Divider,
	Grid,
	Stack,
	SvgIcon,
	Typography,
	styled
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import MemberSkills from "./MemberSkills";
import MemberCertifications from "./MemberCertifications";
import axios from "axios";
import { useAuth } from "../../CustomHooks/AuthenticationHook";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import InputText from "../../ComponentsLibrary/InputText";
import { useInput } from "../../CustomHooks/useInput";
import IconButton from "../../UIComponents/DesignSystem/IconButtton.js";
import Button from "../../UIComponents/DesignSystem/Button.js";
import { NewQuillEditor } from "../../UIComponents";

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		right: 100,
		left: 85,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			// animation: 'ripple 1.2s infinite ease-in-out',
			border: "1px solid currentColor",
			content: '""'
		}
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0
		}
	}
}));

const GridText = ({ title, value, edit, name, onChange }) => (
	<Grid item xs={12} sm={6}>
		<Typography variant="Regular" sx={{ color: "text.disabled" }}>
			{title}
		</Typography>
		{edit ? (
			<InputText
				name={name}
				value={value}
				placeholder={`Please enter your ${title}`}
				onChange={onChange}
				sx={{ minWidth: "50%" }}
			/>
		) : (
			<Typography
				variant="Medium"
				gutterBottom
				sx={{ fontSize: { lg: 10, xl: 12 }, wordBreak: "break-word" }}
			>
				{value}
			</Typography>
		)}
	</Grid>
);

const IconComponent = (props) => (
	<div
		style={{
			backgroundColor: props.background,
			width: 40,
			height: 40,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			borderRadius: 5
		}}
	>
		<SvgIcon viewBox="0 0 14 14">{props.Icon}</SvgIcon>
	</div>
);
const IconComponentText = (props) => (
	<Grid item xs={12} sm={4}>
		<Stack direction={"row"} spacing={{ xs: 1 }} justifyContent={"center"}>
			<IconComponent Icon={props.Icon} background={props.background} />
			<Stack
				direction={"column"}
				whiteSpace={"nowrap"}
				overflow={"hidden"}
				textOverflow={"ellipsis"}
			>
				<Typography variant="Medium" fontSize={10}>
					{props.title}
				</Typography>
				<Typography variant="Medium" sx={{ color: "text.disabled", fontSize: 10 }}>
					{props.subTitle}
				</Typography>
			</Stack>
		</Stack>
	</Grid>
);

const ProfileSummary = () => {
	const navigate = useNavigate();
	const { userId } = useParams();
	let { user } = useAuth();
	const sourceMemberId = user.userId;
	const targetMemberId = userId;
	if (userId) {
		user = { userId };
	}
	const [profile, setProfile] = useState({});
	const [editProfile, setEditProfile] = useState({});
	const [edit, setEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isEditPermitted, setIsEditPermitted] = useState(false);
	const [isAddSkillPermitted, setIsAddSkillPermitted] = useState(false);
	const location = useLocation();
	const phone = useInput(editProfile.phone);
	const bio = useInput(editProfile.bio);
	const title = useInput(editProfile.title);
	const city = useInput(editProfile.city);
	const state = useInput(editProfile.state);
	const country = useInput(editProfile.country);
	const linkedin = useInput(editProfile.linkedin_url);
	const trailblazerId = useInput(editProfile.trailblazer_id);
	const webassessorEmail = useInput(editProfile.webassessor_email);

	const onEdit = () => {
		setEditProfile(profile);
		setEdit(true);
		phone.resetInitialValue(profile.phone);
		bio.resetInitialValue(profile.bio);
		title.resetInitialValue(profile.title);
		city.resetInitialValue(profile.city);
		state.resetInitialValue(profile.state);
		country.resetInitialValue(profile.country);
		linkedin.resetInitialValue(profile.linkedin_url);
		trailblazerId.resetInitialValue(profile.trailblazer_id);
		webassessorEmail.resetInitialValue(profile.webassessor_email);
	};

	const onCloseEdit = () => {
		setEdit(false);
	};

	const updateProfile = (data) => {
		setLoading(true);
		axios
			.put(`${`${process.env.REACT_APP_PROD}/api/v1`}/users/${user.userId}`, {
				update_object: data
			})
			.then((res) => {
				onCloseEdit();
				getMemberDetails();
			})
			.catch((err) => {
				console.log("Something failed while updating user details", err);
			})
			.finally(() => {
				console.log("request finished");
				setLoading(false);
			});
	};

	const onSubmit = () => {
		let data = {};
		if (phone.value && phone.value !== profile.phone) data.phone = phone.value;
		if (bio.value && bio.value !== profile.bio) data.bio = bio.value;
		if (title.value && title.value !== profile.title) data.title = title.value;
		if (city.value && city.value !== profile.city) data.city = city.value;
		if (state.value && state.value !== profile.state) data.state = state.value;
		if (country.value && country.value !== profile.country) data.country = country.value;
		if (linkedin.value && linkedin.value !== profile.linkedin_url)
			data.linkedin_url = linkedin.value;
		if (trailblazerId.value && trailblazerId.value !== profile.trailblazer_id)
			data.trailblazer_id = trailblazerId.value;
		if (webassessorEmail.value && webassessorEmail.value !== profile.webassessor_email)
			data.webassessor_email = webassessorEmail.value;

		if (Object.keys(data).length > 0) {
			updateProfile(data);
		} else {
			toast("No changes were detected.", {
				autoClose: 1500,
				hideProgressBar: true
			});
			onCloseEdit();
		}
	};

	const getMemberDetails = useCallback(() => {
		axios
			.get(`${`${process.env.REACT_APP_PROD}/api/v1`}/users/${user.userId}`)
			.then((res) => {
				setProfile(res.data.data[0]);
			})
			.catch((err) => console.log(err));
	}, [user.userId]);

	const checkEditPermission = useCallback(() => {
		axios
			.post(`${`${process.env.REACT_APP_PROD}/api/v1`}/verifyFeaturePermission`, {
				sourceMemberId,
				targetMemberId,
				featureId: 9 //feature Id for edit permission
			})
			.then((res) => {
				// console.log(res)
				if (res.data.isFeaturePermitted) {
					setIsEditPermitted(true);
				} else {
					setIsEditPermitted(false);
				}
			})
			.catch((err) => {
				console.log("There was some error checking permission", err);
			});
	}, [sourceMemberId, targetMemberId]);
	const checkAddSkillPermission = useCallback(() => {
		axios
			.post(`${`${process.env.REACT_APP_PROD}/api/v1`}/verifyFeaturePermission`, {
				sourceMemberId,
				targetMemberId,
				featureId: 10 //feature Id for add skill permission
			})
			.then((res) => {
				// console.log(res)
				if (res.data.isFeaturePermitted) {
					setIsAddSkillPermitted(true);
				} else {
					setIsAddSkillPermitted(false);
				}
			})
			.catch((err) => {
				console.log("There was some error checking permission", err);
			});
	}, [sourceMemberId, targetMemberId]);

	// const checkViewDashboardPermission = useCallback(()=>{
	//   axios.post(`${`${process.env.REACT_APP_PROD}/api/v1`}/verifyFeaturePermission`,{
	//     sourceMemberId,
	//     targetMemberId,
	//     featureId:11//feature Id for view dashboard
	//   }).then((res)=>{
	//     if(res.data.isFeaturePermitted && sourceMemberId!=targetMemberId ){
	//       navigate(`/dashboard/${targetMemberId}`)
	//       // setIsViewDashboardPermitted(true)
	//     }
	//     // else{
	//     //   setIsViewDashboardPermitted(false)
	//     // }
	//   }).catch((err)=>{
	//     console.log("There was some error checking permission",err);
	//   })
	// })

	const handleRichEditorChange = ({ setValue, value }) => {
		const e = { target: { value } };
		setValue(e);
	};

	useEffect(() => {
		getMemberDetails();
		checkEditPermission();
		checkAddSkillPermission();
		// checkViewDashboardPermission();
	}, [getMemberDetails]);

	return (
		<Card>
			<div style={{ display: "flex", flexDirection: "row-reverse" }}>
				<Stack
					alignItems="center"
					justifyContent="center"
					flexDirection="row-reverse"
					mt={1}
					mr={2}
					gap={2}
				>
					{location.pathname.includes("/member-profile") &&
						isEditPermitted &&
						(edit ? (
							<>
								<Button
									disabled={loading}
									color="primaryLight"
									variant="contained"
									size="small"
									onClick={onSubmit}
								>
									Save
								</Button>
								<Button color="primary" variant="contained" size="small" onClick={onCloseEdit}>
									Cancel
								</Button>
							</>
						) : (
							<IconButton onClick={onEdit}>
								<EditIcon />
							</IconButton>
						))}
					{location.pathname.includes("dashboard") &&
						targetMemberId &&
						sourceMemberId !== targetMemberId && (
							<Button
								color="primaryLight"
								variant="contained"
								size="small"
								onClick={() => {
									navigate(`/member-profile/${targetMemberId}`);
								}}
							>
								View
							</Button>
						)}
				</Stack>
			</div>

			<Stack direction={{ xs: "column" }} alignItems={"center"} p={1}>
				<StyledBadge
					overlap="circular"
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					variant="dot"
				>
					<Avatar
						alt={profile.name}
						src={profile.photo}
						sx={{ width: 120, height: 120, fontSize: "2rem", mb: 1 }}
					/>
				</StyledBadge>
				<div style={{ textAlign: "center" }}>
					<Typography
						variant="Medium"
						letterSpacing={0}
						fontSize={18}
						color={"#5E5873"}
						display={"block"}
					>
						{profile.name}
					</Typography>
					{edit ? (
						<InputText
							value={edit ? title.value : profile.title}
							placeholder={`Please enter your title`}
							onChange={title.onChange}
							sx={{ minWidth: "50%" }}
						/>
					) : (
						<Typography variant="Regular" fontSize={"14px"} color={"#6E6B7B"}>
							{profile.title}
						</Typography>
					)}
				</div>
				<Stack direction={"row"} justifyContent={"space-evenly"} p={1} width={"100%"}>
					<IconComponentText
						Icon={<YearsLogo />}
						background={"rgba(40, 199, 111, 12%)"}
						title={profile?.age || "exp at"}
						subTitle="at MTG"
					/>
					<IconComponentText
						Icon={<CertiLogo />}
						background={" #EEEDFD"}
						title={`${profile.acquired_certifications?.length || "0"} Certificates`}
						subTitle="Achieved"
					/>
					<IconComponentText
						Icon={<SkillsLogo />}
						background={" rgba(234, 84,85,12%)"}
						title={`${profile.skill_count || "0"} Skills`}
						subTitle="Aquired"
					/>
				</Stack>

				{location.pathname.includes("/member-profile") && (
					<>
						<Grid container spacing={1} p={1} pl={2}>
							<GridText title={"Email"} value={profile.email || "NA"} />
							<GridText
								title={"Birthdate"}
								value={
									profile.birthdate ? moment(new Date(profile.birthdate)).format("MMM DD") : "NA"
								}
							/>
							<GridText
								title={"Phone"}
								value={edit ? phone.value : profile.phone}
								edit={edit}
								name="phone"
								onChange={phone.onChange}
							/>
							{edit ? (
								<>
									<Fragment>
										<GridText
											title="City"
											value={edit ? city.value : profile.city}
											edit={edit}
											onChange={city.onChange}
										/>
										<GridText
											title="State"
											value={edit ? state.value : profile.state}
											edit={edit}
											onChange={state.onChange}
										/>
										<GridText
											title="Country"
											value={edit ? country.value : profile.country}
											edit={edit}
											onChange={country.onChange}
										/>
										<GridText
											title="Linkedin"
											value={edit ? linkedin.value : profile.linkedin_url}
											edit={edit}
											onChange={linkedin.onChange}
										/>
										<GridText
											title="Trailblazer Id"
											value={edit ? trailblazerId.value : profile.trailblazer_id}
											edit={edit}
											onChange={trailblazerId.onChange}
										/>
										<GridText
											title="Webassessor Email"
											value={edit ? webassessorEmail.value : profile.webassessor_email}
											edit={edit}
											onChange={webassessorEmail.onChange}
										/>
									</Fragment>
								</>
							) : (
								<>
									<GridText
										title={"Location"}
										value={`${profile?.city?.concat(", ") || ""} ${
											profile?.state?.concat(", ") || ""
										} ${profile?.country || ""}`}
										edit={edit}
									/>
									<GridText
										title={"Linkedin"}
										value={edit ? linkedin.value : profile.linkedin_url || "NA"}
										edit={edit}
										name="linkedin"
										onChange={linkedin.onChange}
									/>
									<GridText
										title={"Trailblazer Id"}
										value={edit ? trailblazerId.value : profile.trailblazer_id || "NA"}
										edit={edit}
										name="trailblazerId"
										onChange={trailblazerId.onChange}
									/>
									<GridText
										title={"Webassessor Email"}
										value={edit ? webassessorEmail.value : profile.webassessor_email || "NA"}
										edit={edit}
										name="webassessorEmail"
										onChange={webassessorEmail.onChange}
									/>
								</>
							)}

							<Grid item xs={12}>
								<Typography variant="Medium" sx={{ color: "text.disabled" }}>
									ABOUT ME
								</Typography>
								{edit ? (
									<NewQuillEditor
										readOnly={!edit}
										hideToolbar={!edit}
										editorHeight="100px"
										placeholder="Enter your Bio"
										value={bio.value}
										onChange={({ html: htmlContent, delta }) => {
											handleRichEditorChange({
												setValue: bio.onChange,
												value: htmlContent
											});
										}}
										sx={{
											width: "500px"
										}}
									/>
								) : (
									// {@TO-DO: Update the api to clean the HTML String}
									<div
										dangerouslySetInnerHTML={{ __html: profile.bio }}
										style={{
											marginTop: ".5rem",
											fontWeight: 400,
											fontSize: ".75rem"
										}}
									/>
								)}
							</Grid>
						</Grid>
					</>
				)}
				<Divider sx={{ backgroundColor: "#0000001f", width: "100%" }} />
				<MemberSkills
					skills={profile.skills || []}
					getMemberDetails={getMemberDetails}
					isAddSkillPermitted={isAddSkillPermitted}
				/>
				<MemberCertifications
					certifications={profile.certifications || []}
					acquired_certifications={profile.acquired_certifications || []}
					assigned_certifications={profile.assigned_certifications || []}
					setProfile={setProfile}
				/>
			</Stack>
		</Card>
	);
};

// ProfileSummary.propTypes = {};

export default ProfileSummary;
