import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, IconButton, Link, Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import InputSubmitSectionComponent from "../ComponentsLibrary/InputSubmitSectionComponent";
import { usePrevious } from "../CustomHooks/usePrevious";

const CommentCard = (props) => {
	const { comment, viewAll, handleDelDetails, actions, onSubmitComment, loading, onDelete } = props;
	const [showMore, setShowState] = useState(false);
	const [edit, setEdit] = useState(false);
	const prevLoading = usePrevious({ loading });
	const editEnabled = actions?.includes("edit");
	const deleteEnabled = actions?.includes("delete");

	const onFinaliseEdit = (data) => {
		onSubmitComment({ ...data, comment_id: comment.comment_id });
	};
	useEffect(() => {
		if (prevLoading?.loading === true && prevLoading?.loading !== loading) {
			if (loading === false) {
				setEdit(false);
			}
		}
	}, [loading, prevLoading]);

	const deletComment = (id) => {
		if (window.confirm(`Delete the comment?`)) {
			onDelete(id);
		} else {
			console.log("Opted out delete");
		}
	};
	return (
		<Card sx={{ minWidth: 275, marginBottom: !viewAll ? 2 : 0 }}>
			<CardContent sx={{ pb: 1 }}>
				<Grid container alignItems={"center"} flexDirection={"row"}>
					{/* <Typography
            sx={{ fontSize: "0.785rem", fontWeight: 600 }}
            color="text.secondary"
          >
            Date: {moment(comment.created_at).format("MMM DD,YYYY")}
          </Typography> */}
					<Typography sx={{ fontSize: "0.785rem", fontWeight: 600 }} color="text.secondary">
						Update by:&nbsp;{comment.assignee_name},&nbsp;
						{comment.assignee_role}&nbsp;on&nbsp;
						{moment(comment.created_at).format("MMM DD,YYYY")}&nbsp;
						{moment(comment.last_updated_at).isAfter(comment.created_at, "minute")
							? `| Updated at: ${moment(comment.last_updated_at).format("MMM DD, YYYY hh:mm A")}`
							: null}
					</Typography>
				</Grid>
				{!edit && (
					<Fragment>
						<Typography variant="body2">
							{showMore ? comment.activity : comment.activity.toString().substring(0, 250)}
						</Typography>
						{comment.activity?.length > 250 && (
							<Link
								href="#"
								component="button"
								variant="body2"
								onClick={() => {
									setShowState(!showMore);
								}}
								color={"secondary"}
							>
								{showMore ? "Show Less" : "Show More"}
							</Link>
						)}
					</Fragment>
				)}
				{edit && (
					<InputSubmitSectionComponent
						initialValue={comment?.activity}
						onSubmitComment={onFinaliseEdit}
						cancellable
						onCancel={() => setEdit(false)}
						loading={loading}
					/>
				)}
			</CardContent>

			{viewAll && (
				<CardActions>
					<Link
						href="#"
						component="button"
						variant="body2"
						onClick={() => {
							handleDelDetails();
						}}
					>
						View all updates
					</Link>
				</CardActions>
			)}
			{actions?.length > 0 && (
				<Stack direction="row" alignItems="center" justifyContent="end" spacing={1}>
					{editEnabled && !edit && (
						<IconButton aria-label="edit" size="small" onClick={() => setEdit(true)}>
							<EditOutlinedIcon fontSize="small" />
						</IconButton>
					)}

					{deleteEnabled && !edit && (
						<IconButton
							aria-label="delete"
							size="small"
							onClick={() => deletComment(comment.comment_id)}
						>
							<DeleteRoundedIcon fontSize="small" color="error" />
						</IconButton>
					)}
				</Stack>
			)}
		</Card>
	);
};

CommentCard.propTypes = {
	viewAll: PropTypes.bool,
	comment: PropTypes.shape({
		assignee_name: PropTypes.string.isRequired,
		created_at: PropTypes.string,
		activity: PropTypes.string
	}),
	actions: PropTypes.arrayOf(PropTypes.oneOf(["edit", "delete"])),
	onSubmitComment: ({ actions, onSubmitComment }) => {
		if (actions.includes("edit") && typeof onSubmitComment !== "function") {
			return new Error("onSubmitComment needs to be passed as fucntion when edit is enabled");
		}
		return null;
	}
};

export default CommentCard;
